import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

function getIndex(value, arr, prop) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i][prop] === value) {
      return i;
    }
  }
  return -1; //to handle the case where the value doesn't exist
}

function clean(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
      delete obj[propName];
    }
  }
  return obj;
}

function getUserData() {
  return JSON.parse(localStorage.getItem("userData"));
}

const updateObjectStateValue = (stateFunction, values) => {
  stateFunction((prevState) => ({
    ...prevState,
    ...values
  }))
}

const arrayColumn = (array, column) => {
  return array.map(item => item[column]);
};

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const capitalise = (string) => string?.charAt(0)?.toUpperCase() + string?.slice(1)

const truncatedText = (text, size) => text.length > size ? text.slice(0, size) + "..." : text;

export { getIndex, truncatedText, clean, getUserData, capitalise, updateObjectStateValue, arrayColumn, useQuery };
