import api from "../../utils/API";

const getAll = (params) => {
  return api.get(`/societe/list${params}`);
};

const getDetails = (uuid) => {
  return api.get(`/societe/${uuid}/details/`);
};

const addSociete = (data) => {
  return api.post(`/societe/add`, data);
};

const editSociete = (data, uuid) => {
  return api.put(`/societe/${uuid}/update/`, data);
};

const deleteSociete = (uuid) => {
  return api.delete(`/societe/${uuid}/delete`);
};

const rechercherSociete = (url, page) => {
  return api.get(`/societemanagement/societe/search?page=${page}&limit=${100}&${url}`);
};

const updateDescription = (data, id) => {
  return api.put(`/societemanagement/societe/${id}/description/update`, data);
};

const updateActualite = (data, id) => {
  return api.put(`/societemanagement/societe/${id}/actualite/update`, data);
};

const getAllContactsInSocieteForMission = (societeId) => {
  return api.get(`/societemanagement/societe/${societeId}/contacts/listformission`);
};

const GetClientSociete = () => {
  return api.get(`/Addmission/societe`)
}

const getSocietesWithActiveMission = () => {
  return api.get(`/societe/missions/active/list`);
}

export {
  getAll,
  getDetails,
  editSociete,
  addSociete,
  deleteSociete,
  rechercherSociete,
  updateDescription,
  updateActualite,
  getAllContactsInSocieteForMission,
  GetClientSociete,
  getSocietesWithActiveMission
};
