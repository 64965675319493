import Modal from "@material-ui/core/Modal";
import React, {useState} from "react";
import {Backdrop, Button, CircularProgress, Fade, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {AiOutlinePlus} from "react-icons/ai";
import {addSociete} from "../../../Services/SocieteService";

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "20px",
  },
  saveButton: {
    backgroundColor: "rgb(66, 186, 150)",
    color: "#FFF",
    fontWeight: 500,
    "&:hover, &:focus": {
      backgroundColor: "#389e7f",
      borderColor: "#359478",
    },
  },
  plus: {
    fontWeight: 500,
    marginBottom: "0.2em",
  },
  buttonLabel: {
    paddingLeft: "0.4em",
    marginLeft: 0,
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginLeft: "0.5em",
    fontWeight: "500",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function SocieteModal({errors, setErrors, snackbarShowMessage}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [newEmployeur, setNewEmployeur] = useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const [loading, setLoading] = useState(false);

  const validate = () => {
    let temp = {};
    temp.newEmloyeur = newEmployeur ? "" : "Le champ 'NOM SOCIÉTÉ' est obligatoire.";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (loading) {
      return;
    }
    setOpen(false);
  };

  const handleChange = (e) => {
    setNewEmployeur(e.target.value.toUpperCase());
  };

  const handleSubmit = () => {
    if (validate()) {
      (async () => {
        setLoading(true);
        const societe = {
          nom_societe: newEmployeur,
          n_tel: "",
          adresse: "",
          code_postal: "",
          ville: "",
          siteinternet: "",
          sachez_que: "",
          description_societe: "",
          actualite_societe: "",
          statut: "client",
          effectif: "",
          type_societe: "",
          secteur_dactivite: "",
          region_societe:"",
          linkedin: "",
        };
        try {
          await addSociete(societe);
          snackbarShowMessage("La société a été ajoutée avec sucèss.", "success");
          setLoading(false);
          handleClose();
        } catch (error) {
          snackbarShowMessage(`Erreur d'ajout de la société "${newEmployeur}". veuillez réessayer plus tard`, "error");
          setLoading(false);
        }
      })();
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="modal-header">
        <h4 className={classes.modalTitle}>Ajouter une société</h4>
      </div>
      <div className="modal-body">
        <label className="input-label" htmlFor="newEmployeur">
          NOM SOCIÉTÉ <span className="required-input">*</span>
        </label>
        <TextField
          id="newEmployeur"
          name="newEmployeur"
          value={newEmployeur}
          onChange={handleChange}
          placeholder="Nom de l'employeur."
          margin="normal"
          variant="outlined"
          autoComplete="off"
          fullWidth
          {...(errors.newEmloyeur && {
            error: true,
            label: errors.newEmloyeur,
          })}
        />
      </div>
      <div className="modal-footer">
        <Button className={classes.saveButton} onClick={handleSubmit}>
          Sauvegarder
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
        <Button className={classes.closeButton} onClick={handleClose}>
          Annuler
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <button className="btn btn-sm btn-link float-right" style={{textDecoration: "none"}} onClick={handleOpen}>
        <AiOutlinePlus className={classes.plus} />
        Ajouter
      </button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>{body}</Fade>
      </Modal>
    </>
  );
}
