import api from "../../utils/API";

const addEmailType = (data) => {
  return api.post("/gestion/emailtype/add", data);
};

const editEmailType = (uuid, data) => {
  return api.put(`/gestion/emailtype/${uuid}/update`, data);
};

const deleteEmailType = (uuid) => {
  return api.delete(`/gestion/emailtype/${uuid}/delete`);
};

const listEmailTypes = (query = '') => {
  return api.get(`/gestion/emailtype/list${query}`);
};

const detailsEmailType = (uuid) => {
  return api.get(`/gestion/emailtype/${uuid}/details`);
};

const detailsSms = (page) => {
  return api.get(`/gestion/typesms/list?page=${page}&limit=${100}`);
};

const getContactsUsersEmails = (search) => {
  return api.get(`/gestion/emails/contacts-users/get?search=${search}`)
}

export { addEmailType, editEmailType, deleteEmailType, listEmailTypes, detailsEmailType, detailsSms, getContactsUsersEmails };
