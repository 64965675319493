import { Avatar, Box, Button, Fade, IconButton, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Modal } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import moment from "moment";
import React, { useState } from "react";
import { AiOutlineComment } from "react-icons/ai";
import { BiTime } from "react-icons/bi";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { RiUser2Fill, RiUserReceivedLine } from "react-icons/ri";
import { VscLoading } from "react-icons/vsc";
import LabelHtmlToTextCustom from "../LabelHtmlToTextCustom/LabelHtmlToTextCustom";


function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: 500,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "20px",
        padding: theme.spacing(2, 4, 3),
    },
    saveButton: {
        backgroundColor: "rgb(66, 186, 150)",
        color: "#FFF",
        fontWeight: 500,
        "&:hover, &:focus": {
            backgroundColor: "#389e7f",
            borderColor: "#359478",
        },
    },
    target: {
        fontWeight: 500,
        marginBottom: "0.2em",
    },
    closeButton: {
        color: "#1b2a4e",
        backgroundColor: "#d9e2ef",
        borderColor: "#d9e2ef",
        fontWeight: "500",
    },

    modalTitle: {
        color: "rgb(17 122 167)",
        marginBottom: "1em",
        paddingTop: "1rem",
    },
}));

const ViewTacheModal = ({ affecteA, affectePar, commentaire, dateTime, statut }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [modalStyle] = useState(getModalStyle);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const items = [
        { icon: <RiUser2Fill />, primary: "AFFECTÉE PAR", secondary: affectePar },
        { icon: <RiUserReceivedLine />, primary: "AFFECTÉE À", secondary: affecteA },
        {
            icon: <BiTime />,
            primary: "DATE/HEURE",
            secondary: moment(dateTime).format("DD/MM/yyyy HH:mm:ss"),
        },
        { icon: <AiOutlineComment />, primary: "COMMENTAIRE", secondary: <LabelHtmlToTextCustom sx={{ paddingTop: "5px", overflowY: "auto", maxHeight: "150px" }} data={commentaire} /> },
        {
            icon: statut === false ? <VscLoading /> : <IoCheckmarkDoneCircleSharp />,
            primary: "STATUS",
            secondary: statut ? "FAIT" : "À FAIRE",
        },
    ]
    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h4 className={classes.modalTitle}>
                Detailles de la tachê
            </h4>
            <Box style={{ border: "none" }}>
                <List>
                    {items.map((item, index) => (
                        <ListItem key={index + item.primary}>
                            <ListItemAvatar>
                                <Avatar>{item.icon}</Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.primary} secondary={item.secondary} />
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button className={classes.closeButton} onClick={handleClose}>
                    Fermer
                </Button>
            </Box>

        </div>
    );

    return (
        <>
            <IconButton style={{ color: "#3E60F2" }} onClick={handleOpen} data-toggle="tooltip"
                title="Voir detailles">
                <Visibility className={classes.target} />
            </IconButton>
            <Modal open={open} onClose={handleClose}>
                <Fade in={open}>{body}</Fade>
            </Modal>
        </>
    );
};

export default ViewTacheModal;
