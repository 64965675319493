import { BarChart } from "@mui/x-charts";

const BarChartt = ({ data }) => {
  return (
    <BarChart
      xAxis={[
        {
          id: data?.id,
          data: data?.list,
          scaleType: "band",
          barGapRatio: 0.1,
        },
      ]}
      series={[
        {
          data: data?.values,
        },
      ]}
      height={270}
    />
  );
};

export default BarChartt;
