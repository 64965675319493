import api from "../../utils/API";

const getAll = (params) => {
  return api.get(`/missions/list`, {params});
};

const getDetails = (uuid) => {
  return api.get(`/mission/${uuid}/details`);
};

const getDetailsForModification = (missionId) => {
  return api.get(`/mission/${missionId}/detailstomodify`);
};

const getMissionsOfSociete = (uuid, page, status) => {
  return api.get(`/societe/${uuid}/missions/list?page=${page}&limit=${50}&status=${status}`);
};
const addMission = (data) => {
  return api.post(`/mission/add`, data);
};

const editMission = (data, uuid) => {
  return api.put(`/mission/${uuid}/update`, data);
};

const getListDataEspaceMission = () => {
  return api.get(`/missions/espace/listdata/list`);
};
const getAllCandidatsOfMission = (mission) => {
  return api.get(`/processes/mission/${mission}/candidats/list`);
};
const terminerMission = (mission) => {
  return api.put(`/mission/missions/${mission}/terminer`);
};

const getMissionsActive = (queryParams = "") => {
  return api.get(`/missions/active/list${queryParams}`);
}

export {getAll, getDetails, editMission, addMission, getMissionsOfSociete, getDetailsForModification, getListDataEspaceMission, terminerMission,getAllCandidatsOfMission, getMissionsActive};
