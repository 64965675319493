import api from "../../utils/API";

const getAll = (uuid, page) => {
  return api.get(`/societemanagement/societe/${uuid}/notes/list?page=${page}&limit=${50}`);
};

const deleteNote = (societeId, noteId) => {
  return api.delete(`/societemanagement/societe/${societeId}/notes/${noteId}/delete`);
};

const addNote = (societeId, data) => {
  return api.post(`/societemanagement/societe/${societeId}/notes/add`, data);
};

const editNote = (data, societeId, noteId) => {
  return api.post(`/societemanagement/societe/${societeId}/notes/${noteId}/update`, data);
};

const getNoteFile = (fileName) => {
  return api.get(`/societemanagement/societe/notes/files/${fileName}/get`, {
    responseType: "blob",
  });
};

export {getAll, editNote, deleteNote, addNote, getNoteFile};
