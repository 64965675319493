import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import LoadingButtonCustom from "../LoadingButtonCustom/LoadingButtonCustom";

export default function DialogDeleteCustom({
  handleClose,
  handleOpen,
  handleDelete,
  loading,
  label,
  title = "",
  labelButton = "Confirmer",
}) {
  const loadingButtonProps = {
    handleSubmit: handleDelete,
    loading,
    labelButton,
  };

  return (
    <Dialog open={handleOpen} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title" style={{ color: "rgb(17 122 167)" }}>
        {title === "" ? "Supprimer" : title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {label}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={loading}>
          Annuler
        </Button>
        <LoadingButtonCustom {...loadingButtonProps} />
      </DialogActions>
    </Dialog>
  );
}
