import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const ReportingInternList = ({ listData, ...props }) => {
    return (
        <>
            <strong>{listData.label}</strong>
            <Autocomplete
                value={listData.value}
                onChange={(event, newValue) => {
                    props.handleDataChange(newValue);
                  }}
                getOptionLabel={option => option.value}
                getOptionSelected={(option, value) => option.id === value.id}
                options={listData.data}
                noOptionsText={"Aucun client trouvé."}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
                disableClearable
                loading={listData.isLoading}
                loadingText="chargement..."
            />
        </>
    )
}

export default ReportingInternList