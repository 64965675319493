import api from "../../utils/API";

const getAllEcoles = () => {
  return api.get(`/candidatsmanagement/ecole/list`);
};

const addEcole = (data) => {
  return api.post(`/candidatsmanagement/ecole/add`, data);
};

export {getAllEcoles, addEcole};
