import {Avatar, Button, CircularProgress, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Modal} from "@material-ui/core";
import {AttachFile, Visibility} from "@material-ui/icons";
import moment from "moment";
import React, {useState} from "react";
import {AiOutlineComment} from "react-icons/ai";
import {BiTask, BiTime} from "react-icons/bi";
import {IoIosPerson} from "react-icons/io";
import {getNoteFile} from "../../../../Services/NoteSociete";

function getModalStyle() {
  const top = 50 ;
  const left = 50 ;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    height:"80%",
    borderRadius: "20px",
  },
  saveButton: {
    backgroundColor: "rgb(66, 186, 150)",
    color: "#FFF",
    fontWeight: 500,
    "&:hover, &:focus": {
      backgroundColor: "#389e7f",
      borderColor: "#359478",
    },
  },
  target: {
    fontWeight: 500,
    marginBottom: "0.2em",
  },
  buttonLabel: {
    paddingLeft: "0.4em",
    marginLeft: 0,
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginLeft: "0.5em",
    fontWeight: "500",
  },

  modalTitle: {
    color: "rgb(17 122 167)",
    marginBottom: "1em",
    paddingTop:"1rem",
    paddingLeft:"1rem",
  },
}));

const ViewNoteSociete = ({pj, commentaire, dateTime, auteur, noteId, setSnackMsg, setOpenSnack, setSeverity}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownloadFile = () => {
    const FileDownload = require("js-file-download");
    (async () => {
      setLoading(true);
      try {
        let response = await getNoteFile(pj);
        FileDownload(response.data, pj);
        setLoading(false);
      } catch (error) {
        setSnackMsg("Le fichier ne peux pas être téléchargé pour l'instant réessayer plutard.");
        setSeverity("error");
        setOpenSnack(true);
        setLoading(false);
      }
    })();
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h4 className={classes.modalTitle}>
        Note Société
      </h4>
      <div className="modal-content" style={{overflowY:"scroll",height:"80%",border:"none",alignItems:"normal",}}>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <BiTime />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="DATE/HEURE" secondary={moment(dateTime.date).format("DD/MM/yyyy HH:mm:ss")} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AiOutlineComment />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="COMMENTAIRE" secondary={commentaire} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <IoIosPerson />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="AUTEUR" secondary={auteur} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AttachFile />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="PIECE JOINTE"
              secondary={
                pj ? (
                  <>
                    <a style={{textDecoration: "none"}} href="#/" onClick={handleDownloadFile}>
                      {pj}
                    </a>
                    {loading ? <CircularProgress size={14} /> : ""}
                  </>
                ) : (
                  "-"
                )
              }
            />
          </ListItem>
        </List>
      </div>
      <div className="modal-footer">
        <Button className={classes.closeButton} onClick={handleClose}>
          Annuler
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Button style={{color: "#3E60F2"}} onClick={handleOpen}>
        <Visibility className={classes.target} />
      </Button>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </>
  );
};

export default ViewNoteSociete;
