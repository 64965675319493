import {
    Box,
    CircularProgress,
    IconButton,
    useMediaQuery,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import AppointmentsListModal from "../../../Mission/Components/AppointmentsListModal";
import ShowCvCandidat from "../../../Candidat/VisualiserCandidat/ShowCVCandidat";
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import { RemoveRedEye } from "@mui/icons-material";
import { displaySwipeableDrawerSummaryCandidat } from "../../../../redux/SwipeableDrawer/actions/swipeableDrawerActions";
import { useDispatch } from "react-redux";
import { extractDate, filterAppointmentsByStatut } from "../../../../utils/AppointmentsUtils";
import AppointmentModal from "../../../Candidat/VisualiserCandidat/Tabs/Modals/CandidatProcess/AppointmentModal";
import ShowAddCvCandidat from "../../../Candidat/VisualiserCandidat/ShowAddCvCandidat";

const TableReportingIntern = ({ reportingData, snackbarShowMessage, reloadData, setReloadData }) => {
    const dispatch = useDispatch()
    const apptModalProps = { reloadData, setReloadData };


  const columns = [
    {
      name: "id",
      label: "ID ",
      options: { filter: true, sort: true, display: false },
    },
        {
            name: "numCandidat",
            label: "N° CANDIDAT",
            options: {
                filter: true,
                sort: true,
                setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
                customBodyRender: (value, tableMeta) => {
                    return (
                        <ShowAddCvCandidat
                            {...{ snackbarShowMessage, files: tableMeta.rowData[20] }}
                        >
                            {value}
                        </ShowAddCvCandidat>
                    );
                },
            },
        },

        {
            name: "nom",
            label: "NOM",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "200px",
                            }}
                        >
                            {!(isSmall || isMediumMode) && <IconButton
                                onClick={() => dispatch(displaySwipeableDrawerSummaryCandidat({ show: true, id: tableMeta.rowData[0] }))}
                                data-toggle="tooltip"
                                title="Voir resumé">
                                <RemoveRedEye size={"15px"} /></IconButton>}
                            <Link
                                to={{ pathname: `/candidats/${tableMeta.rowData[0]}/view` }}
                            >
                                {value}
                            </Link>
                        </Box>
                    );
                },
            },
        },
        {
            name: "sourceur",
            label: "RECRUTEUR",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "center",
                                width: "140px",
                            }}
                        >
                            {value ?? "-"}
                        </Box>
                    );
                },
            },
        },
        {
            name: "consultant",
            label: "ACCOUNT MANAGER",
            options: {
                filter: true,
                sort: true,
                setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "center",
                                width: "140px",
                            }}
                        >
                            {value ?? "-"}
                        </Box>
                    );
                },
            },
        },
        {
            name: "nom_societe",
            label: "CLIENT",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "100px",
                            }}
                        >
                            <Link to={{ pathname: `/societe/${tableMeta.rowData[19]}/view` }}>
                                {value}
                            </Link>
                        </Box>
                    );
                },
            },
        },
        {
            name: "nom_mission",
            label: "MISSION",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "200px",
                            }}
                        >
                            <Link
                                to={{
                                    pathname: `/societe/${tableMeta.rowData[18]}/missions/${tableMeta.rowData[16]}/view`,
                                }}
                            >
                                {value}
                            </Link>
                        </Box>
                    );
                },
            },
        },
        {
            name: "priorite",
            label: "PRIORITE",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textAlign: "left", width: "150px" }}>
                                {value ?? "-"}
                            </Box>
                        </>
                    );
                },
            },
        },
        {
            name: "lastAppointmentStatus",
            label: "STEP",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const prequalifRh = tableMeta.rowData[tableMeta.columnIndex + 1];
                    const testInterne = tableMeta.rowData[tableMeta.columnIndex + 2];
                    const envoiClient = tableMeta.rowData[tableMeta.columnIndex + 3];
                    const rvClient = tableMeta.rowData[tableMeta.columnIndex + 4];
                    const rvClosing = tableMeta.rowData[tableMeta.columnIndex + 5];
                    const status = value;
                    const expectedStatus = [
                        "Propale",
                        "Embauche/Integration",
                        "REFUSÉ PAR CLIENT",
                        "REFUSÉ PAR CANDIDAT",
                        "REFUS PROPALE"
                    ]
                    let stepValue = "Préquatif RH";

                    if (expectedStatus.includes(status)) {
                        stepValue = value;
                    }
                    else if (rvClosing) {
                        stepValue = "RV CLOSING";
                    } else if (rvClient) {
                        stepValue = "RV CLIENT";
                    } else if (envoiClient) {
                        stepValue = "ENVOI CLIENT";
                    } else if (testInterne) {
                        stepValue = "TEST INTERNE";
                    }

                    return (
                        <Box
                            data-toggle="tooltip"
                            title={stepValue}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "150px"
                            }}
                        >
                            {stepValue}
                        </Box>
                    );
                },
            },
        },

        {
            name: "dateAccrochage",
            label: "PREQUALIF RH",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                            {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                        </Box>
                    );
                },
            },
        },
        {
            name: "rvTech",
            label: "TEST INTERNE",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                            {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                        </Box>
                    );
                },
            },
        },

        {
            name: "sent",
            label: "ENVOI CLIENT",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                            {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                        </Box>
                    );
                },
            },
        },
        {
            name: "rvClient",
            label: "RV CLIENT",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                            {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                        </Box>
                    );
                },
            },
        },
        {
            name: "rvClosing",
            label: "RV CLOSING",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                            {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                        </Box>
                    );
                },
            },
        },
        {
            name: "lastAppointmentDatePropale",
            label: "PROPALE",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const status = tableMeta.rowData[tableMeta.columnIndex - 6];
                    const formattedDate = status === "Propale" && value && moment(new Date(value)).isValid()
                    ? moment(new Date(value)).format("DD/MM/YYYY")
                    : "-";
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                                {formattedDate}
                        </Box>
                    );
                },
            },
        },
        {
            name: "lastAppointmentDateIntegration",
            label: "EMB/INTÉGRATION",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const formattedDate = value && moment(new Date(value)).isValid()
                    ? moment(new Date(value)).format("DD/MM/YYYY")
                    : "-";
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                                {formattedDate}
                        </Box>
                    );
                },
            },
        },
        {
            name: "uuid",
            label: "N°MISSION",
            options: { filter: true, sort: true, display: false },
        },
        {
            name: "appointments",
            options: { filter: true, sort: true, display: false },
        },
        {
            name: "idProcess",
            options: { filter: true, sort: true, display: false },
        },
        {
            name: "client_id",
            options: { filter: true, sort: true, display: false },
        },
        {
            name: "uploadedFiles",
            options: { filter: true, sort: true, display: false, viewColumns: false },
        },
    ];

    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setViewportHeight(window.innerHeight);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
    const isMediumMode = useMediaQuery((theme) =>
        theme.breakpoints.between(767, 1023)
    );
    const isLargeMode = useMediaQuery((theme) =>
        theme.breakpoints.between(1023, 2800)
    );
    const tableBodyMaxHeight = `${isSmall
        ? viewportHeight - 242
        : isMediumMode
            ? viewportHeight - 510
            : isLargeMode
                ? viewportHeight - 370
                : 200}px`;

    const options = {
        tableBodyMaxHeight: tableBodyMaxHeight,
        rowsPerPage: 50,
        sort: true,
        textLabels: {
            body: {
                noMatch: reportingData.isLoading ? (
                    <CircularProgress />
                ) : (
                    "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment."
                ),
            },
        },
        selectableRows: "none",
        draggableColumns: {
            enabled: false,
        },
        responsive: "standard",
    };

    const reportingDataCustom = reportingData?.data?.map((row) => {
        const updatedRow = {
            ...row,
            sent: (row.sent?.date != null) ? moment(row?.sent?.date).toDate().getTime() : null,
            rvTech: (row.rvTech?.date != null) ? moment(row?.rvTech?.date).toDate().getTime() : null,
            rvClient: (row.rvClient?.date != null) ? moment(row?.rvClient?.date).toDate().getTime() : null,
            rvClosing: (row.rvClosing?.date != null) ? moment(row?.rvClosing?.date).toDate().getTime() : null,

            dateAccrochage: (row.dateAccrochage?.date != null) ? moment(row?.dateAccrochage?.date).toDate().getTime() : null,
            dateIntegration: (row.dateIntegration?.date != null) ? moment(row?.dateIntegration?.date).toDate().getTime() : null,
            revealed: (row.revealed?.date != null) ? moment(row?.revealed?.date).toDate().getTime() : null,
        };
        return updatedRow;
    });

    return (
        <MUIDataTable
            data={reportingDataCustom}
            columns={columns}
            options={options}
        />
    );
};

export default withSnackbar(TableReportingIntern);
