import React from "react";
import { Box, SwipeableDrawer, useMediaQuery } from "@material-ui/core";
import { theme } from "../../utils/Theme";
import VisualiserCandidatDrawer from "../../pages/Candidat/VisualiserCandidat/VisualiserCandidatDrawer";
import { useDispatch, useSelector } from "react-redux";
import { undisplaySwipeableDrawerSummary } from "../../redux/SwipeableDrawer/actions/swipeableDrawerActions";
import { TABLE } from "../../enums/tablesEnum";
import VisualiserContactDrawer from "../../pages/Contact/VisualiserContact/VisualiserContactDrawer";

const SwipeableDrawerSummary = () => {
  const dispatch = useDispatch();
  const isLaptop = useMediaQuery(() => theme.breakpoints.between(1024, 1300));
  const swipeableDrawer = useSelector((state) => state.swipeableDrawer);

  const Frame = () => {
    switch (swipeableDrawer.table) {
      case "candidat":
        return (
          <VisualiserCandidatDrawer
            idCandidat={swipeableDrawer.idCandidatSelected}
          />
        );
      case TABLE.CONTACT:
        return (
          <VisualiserContactDrawer
            idContact={swipeableDrawer.idContactSelected}
            id={swipeableDrawer.idSocieteSelected}
          />
        );
      case TABLE.SOCIETE:
        return <Box>Not implemented</Box>;
      default:
        return <Box>Not implemented</Box>;
    }
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={swipeableDrawer.show}
      onClose={() => dispatch(undisplaySwipeableDrawerSummary())}
      onOpen={() => {}}
    >
      <Box sx={{ width: isLaptop ? "60vw" : "35vw", marginY: "15px" }}>
        <Frame />
      </Box>
    </SwipeableDrawer>
  );
};

export default SwipeableDrawerSummary;
