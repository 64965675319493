import React from "react";
import { Grid, Table, TableBody, TableCell, TableRow, makeStyles } from "@material-ui/core";
import { sources } from "../../../../utils/SourceUtil";
import { LinkedIn } from "@material-ui/icons";
import "../visuialisation.css";
import moment from "moment";
import { v4 } from "uuid";

const useStyles = makeStyles({
  table: {
    minWidth: "100%",
  },
  donnéeStyle: {
    fontSize: "14px",
  },
});

function Informations({
  linkedin,
  email,
  telephone,
  employeur,
  fonction,
  dateDebutExperience,
  seniorite,
  sachezQue,
  consultant,
  competenceSectorielle,
  competenceMetier,
  competencesTechniques,
  ecole,
  source,
  anglais,
  langue2,
  createdAt
}) {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {[
        {
          title: "CONTACT INFORMATION",
          rows: [
            { label: "TÉLÉPHONE", value: telephone },
            { label: "EMAIL", value: email },
            {
              label: "LINKEDIN",
              value: (
                <a href={linkedin} target="_blank" rel="noreferrer">
                  <LinkedIn />
                </a>
              ),
            },
            {
              label: "CONSULTANT",
              value: consultant ? consultant.prenom + " " + consultant.nom : "-",
            },
            { label: "SACHEZ QUE", value: sachezQue },
            {
              label: "CRÉÉ LE", value: createdAt !== null ? moment(createdAt)
                .locale("fr")
                .format("DD MMMM YYYY - HH:mm") : "-"
            },
            {
              label: "SOURCE", value: ![null, '', '0'].includes(source) ? sources.find(item => item.id == source).name : "-"
            },
          ],
        },
        {
          title: "EMPLOYMENT INFORMATION",
          rows: [
            {
              label: "SOCIÉTÉ",
              value: employeur ? employeur.nomSociete.toUpperCase() : "-",
            },
            { label: "FONCTION", value: fonction },
            { label: "SENIORITÉ", value: dateDebutExperience ? seniorite : "-" },
            {
              label: "COMPÉTENCES SECTORIELLES",
              value: competenceSectorielle
                ? competenceSectorielle.map((competence) => competence.secteur).join(", ")
                : "-",
            },
            {
              label: "COMPÉTENCES METIERS",
              value: competenceMetier
                ? competenceMetier.map((competence) => competence.metier).join(", ")
                : "-",
            },
            {
              label: "COMPÉTENCES TECHNIQUES",
              value: competencesTechniques !== "null" ? competencesTechniques : "-",
            },
            { label: "ANGLAIS", value: anglais !== "null" ? anglais : "" },
            {
              label: "LANGUE 2",
              value: langue2 !== null && langue2 !== "null" ? langue2 : "-",
            },
            { label: "Diplôme(s)", value: ecole && ecole.name },
          ],
        },
      ].map((section, index) => (
        <Grid
          key={v4()}
          xs={12}
          sm={6}
          md={6}
          lg={6}
          item
          style={{
            backgroundColor: "rgb(17 72 167 / 4%)",
            padding: "0px 8px",
          }}
        >
          <Table className="table table-striped">
            <TableBody component="tbody">
              {section.rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell scope="row">
                    <strong>{row.label}</strong>
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.donnéeStyle}
                  >
                    {row.value !== "null" ? row.value : "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      ))}
    </Grid>
  );
}

export default Informations;
