import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";
import { Backdrop, Card, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MdDescription } from "react-icons/md";
import { IconButton } from "@material-ui/core";
import LabelHtmlToTextCustom from "../components/LabelHtmlToTextCustom/LabelHtmlToTextCustom";
import { Box } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "20px",
    width: "800px",
  },
  plus: {
    fontWeight: 500,
    marginBottom: "0.2em",
  },
  buttonLabel: {
    paddingLeft: "0.4em",
    marginLeft: 0,
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginLeft: "0.5em",
    fontWeight: "500",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
    fontSize: "20px"
  },
  description: {
    fontWeight: 500,
    fontSize: 20,
  },
}));

export default function SachezQueModal({ sachezQue, modalTitle }) {
  const classes = useStyles();
  const [modalState, setModalState] = useState({ open: false });

  const handleOpen = () => {
    setModalState({ open: true });
  };

  const handleClose = () => {
    setModalState({ open: false });
  };

  return (
    <>
      <IconButton data-toggle="tooltip" title="Voir commentaire" style={{ backgroundColor: "rgb(17 122 167)", color: "#fff" }} variant="contained" onClick={handleOpen}>
        <MdDescription />
      </IconButton>

      <Modal
        className={classes.modal}
        open={modalState.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalState.open}>
          <Card style={{ padding: 20, height: 400, width: 500 }}>
            <p className={classes.modalTitle}>{modalTitle ?? "Sachez Que :"}</p>
            <Box sx={{ height: 330, overflowY: "auto" }}>
              <LabelHtmlToTextCustom data={sachezQue ?? "(vide)"} />
            </Box>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}
