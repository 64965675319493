import { createReducer } from "@reduxjs/toolkit";
import { setToggleSidebar, setToggleMailing } from "../actions/toggleActions"


const initialState = {
    isToggle: false,
    showMailing: false
};

const toggleReducer = createReducer(initialState, {
    [setToggleSidebar]: (state, action) => {
        state.isToggle = action.payload
    },
    [setToggleMailing]: (state, action) => {
        state.showMailing = action.payload
    },
})

export default toggleReducer;