import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { GetClientSociete } from "../Services/SocieteService";
import { Box, Button, CircularProgress } from "@material-ui/core";
import "./Modal.css";
import { withSnackbar } from "../../utils/SnackbarHOC";


function ModalMission({ statut, setOpenModal, snackbarShowMessage }) {
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const [company, setCompany] = useState(undefined);
  const [modalState, setModalState] = useState({ open: false });
  const [employeur] = useState({});
  const [options, setOptions] = useState([]);
  const [societeNOM, setSocieteNom] = useState({});
  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;


  //Input validation
  const validate = () => {
    let temp = {};
    temp.employeur = company ? "" : "Le champ 'société' est obligatoire.";
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  React.useEffect(() => {
    let active = true;
    let employeursData;
    setSocieteNom("");

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        employeursData = await GetClientSociete();
        setOptions(employeursData.data);
      } catch (error) {
        snackbarShowMessage("Erreur de chargement du des sociétés.", "error", 4000);
      } finally {
        handleClose();
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    let active = true;
    window.scrollTo({ top: 0, behavior: "smooth" });

    return () => {
      active = false;
    };
  }, []);

  const handleClose = () => {
    if (loading) {
      return;
    }
    setModalState({ open: false });
  };


  return (
    <div className="modalBackground">
      <Box sx={{ background: "white", width: "450px", padding: "20px", borderRadius: "15px" }}>
        <div style={{ color: "rgb(17, 122, 167)" }}>
          <h4>Ajouter Mission</h4>
        </div>
        <div className="body">
          <label className="input-label" style={{ marginRight: "17px" }} htmlFor="societeId">
            SOCIÉTÉ<span className="required-input">*</span>
          </label>
          <Autocomplete
            id="societeId"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, value) => option.nom_societe === value.uuid}
            getOptionLabel={(option) => option.nom_societe}
            onChange={(event, selectedValue) => setCompany(selectedValue)}
            options={options}
            loading={loading}
            renderInput={(params) => (
              <TextField
                id="societeNOM"
                {...params}
                variant="outlined"
                fullWidth
                margin="normal"
                error={!!errors.employeur}
                helperText={errors.employeur}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </div>
        <Box style={{ display: "flex", justifyContent: "right", gap: "10px", marginTop: "20px" }}>
          <Button style={{ color: "#1b2a4e", backgroundColor: "#d9e2ef", borderColor: "#d9e2ef", marginLeft: "0.5em", fontWeight: "500", }} onClick={() => { setOpenModal(false); }} disabled={loading}>
            Annuler
          </Button>
          <Button style={{ backgroundColor: "rgb(66, 186, 150)", color: "#FFF", fontWeight: 500, "&:hover, &:focus": { backgroundColor: "#389e7f", borderColor: "#359478", }, }} onClick={() => { if (validate()) { history.push(`../societe/${company?.Uuid}/missions/add`) } }}>Ajouter
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default withSnackbar(ModalMission);
