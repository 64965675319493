import moment from "moment";

export const filterAppointmentsByStatut = (appointments, matchString) => {
    return appointments?.find(({ status }) =>
        new RegExp(matchString).test(status)
    );
};

export const extractDate = (appointments, statusRegex) => {
    const appointment = filterAppointmentsByStatut(appointments, statusRegex);
    return appointment ? moment(appointment.date).toDate().getTime() : null;
};