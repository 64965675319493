import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";
import { Backdrop, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { BsCalendar } from "react-icons/bs";
import SachezQueModal from "../../../utils/SachezQueModal";
import { withSnackbar } from "../../../utils/SnackbarHOC";

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: "600px"
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "20px",
    width: "800px",
  },
  saveButton: {
    backgroundColor: "rgb(66, 186, 150)",
    color: "#FFF",
    fontWeight: 500,
    "&:hover, &:focus": {
      backgroundColor: "#389e7f",
      borderColor: "#359478",
    },
  },
  buttonLabel: {
    paddingLeft: "0.4em",
    marginLeft: 0,
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginLeft: "0.5em",
    fontWeight: "500",
  },
  actionBar: {
    marginTop: "1.5em",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
    marginBottom: "1em",
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  takeAppointment: {
    color: "rgb(17 122 167)",
  },
  calendar: {
    marginRight: "0.5em",
  },
}));

const AppointmentsListModal = ({ appointments, step, ...otherProps }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const IntegrationRegex = /[I-i]nt[e-é]gration/;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      name: "uuid",
      label: "N° RENDEZ-VOUS",
      options: {
        display: false,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", maxWidth: "100px" } }),
      },
    },
    {
      name: "status",
      label: "STEP",
      options: {
        setCellHeaderProps: () => ({ style: { justifyContent: "center" } }),
      },
    },
    {
      name: "date",
      label: "DATE",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>
          { tableMeta.rowData[1]?.match(IntegrationRegex) ? (
            moment(value).format("DD/MM/yyyy") + "-" + moment(tableMeta.rowData[4]).format("DD/MM/yyyy") 
          ) : (
            value ? moment(value).format("DD/MM/yyyy") : "-"
          )}
          </>;
        },
        setCellHeaderProps: () => ({ style: { justifyContent: "center" } }),
      },
    },
    {
      name: "note",
      label: "NOTE",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <SachezQueModal sachezQue={value} modalTitle={"COMMENTAIRE : "} />
            </>
          );
        },
        setCellHeaderProps: () => ({ style: { justifyContent: "center" } }),
      },
    },
    { name: "dateIntegration", options: { display: false } }
  ];

  const options = {
    rowsPerPageOptions: [],
    rowsPerPage: 50,
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filter: true,
    filterType: "dropdown",
    responsive: "simple",
  };

  const body = (
    <div style={modalStyle} className={classes.paper} >
      <h2 className={classes.modalTitle}>
        <BsCalendar size={24} className={classes.calendar} />
        Liste des STEPS
      </h2>
      <div className="test" style={{ overflowY: "scroll", height: "78%", border: "none", alignItems: "normal", }}>
        <MUIDataTable data={appointments} columns={columns} options={options} style={{ overflow: "hidden", display: "flex", justifyContent: "center" }} />
      </div>
      <div className={classes.actionBar}>
        <Button className={classes.closeButton} onClick={handleClose}>
          Annuler
        </Button>
      </div>
    </div>

  );

  return (
    <>
      {step ? (
        <button className="btn btn-sm btn-link" style={{ textDecoration: "none" }} onClick={handleOpen}>
          {step}
        </button>
      ) : (
        "-"
      )}
      <Modal
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        open={open}
        onClose={handleClose}
      >
        {body}
      </Modal>
    </>
  );
}
export default withSnackbar(AppointmentsListModal);