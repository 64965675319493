import axios from "axios";

const baseURL = process.env.NODE_ENV === 'production' ? `${window.location.origin}/api` : `http://localhost:8080/api`

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // Prevent infinite loops
    if (error.response.status === 401 && originalRequest.url === "/token/refresh") {
      localStorage.clear();
      window.location.href = "/login/";
      return Promise.reject(error);
    }

    if (error.response.status === 401 && originalRequest.url === "/authentification/user/login_check") {
      return Promise.reject(error);
    }
    if (error.response.status === 401 && error.response.statusText === "Unauthorized") {
      const token = localStorage.getItem("token");
      const refresh = localStorage.getItem("refresh_token");
      if (token) {
        const tokenParts = JSON.parse(atob(token.split(".")[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);
        if (tokenParts.exp < now) {
          try {
            const response = await axiosInstance
              .post("/token/refresh", { refresh_token: refresh });
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("refresh_token", response.data.refresh_token);
            return axiosInstance(originalRequest);
          } catch (error) {
            console.log(error)
          }
        } else {
          console.log("Refresh token is expired", tokenParts.exp, now);
          window.location.href = "/login/";
        }
      } else {
        localStorage.clear();
        console.log("Refresh token not available.");
        window.location.href = "/login/";
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
