import React, { useRef, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  Card,
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
  makeStyles,
  OutlinedInput,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import "./Adduser.css";
import {
  addUser,
  getRole,
  getDetails,
  editUser,
} from "../Services/UserService";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { IoMdSave } from "react-icons/io";
import { Box } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { FormLabel } from "@material-ui/core";
import "react-phone-number-input/style.css";
import Loader from "../../components/Loader/Loader";
import { withSnackbar } from "../../utils/SnackbarHOC";
import SaveBar from "../Candidat/CandidatForm/Components/SaveBar";
import { useMediaQuery } from "@material-ui/core";
import { theme } from "../../utils/Theme";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  firstCard: {
    height: "100%",
  },
  actionArea: {
    marginTop: "1rem",
  },
}));

const FormUser = ({ history, editMode, snackbarShowMessage }) => {
  const classes = useStyles();
  const [titre, setTitre] = useState("");
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [localisation, setLocalisation] = useState("");
  const [fonction, setFonction] = useState("");
  const [telFix, setTelFix] = useState("");
  const [telMobile, setTelMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [statut, setStatut] = useState("");
  const [roles, setRoles] = useState([]);
  const [ringOverToken, setRingOverToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const mounted = useRef(false);
  const [errors, setErrors] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const title = editMode ? "MODIFIER UN UTILISATEUR" : "AJOUTER UN UTILISATEUR";
  const { id } = useParams();

  const validate = () => {
    let temp = {};
    temp.titre = titre ? "" : "Le champ 'TITRE' est obligatoire.";
    temp.prenom = prenom ? "" : "Le champ 'PRÉNOM' est obligatoire.";
    temp.nom = nom ? "" : "Le champ 'NOM' est obligatoire.";
    temp.localisation = localisation
      ? ""
      : "Le champ 'LOCALISATION' est obligatoire.";
    temp.fonction = fonction ? "" : "Le champ 'FONCTION' est obligatoire.";
    temp.telMobile = /^(?:(?:\+|00)33|0)[1-9](?:[0-9]{2}){4}$/.test(telMobile)
      ? ""
      : "Veuillez choisir un code de pays valide.";
    temp.email =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
        ? ""
        : "Le champ 'EMAIL' est incorrect.";
    if (!editMode) {
      temp.password = password
        ? ""
        : "Le champ 'MOT DE PASSE' est obligatoire.";
    }
    temp.statut = statut ? "" : "Le champ 'STATUT' est obligatoire.";

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = () => {
    if (validate()) {
      (async () => {
        setLoading(true);
        const data = {
          titre: titre,
          nom: nom,
          prenom: prenom,
          localisation: localisation,
          fonction: fonction,
          tel_fixe: telFix,
          tel_mobile: telMobile,
          email: email,
          plainPassword: password,
          role: statut,
          ringOverKey: ringOverToken
        };
        try {
          snackbarShowMessage("Patientez svp...", "warning", 4000);
          await addUser(data);
          history.goBack();
          snackbarShowMessage("Bien ajouté.", "success", 4000);
        } catch (error) {
          if (!mounted.current) {
            return;
          }
          snackbarShowMessage("Erreur d'ajout d'utilisateur.", "error", 4000);
        } finally {
          setLoading(false);
        }
      })();
    }
  };

  const handleUpdate = () => {
    if (validate()) {
      (async () => {
        const data = {
          titre: titre,
          nom: nom,
          prenom: prenom,
          localisation: localisation,
          fonction: fonction,
          tel_fixe: telFix,
          tel_mobile: telMobile,
          email: email,
          plainPassword: password,
          role: statut,
          ringOverKey: ringOverToken
        };
        try {
          snackbarShowMessage("Patientez svp...", "warning", 4000);
          await editUser(data, id);
          history.goBack();
          snackbarShowMessage("Bien modifié.", "success", 4000);
        } catch (error) {
          if (!mounted.current) {
            return;
          }
          snackbarShowMessage(
            "Erreur de modification d'utilisateur.",
            "error",
            4000
          );
        } finally {
          setLoading(false);
        }
      })();
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        setLoadingData(true);
        const roles = await getRole();
        setRoles(roles.data);
        if (editMode) {
          const userDetails = await getDetails(id);
          setTitre(userDetails.data.titre);
          setPrenom(userDetails.data.prenom);
          setNom(userDetails.data.nom);
          setLocalisation(userDetails.data.localisation);
          setFonction(userDetails.data.fonction);
          setTelFix(userDetails.data.telFixe);
          setTelMobile(userDetails.data.telMobile);
          setEmail(userDetails.data.email);
          setPassword(userDetails.data.password);
          setStatut(userDetails.data.statut);
          setLoadingData(false);
          setRingOverToken(userDetails.data.ringOverKey);
        }
      } catch (error) {
        snackbarShowMessage("Erreur de chargement des données.", "error", 4000);
      } finally {
        setLoadingData(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));

  const saveBarProps = {
    handleSubmit,
    editMode,
    handleEdit: handleUpdate,
    loading: loading,
  };

  return (
    <Box sx={{ padding: "0 20px " }}>
      <Box style={{ marginTop: "1em" }} className="candidatedit_container">
        {loadingData && editMode ? (
          <Loader />
        ) : (
          <>
            <Box className="candidatedit_header">
              <Grid
                container
                spacing={1}
                direction={isSmMode ? "column-reverse" : ""}
              >
                <Grid item xs={12} sm={6} md={6} xl={6}>
                  <h4
                    className="text-capitalize"
                    style={{ color: "rgb(17, 122, 167)" }}
                  >
                    {title}
                  </h4>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6}>
                  <SaveBar item {...saveBarProps} />
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Card className={classes.firstCard}>
                  <CardContent>
                    <label className="input-label" htmlFor="idCandidat">
                      TITRE <span className="required-input">*</span>
                    </label>
                    <FormControl variant="outlined" fullWidth margin="normal">
                      <InputLabel
                        {...(errors.titre && {
                          error: true,
                        })}
                        shrink
                      >
                        {errors.titre}
                      </InputLabel>
                      <Select
                        name="titre"
                        value={titre}
                        onChange={(e) => setTitre(e.target.value)}
                        {...(errors.titre && {
                          error: true,
                        })}
                        input={
                          <OutlinedInput
                            name="titre"
                            {...(errors.titre && {
                              labelWidth: 230,
                            })}
                            notched
                          ></OutlinedInput>
                        }
                      >
                        <MenuItem value="Mr">
                          <em>Mr</em>
                        </MenuItem>
                        <MenuItem value="Mme">
                          <em>Mme</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <label className="input-label" htmlFor="prenom">
                      PRÉNOM<span className="required-input">*</span>
                    </label>
                    <TextField
                      onChange={(e) => setPrenom(e.target.value.toLowerCase())}
                      name="prenom"
                      value={prenom}
                      placeholder="PRÉNOM"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      {...(errors.prenom && {
                        error: true,
                        label: errors.prenom,
                      })}
                    />
                    <label className="input-label" htmlFor="nom">
                      NOM<span className="required-input">*</span>
                    </label>
                    <TextField
                      onChange={(e) => setNom(e.target.value.toUpperCase())}
                      name="nom"
                      value={nom}
                      placeholder="NOM"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      {...(errors.nom && {
                        error: true,
                        label: errors.nom,
                      })}
                    />
                    <label className="input-label" htmlFor="email">
                      EMAIL<span className="required-input">*</span>
                    </label>
                    <TextField
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      value={email}
                      placeholder="EMAIL"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      {...(errors.email && {
                        error: true,
                        label: errors.email,
                      })}
                    />
                    {editMode ? (
                      <>
                        <label className="input-label" htmlFor="password">
                          MOT DE PASSE
                        </label>
                        <TextField
                          onChange={(e) => setPassword(e.target.value)}
                          name="password"
                          value={password}
                          placeholder="MOT DE PASSE"
                          margin="normal"
                          variant="outlined"
                          autoComplete="off"
                          type="password"
                          fullWidth
                        />
                      </>
                    ) : (
                      <>
                        <label className="input-label" htmlFor="password">
                          MOT DE PASSE<span className="required-input">*</span>
                        </label>
                        <TextField
                          onChange={(e) => setPassword(e.target.value)}
                          name="password"
                          value={password}
                          placeholder="MOT DE PASSE"
                          margin="normal"
                          variant="outlined"
                          autoComplete="off"
                          type="password"
                          fullWidth
                          {...(errors.password && {
                            error: true,
                            label: errors.password,
                          })}
                        />
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card style={{ display: "flex", height: "100%" }}>
                  <CardContent>
                    <label className="input-label" htmlFor="mobile">
                      TÉL MOBILE<span className="required-input">*</span>
                    </label>
                    <TextField
                      onChange={(e) => setTelMobile(e.target.value)}
                      name="telFix"
                      value={telMobile}
                      placeholder="TÉL MOBILE"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      {...(errors.telMobile && {
                        error: true,
                        label: errors.telMobile,
                      })}
                    />
                    <label className="input-label" htmlFor="fonction">
                      FONCTION<span className="required-input">*</span>
                    </label>
                    <TextField
                      onChange={(e) => setFonction(e.target.value)}
                      name="fonction"
                      value={fonction}
                      placeholder="fonction"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      {...(errors.fonction && {
                        error: true,
                        label: errors.fonction,
                      })}
                    />
                    <label className="input-label" htmlFor="statut">
                      STATUT <span className="required-input">*</span>
                    </label>
                    <FormControl variant="outlined" fullWidth margin="normal">
                      <InputLabel
                        {...(errors.statut && {
                          error: true,
                        })}
                        shrink
                      >
                        {errors.statut}
                      </InputLabel>
                      <Select
                        value={statut}
                        name="statut"
                        onChange={(e) => {
                          setStatut(e.target.value);
                        }}
                        placeholder={statut}
                        {...(errors.statut && {
                          error: true,
                        })}
                        input={
                          <OutlinedInput
                            name="statut"
                            {...(errors.statut && {
                              labelWidth: 250,
                            })}
                            notched
                          ></OutlinedInput>
                        }
                      >
                        {loadingData ? (
                          <CircularProgress size={24} />
                        ) : (
                          roles.map((role) => (
                            <MenuItem value={role.uuid} key={role.uuid}>
                              {role.role.replace("ROLE_", "").replace("_", " ")}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                    <Box pt={1.4}>
                      <FormControl
                        component="fieldset"
                        {...(errors.localisation && {
                          error: true,
                        })}
                      >
                        <FormLabel
                          component="label"
                          style={{
                            color: errors.localisation
                              ? "#f44336"
                              : "rgba(0, 0, 0, 0.87)",
                            fontWeight: 500,
                            marginBottom: 27,
                          }}
                        >
                          LOCALISATION<span className="required-input">*</span>
                        </FormLabel>
                        <RadioGroup
                          name="localisation"
                          value={localisation}
                          onChange={(e) => setLocalisation(e.target.value)}
                          row
                        >
                          <FormControlLabel
                            value="rabat"
                            control={<Radio />}
                            label="RABAT"
                          />
                          <FormControlLabel
                            value="paris"
                            control={<Radio />}
                            label="PARIS"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <label className="input-label" htmlFor="ringovertoken">
                      TOKEN RINGOVER
                    </label>
                    <TextField
                      onChange={(e) => setRingOverToken(e.target.value)}
                      name="ringovertoken"
                      value={ringOverToken}
                      placeholder="Token ringover..."
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
};

export default withSnackbar(withRouter(FormUser));
