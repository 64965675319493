import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { withSnackbar } from "../../utils/SnackbarHOC";
import { theme } from "../../utils/Theme";
import { getAll, getListDataEspaceMission } from "../Services/MissionService";
import MissionsTable from "./Components/MissionsTable";
import "./Listermission.css";
import ModalMission from "./ModalMission";


const Listermission = ({ history, snackbarShowMessage }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const statutFromURL = searchParams.get("statut") ? searchParams.get("statut") : '910f357b-0b13-4f66-89a8-5c06964c1dcf'
  const clientsFromURL = searchParams.getAll("clients[]") ? searchParams.getAll("clients[]") : []
  const keyFromURL = searchParams.get("keyword") ? searchParams.get("keyword") : ""
  const [statut, setStatut] = useState(statutFromURL.toString())
  const [loadingListData, setLodingListData] = useState(true);
  const [listData, setListData] = useState({ societesClients: [], statutsMission: [] });
  const [clients, setClients] = useState(clientsFromURL);
  const [keyword, setKeyWord] = useState(keyFromURL.toString());
  const [page, setPage] = useState(1);
  const [reloadData, setReloadData] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [missionData, setMissionData] = useState({
    data: [],
    isLoading: false,
    totalMissions: 0,
  });

  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));

  const resetParamsUrl = () => {
    const params = new URLSearchParams();
    params.append('statut', '910f357b-0b13-4f66-89a8-5c06964c1dcf');
    return params;
  }

  const getInitialParameters = () => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.toString()) return searchParams;
    return resetParamsUrl();
  };

  const handlePageChange = (page) => {
    setPage(page + 1);
  };

  React.useEffect(() => {
    let active = true;
    (async () => {
      setLodingListData(true);
      try {
        const responseListData = await getListDataEspaceMission();
        if (!active) {
          return;
        }
        setListData({ societesClients: responseListData.data.societesClients, statutsMission: responseListData.data.statutMission });
        setLodingListData(false);
      } catch (error) {
        if (!active) {
          return;
        }
        snackbarShowMessage("Erreur de chargement des données des listes.", "error", 4000);
        setLodingListData(false);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showLoading = () => {
    setMissionData((prevState) => ({
      ...prevState,
      data: [],
      isLoading: true,
    }));
  }

  React.useEffect(() => {
    let active = true;
    if (!page) return;

    const fetchData = async () => {
      showLoading();
      try {
        const { data } = await getAll(getInitialParameters());
        if (!active) return;
        setMissionData({
          isLoading: false,
          data: data.missions,
          totalMissions: data.count,
        });
      } catch (error) {
        snackbarShowMessage("Erreur de chargement des missions.", "error", 4000);
        setMissionData({
          isLoading: false,
        });
      }
    };
    fetchData();
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, reloadData, location.search]);

  const updateURLParams = ({ clients, statut, keyword }) => {
    const params = new URLSearchParams();
    clients.forEach((value) => {
      params.append(`clients[]`, value);
    });
    params.append('statut', statut);
    params.append("keyword", keyword);
    history.push(`/missions?${params.toString()}`);
  };

  const handleFilterByMissionState = (e) => {
    setStatut(e.target.value);
    updateURLParams({ clients: clients, statut: e.target.value, keyword });
  };

  const handleFilterByClients = (clients) => {
    setClients(clients.target.value);
    updateURLParams({ clients: clients.target.value, statut, keyword });
  };

  const handleFilterByKeyword = () => {
    updateURLParams({ clients: clients, statut, keyword });
  };

  const editMission = (missionId, societeId) => {
    history.push({ pathname: `/societe/${societeId}/missions/${missionId}/update` });
  };


  return (
    <Box sx={{ padding: "0 20px" }}>
      <Grid container spacing={1} style={{ paddingBottom: "5px" }}>
        <Grid item xs={10} sm={6} md={6} xl={6}>
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <h2 className="text-capitalize" style={{ color: "rgb(17, 122, 167)" }}>
              LISTE DES MISSIONS
            </h2>
          </Box>
        </Grid>
        <Grid item xs={2} sm={6} md={6} xl={6}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            {!isSmMode ? <button
              className="openModalBtn btn btn-primary "
              style={{ backgroundColor: "rgb(17, 122, 167)", color: "while", fontWeight: 500 }}
              onClick={() => {
                setModalOpen(true);
              }}>
              AJOUTER UNE MISSION
            </button>
              : <IconButton data-toggle="tooltip"
                title="Ajouter" aria-label="delete" onClick={() => setModalOpen(true)}>
                <AddIcon />
              </IconButton>
            }
          </Box>
        </Grid>
      </Grid>
      {modalOpen && <ModalMission setOpenModal={setModalOpen} />}
      <Box className="mission_content">
        <Card style={{ padding: "1em", marginBottom: "1em" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <strong>STATUT MISSIONS</strong>
              <FormControl variant="outlined" fullWidth margin="normal">
                {loadingListData ? (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress />
                  </Box>
                ) : (
                  <Select
                    name="statut"
                    value={statut}
                    onChange={(e) => handleFilterByMissionState(e)}
                  >
                    {loadingListData ? (
                      <Box display="flex" minHeight="100" justifyContent="center">
                        <CircularProgress />
                      </Box>
                    ) : (
                      listData.statutsMission.map((statut) => (
                        <MenuItem value={statut.uuid} key={statut.uuid}>
                          {statut.statut}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <strong>RECHERCHER PAR CLIENTS</strong>
              <FormControl variant="outlined" fullWidth margin="normal">   {loadingListData ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                <Select
                  multiple
                  name="clients"
                  value={clients}
                  onChange={(e) => handleFilterByClients(e)}
                  renderValue={(selected) =>
                    listData.societesClients
                      .filter((item) => selected.includes(item.uuid))
                      .map((client) => {
                        return client.nomSociete;
                      })
                      .join(", ")
                  }
                >
                  {listData.societesClients.map((client) => (
                    <MenuItem value={client.uuid} key={client.uuid}>
                      <Checkbox checked={clients.indexOf(client.uuid) > -1} />
                      <ListItemText primary={client.nomSociete.toUpperCase()} />
                    </MenuItem>
                  ))}
                </Select>)}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <strong>RECHERCHER PAR CONSULTANT(E), N° OU MOT-CLÉ</strong>
              <TextField
                name="keyword"
                value={keyword}
                onChange={(e) => {
                  setKeyWord(e.target.value);
                }}
                variant="outlined"
                autoComplete="off"
                fullWidth
                placeholder="N° ou Mot-clé"
                margin="normal"
                onBlur={() => handleFilterByKeyword()}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleFilterByKeyword()
                  }
                }}
              />
            </Grid>
          </Grid>
        </Card>
        <Box className="missions_container">
          <MissionsTable {...{ missionData, handlePageChange, editMission }} />
        </Box>
      </Box>
    </Box >
  );
};
export default withRouter(withSnackbar(Listermission));
