import { Box, Button, IconButton, useMediaQuery } from "@material-ui/core";
import { IoMdReturnLeft, IoMdSave } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { theme } from "../../../../utils/Theme";
import LoadingButtonCustom from "../../../../components/LoadingButtonCustom/LoadingButtonCustom";

export default function SaveBar({ handleSubmit, editMode = false, handleEdit, loading }) {
    const history = useHistory();
    const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));

    const loadingButtonProps = {
        handleSubmit: handleSubmit,
        handleUpdate: handleEdit,
        loading,
        editMode: editMode,
    }

    return <Box sx={{ display: "flex", gap: "10px", justifyContent: "end", alignItems: "center", height: "100%" }} >
        {
            isSmMode ?
                <>
                    <IconButton aria-label="Retourner"
                        style={{
                            color: "#1b2a4e",
                            backgroundColor: "#d9e2ef",
                            borderColor: "#d9e2ef",
                        }}
                        size="medium"
                        onClick={() => history.goBack()}>
                        <IoMdReturnLeft fontSize="18px" />
                    </IconButton>
                    <IconButton style={{
                        backgroundColor: "#42BA96",
                        color: "#FFF",
                        fontWeight: "500",
                    }} aria-label="Sauvegarder" size="large" color="success" onClick={() => editMode ? handleEdit() : handleSubmit()}>
                        <IoMdSave />
                    </IconButton>
                </>
                :
                <>
                    <Button
                        onClick={() => history.goBack()}
                        variant="outlined"
                        disabled={loading}
                        sx={{
                            color: "#1b2a4e",
                            backgroundColor: "#d9e2ef",
                            borderColor: "#d9e2ef",
                        }}
                    >
                        Annuler
                    </Button>
                    <LoadingButtonCustom {...loadingButtonProps} />
                </>
        }

    </Box>
}