import { Box, CircularProgress } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

const ComparativeTable = ({ showTsp, data, isLoading }) => {
  const options = {
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress />
        ) : (
          "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment."
        ),
      },
    },
    tableBodyMaxHeight: "32vh",
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filterType: "dropdown",
    print: false,
    columns: false,
    responsive: "standard",
    download: false,
    viewColumns: false,
    filter: false,
    search: false,
    rowsPerPage: 50,
  };

  const columns = [
    {
      name: "nom",
      label: !showTsp ? "CONSULTANTS" : "CLIENTS",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: { textAlign: "center", padding: "2px 12px" },
        }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
      },
    },
    {
      name: "Envoi",
      label: "ENVOIS",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: { textAlign: "center", padding: "2px 12px" },
        }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
      },
    },
    {
      name: "Révélation",
      label: "RÉVÉLATIONS",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: { textAlign: "center", padding: "2px 12px" },
        }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
      },
    },
    {
      name: "tr",
      label: "TR %",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
            padding: "2px 12px",
            whiteSpace: "nowrap",
            textTransform: "capitalize",
            textOverFlow: "ellipsis",
            overflow: "hidden",
          },
        }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
      },
    },
    {
      name: "Propale",
      label: "PROPALES",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
            padding: "2px 12px",
            whiteSpace: "nowrap",
            textTransform: "capitalize",
            textOverFlow: "ellipsis",
            overflow: "hidden",
          },
        }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
      },
    },
    {
      name: "Embauche/Integration",
      label: "SIGNATURES",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
            padding: "2px 12px",
            whiteSpace: "nowrap",
            textTransform: "capitalize",
            textOverFlow: "ellipsis",
            overflow: "hidden",
            maxWidth: "120px",
          },
        }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
      },
    },
    {
      name: "ts",
      label: "TS %",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { maxWidth: "100px" } }),
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
            padding: "2px 12px",
            maxWidth: "100px",
            whiteSpace: "nowrap",
            textTransform: "capitalize",
            textOverFlow: "ellipsis",
            overflow: "hidden",
          },
        }),
      },
    },
    {
      name: "tsp",
      label: "TS/P %",
      options: {
        display: showTsp,
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            maxWidth: "100px",
            whiteSpace: "nowrap",
            textTransform: "capitalize",
            textOverFlow: "ellipsis",
            overflow: "hidden",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
            padding: "2px 12px",
            maxWidth: "130px",
          },
        }),
      },
    },
  ];

  return (
    <Box sx={{ marginTop: "20px" }}>
      <MUIDataTable data={data} columns={columns} options={options} />
    </Box>
  );
};

export default withRouter(withSnackbar(ComparativeTable));
