import React, { useRef, useState } from "react";
import { CircularProgress, IconButton } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { useParams, withRouter } from "react-router-dom";
import moment from "moment";
import { getAll, deleteNote, getNoteFile, addNote, editNote } from "../../../Services/NoteSociete";
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { GrDocumentDownload } from "react-icons/gr";
import { AttachFile, Delete, Edit } from "@material-ui/icons";
import ViewNoteSociete from "./Modals/ViewNoteSociete";
import DialogDeleteCustom from "../../../../components/DialogDeleteCustom/DialogDeleteCustom";
import FormNoteModal from "../../../../components/FormNoteModal/FormNoteModal";
import { getUserData } from "../../../../utils/FunctionHelpers";
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import LabelReadMoreReadLess from "../../../../components/LabelReadMoreReadLess/LabelReadMoreReadLess";
import EditorCustom from "../../../../components/EditorCustorm/EditorCustom";
import { removeLineBreak } from "../../../../utils/EditorUtil";
import { Download } from "@mui/icons-material";


function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const NoteSociete = ({ snackbarShowMessage }) => {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [notesSociete, setNotesSociete] = useState([]);
  const [noteSocieteInfos, setNotesSocieteInfos] = useState({ auteur: "", id: "" });
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [loadingToDelete, setLoadingToDelete] = useState(false);
  const [reloadingData, setReloadingData] = useState(false);
  const [downloadingFile, setDownloadingFile] = useState(false);
  const [modalState, setModalState] = useState({ open: false, ecole: "" });
  const [pj, setPj] = useState(null);
  const [comment, setComment] = useState("");
  const [modalStyle] = useState(getModalStyle);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const active = useRef(false);
  const [textEdit, setTextEdit] = useState(true);
  const [idNoteSeleted, setIdNoteSeleted] = useState(null);
  const [downloadingRowIndex, setDownloadingRowIndex] = useState(null);
  const [dataUpload, setDataUpload] = useState({});
  const uploadRef = useRef(null);

  React.useEffect(() => {
    active.current = true;
    return () => {
      active.current = false;
    };
  }, []);

  React.useEffect(() => {
    let isActive = true;
    const fetchData = async () => {
      setNotesSociete([])
      setLoading(true);
      try {
        const noteSocieteData = await getAll(id, page);
        if (!isActive) return;

        setNotesSociete(noteSocieteData.data.notesSociete);
        setCount(noteSocieteData.data.count);
      } catch (error) {
        if (!isActive) return;
        snackbarShowMessage("Erreur de chargement des notes.", "error", 4000);
      } finally {
        if (isActive) setLoading(false);
      }
    };

    fetchData();

    return () => {
      isActive = false;
    };
  }, [id, page, reloadingData]);

  const handleCloseDialog = () => {
    setOpenDialogDelete(false);
  };

  const handleOpenDialog = (data) => {
    setNotesSocieteInfos({ auteur: data.rowData[3], id: data.rowData[0] });
    setOpenDialogDelete(true);
  };

  const handlePageChange = (page) => {
    setPage(page + 1);
  };

  const handleDownload = (noteId, fileName) => {
    const FileDownload = require("js-file-download");
    (async () => {
      setDownloadingFile(true);
      try {
        setDownloadingRowIndex(noteId)
        snackbarShowMessage("Patientez svp!", "warning", null);
        let response = await getNoteFile(fileName);
        snackbarShowMessage("Bien téléchargé", "success", 4000);

        if (!active.current) {
          return;
        }
        FileDownload(response.data, fileName);
        setDownloadingFile(false);
        setDownloadingRowIndex(null)
      } catch (error) {
        if (!active.current) {
          return;
        }
        snackbarShowMessage("Erreur de téléchargement du fichier", "error", 4000);
        setDownloadingFile(false);
        setDownloadingRowIndex(null)
      }
    })();
  };

  const handleDeleteNote = () => {
    (async () => {
      setLoadingToDelete(true);
      try {
        snackbarShowMessage("Patientez svp!", "warning", null);
        const res = await deleteNote(id, noteSocieteInfos.id);
        snackbarShowMessage(res.data);
        setLoadingToDelete(false);
        handleCloseDialog();
        setReloadingData(true);
      } catch (error) {
        snackbarShowMessage("Erreur de suppression de la note.", "error", 4000);
        setLoadingToDelete(false);
      }
    })();
  };

  const validate = (comment) => {
    let temp = {};
    temp.commentaire = !(comment?.replace(/<\/?[^>]+(>|$)/g, "") == "") ? "" : "Le champ 'COMMENTAIRE' est obligatoire.";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleOpen = () => {
    setModalState({ open: true });
  };

  const handleClose = () => {
    if (loading) {
      return;
    }
    setModalState({ open: false });
    setComment("")
    setErrors("")
  };

  const handleSubmit = (comment) => {
    if (validate(comment)) {
      (async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("note", removeLineBreak(comment));
        formData.append("auteur", getUserData().uuid);
        formData.append("pj", pj);
        try {
          snackbarShowMessage("Patientez svp!", "warning", null);
          const response = await addNote(id, formData);
          snackbarShowMessage(response.data);
          setLoading(false);
          setReloadingData(!reloadingData);
          handleClose();
        } catch (e) {
          setLoading(false);
          snackbarShowMessage("Une erreur d'ajout est survenue.", "error", 4000);
          handleClose();
        }
      })();
    }
  }

  const handleUpdate = (data) => {
    (async () => {
      setLoading(true);
      let noteId = 0;
      const formData = new FormData();
      if (typeof data === 'string') {
        formData.append("note", removeLineBreak(data));
        formData.append("pj", pj);
      } else {
        const { comment, file, idNote } = data;
        formData.append("note", removeLineBreak(comment));
        formData.append("pj", file);
        noteId = idNote;
      }
      try {
        snackbarShowMessage("Patientez svp!", "warning", null);
        const response = await editNote(formData, id, idNoteSeleted ?? noteId);
        setLoading(false);
        snackbarShowMessage(response.data);
        setReloadingData(!reloadingData);
        setTextEdit(true);
        handleClose();
      } catch (e) {
        setLoading(false);
        snackbarShowMessage("Une erreur de mise à jour est survenue.", "error", 4000);
        handleClose();
      }
    })();
  };

  const handleFileChange = (event) => {
    handleUpdate({ comment: dataUpload[2], file: event.target.files[0], idNote: dataUpload[0] });
  };

  const handleRowClick = (comment, id) => {
    setComment(comment);
    setIdNoteSeleted(id);
    setTextEdit(false);
  }

  const editorCustomProps = {
    value: comment,
    setValue: setComment,
    handleSubmit: handleUpdate,
    enableOnBlur: true
  }

  const columns = [
    {
      name: "uuid",
      label: "N°NOTE",
      options: {
        display: false,
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" } }),
      },
    },
    {
      name: "date",
      label: "DATE",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>{moment(value.date).format("DD/MM/yyyy")}</>
          );
        },
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center", width: "10%" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "10%" } }),
      },
    },
    {
      name: "note",
      label: "COMMENTAIRE",
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: "left", width: '60%', padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box sx={{ cursor: "hand" }}>
                {textEdit ? (
                  <span onDoubleClick={() => handleRowClick(value, tableMeta.rowData[0])}>
                    <LabelReadMoreReadLess data={value} />
                  </span>
                ) : tableMeta.rowData[0] === idNoteSeleted ? (
                  <EditorCustom {...editorCustomProps} />
                ) : (
                  <LabelReadMoreReadLess data={value} />)
                }
              </Box >
            </>
          );
        },
      },
    },
    {
      name: "auteur",
      label: "AUTEUR",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { width: '100px', padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" } }),
      },
    },
    {
      name: "pj",
      label: "P.J",
      options: {
        setCellHeaderProps: () => ({ style: { width: '60px', padding: "2px 12px" } }),
        setCellProps: () => ({ style: { width: '60px', padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box sx={{ display: "flex" }}>
                {value ? (
                  <>
                    <IconButton disabled={loading} key={`pj ${tableMeta.rowData[0]}`} onClick={() => handleDownload(tableMeta.rowData[0], value)} data-toggle="tooltip"
                      title="Télécharger">
                      {downloadingRowIndex === tableMeta.rowData[0] ? <CircularProgress size={25} /> : <Download size={25} color="primary" style={{ fontSize: "20px" }} />}
                    </IconButton>
                    <IconButton disabled={loading} onClick={() => { uploadRef.current.click(); setDataUpload(tableMeta.rowData) }} data-toggle="tooltip"
                      title="Modifier PJ">
                      <Edit style={{ fontSize: "20px" }} color="disabled" /> {downloadingRowIndex === tableMeta.rowData[0]}
                    </IconButton>
                  </>
                ) : (
                  <IconButton disabled={loading} key={`pj ${tableMeta.rowData[0]}`} onClick={() => { uploadRef.current.click(); setDataUpload(tableMeta.rowData) }} data-toggle="tooltip"
                    title="Ajouter PJ">
                    <AttachFile style={{ fontSize: "20px" }} color="primary" />
                  </IconButton>
                )}<input
                  type="file"
                  onChange={(e) => { handleFileChange(e) }}
                  style={{ display: 'none' }}
                  ref={uploadRef}
                />
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "Action",
      label: "ACTIONS",
      options: {
        setCellHeaderProps: () => ({ style: { maxWidth: '80px', padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", width: '80px', textAlign: "center" } }),
        customBodyRender: (data, tableMeta, updateValue) => {
          return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>

              {/* <ViewNoteSociete
            commentaire={tableMeta.rowData[3]}
            dateTime={tableMeta.rowData[1]}
            auteur={tableMeta.rowData[2]}
            pj={tableMeta.rowData[4]}
            noteId={tableMeta.rowData[0]}
            setOpenSnack={setOpenSnack}
            setSnackMsg={setSnackMsg}
            setSeverity={setSeverity}
          />
          <FormNoteSociete
            {...props}
            editMode={true}
            oldCommentaire={tableMeta.rowData[3]}
            noteSocieteUuid={tableMeta.rowData[0]}
            oldPj={tableMeta.rowData[4]}
          /> */}
              <IconButton
                data-toggle="tooltip"
                title="Supprimer"
                aria-label="Supprimer"
                onClick={() => handleOpenDialog(tableMeta)}
                style={{ color: "#EF6262" }}
              >
                <Delete />
              </IconButton>
            </Box>
          );
        },
      },
    }

  ];


  const options = {
    tableBodyMaxHeight: "47vh",
    count: count,
    serverSide: true,
    rowsPerPage: 50,
    textLabels: {
      body: {
        noMatch: loading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handlePageChange(tableState.page);
          break;
        default:
      }
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filterType: "dropdown",
    filter: false,
    print: false,
    columns: false,
    responsive: "standard",
    search: false,
    download: false,
    viewColumns: false,
  };

  const formNoteProps = { errors, setPj, loading, handleSubmit, modalState: modalState.open, handleOpen, handleClose }

  const dialogDeleteProps = {
    handleClose: handleCloseDialog,
    handleOpen: openDialogDelete,
    handleDelete: handleDeleteNote,
    loading: loadingToDelete,
    label: ` Voulez vous vraiment supprimer la note de ${noteSocieteInfos?.auteur}?`
  }

  return (
    <>
      <DialogDeleteCustom {...dialogDeleteProps} />
      <FormNoteModal {...formNoteProps} />
      <MUIDataTable
        data={notesSociete}
        columns={columns}
        options={options}
        style={{ overflow: "hidden", display: "flex", justifyContent: "center" }}
      />
    </>
  );
};

export default withSnackbar(withRouter(NoteSociete));
