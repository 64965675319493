import { Container } from "react-bootstrap";
import {
  Box, Button,
  Card,
  FormControl,
  Grid, InputAdornment, makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  SwipeableDrawer,
  TextField, useMediaQuery
} from "@material-ui/core";
import { BiSearch } from "react-icons/bi";
import ButtonList from "./Controls/ButtonList";
import MultiCritere from "./Accordions/MultiCritere";
import { useState, useCallback } from "react";
import "./dashboardStyle.css"
import { useEffect } from "react";
import { getAllSearchLists } from "../Services/SearchService";
import { updateObjectStateValue } from "../../utils/FunctionHelpers";
import { withSnackbar } from "../../utils/SnackbarHOC";
import FilterListIcon from '@mui/icons-material/FilterList';
import { useSelector } from "react-redux";


const useStyles = makeStyles(() => ({
  searchByName: {
    padding: "15px",
    justifyContent: "center",
  },
  title: {
    color: "#117aa7",
    fontWeight: 700,
    fontFamily: "myFont",
  },
  classHeading: {
    color: "#FFF",
  },
  multi2: {
    marginRight: "1.5em",
    width: "100%",
  },
  MuiTypographyCaption: {
    fontWeight: 700,
  },
  fontStyle: {
    fontFamily: "myFont",
    fontSize: "14px",
  },
}));

const getInitialParameters = () => {
  const location = window.location.search
  if (location) {
    const searchParams = new URLSearchParams(location);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    return params;
  }

  return {
    prenom: "",
    categorie: "candidat",
    motsCleCategorie: "fonction",
  };
};

const Search = ({
  candidatsData,
  societesData,
  contactsData,
  handleSearch,
  snackbarShowMessage,
  history,
}) => {
  const classes = useStyles();
  const [nomFamille, setNomFamille] = useState(false);
  const [motsCleCategorie, setMotsCleCategorie] = useState("tous");
  const [multiOpen, setMultiOpen] = useState(false);
  const [searchKeys, setSearchKeys] = useState({
    motsCle: [],
    societe: "",
    selection: ""
  })
  const [loading, setLoading] = useState(false)
  const [selectionsData, setSelectionsData] = useState({
    societes: {
      data: []
    },
    selections: {
      data: []
    },
    competenceMetier: [],
    competenceSecto: [],
    consultants: [],
    managers: []
  });
  const selectedCandidatForSelection = useSelector((state) => state.candidats.candidatsSelectedForSelection);
  const [parameters, setParameters] = useState(getInitialParameters);

  const addParam = useCallback((e) => {
    const { name, value } = e.target;

    setParameters((prevParameters) => ({
      ...prevParameters,
      [name]: value || "",
    }));
  }, []);

  const settingUpListsForAutoComplete = (idKeyName, valueKeyName, data) => {
    return data.map(({
      [idKeyName]: id,
      [valueKeyName]: value,
      ...rest
    }) => ({
      id,
      value,
      ...rest
    }));
  }


  useEffect(() => {
    if (multiOpen) {
      let active = true;
      setLoading(true);
      (async () => {
        try {
          const { data } = await getAllSearchLists();
          if (!active) {
            return;
          }
          const setupSocietes = settingUpListsForAutoComplete("uuid", "nom_societe", data.societes)
          updateObjectStateValue(setSelectionsData, {
            societes: {
              data: setupSocietes,
              isLoading: loading
            },
            selections: {
              data: data.selections,
              isLoading: loading,
              label: 'SÉLECTIONS'
            },
            consultants: data.consultants,
            managers: data.managers,
            competenceSecto: data.competencesSectorielles,
            competenceMetier: data.competencesMetiers
          })
        } catch (error) {
          snackbarShowMessage("Erreur de chargement des listes.", "error", 4000)
        } finally {
          setLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiOpen]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      history.push(`/accueil/chercher?${new URLSearchParams(parameters).toString()}`);
    }
  };

  useEffect(() => {
    const updatedParams = {
      ...parameters,
      ...( { 'searchKeys': searchKeys?.motsCle?.length ? searchKeys.motsCle.join('|') :"" }),
      ...(searchKeys?.societe?.id ? { 'societe': searchKeys?.societe?.id } : {}),
      ...(searchKeys?.selection?.id ? { 'selection': searchKeys.selection.id } : {}),
    };
    setParameters(updatedParams);
  }, [searchKeys.motsCle, searchKeys?.societe, searchKeys.selection, parameters]);


  const handleNameChecked = (e) => {
    const { checked } = e.target;
    const paramName = checked ? "prenom" : "nom";
    const otherParamName = checked ? "nom" : "prenom";
    const { [paramName]: temp, ...newParameters } = parameters;
    setParameters({ ...newParameters, [otherParamName]: temp });
    setNomFamille(checked);
  };


  const multiCritereProps = {
    classes,
    motsCleCategorie,
    setMotsCleCategorie,
    multiOpen,
    addParam,
    parameters,
    searchKeys,
    setSearchKeys,
    loading,
    selectionsData,
    handleSearch,
    handleNameChecked,
    nomFamille
  };

  const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const isMediumMode = useMediaQuery((theme) => theme.breakpoints.between(767, 1023));

  return (
    <Container >
      <Card className={classes.searchByName} style={{ position: "center" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h4 className={classes.title}>RECHERCHE</h4>
          <Box sx={{ display: "flex" }}>
            {candidatsData.loadCandidats || societesData.loadSociete || contactsData.loadContacts ? <ButtonList loading={loading} selectedCandidats={selectedCandidatForSelection}></ButtonList> : ""}
            <Button startIcon={<FilterListIcon />} onClick={() => setMultiOpen(true)} className={classes.fontStyle} style={{ color: "#117aa7" }}>
              FILTRER
            </Button>
          </Box>

        </Box>

        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3}>
            <FormControl variant="outlined" fullWidth margin="normal">
              <Select
                className={classes.fontStyle}
                value={parameters && parameters.categorie}
                name="categorie"
                onChange={(e) => {
                  addParam(e);
                }}
                input={<OutlinedInput name="categorie" />}
              >
                {["candidat", "contact", "societe"].map((category) => (
                  <MenuItem
                    value={category}
                    key={category}
                    className={classes.fontStyle}
                  >
                    {category.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            {...(multiOpen
              ? { md: 9, style: { transition: "ease", transitionDuration: "0.3s" } }
              : { md: 9, style: { transition: "ease", transitionDuration: "0.2" } })}
          >
            <TextField
              {...(nomFamille ? { name: "nom" } : { name: "prenom" })}
              value={parameters && parameters.nom ? parameters.nom : parameters.prenom}
              onChange={(e) => {
                addParam(e);
              }}
              variant="outlined"
              autoComplete="off"
              fullWidth
              placeholder="Nom ou N° de dossier ou N° de téléphone ou Lien LKD"
              margin="normal"
              onKeyDown={handleKeyPress}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <BiSearch style={{
                      color: "#117aa7",
                      fontSize: "24px",
                    }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Card>
      <SwipeableDrawer
        anchor="right"
        open={multiOpen}
        onClose={() => setMultiOpen(false)}
        onOpen={() => setMultiOpen(true)}
      >
        <Box sx={{ width: isSmall || isMediumMode ? "60vw" : "35vw" }}>
          <MultiCritere {...multiCritereProps} />
        </Box>
      </SwipeableDrawer>
    </Container >
  )
}

export default withSnackbar(Search);
