import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getDetails } from "../../../Services/SocieteService";
import DescriptionModal from "./Modals/DescriptionModal";
import DescriptionSociete from "../../../../components/DescriptionSociete/DescritionSociete";
import { withSnackbar } from "../../../../utils/SnackbarHOC";


const Descripsociete = ({ snackbarShowMessage }) => {
  const [loadingDescription, setLoadingDescription] = useState(true);
  const [description, setDescription] = useState("");
  const { id } = useParams();
  const [reloadDescription, setReloadDescription] = useState(false);
  const descriptionModalProps = { description, setReloadDescription, reloadDescription };

  React.useEffect(() => {
    (async () => {
      try {
        setLoadingDescription(true)
        const societeData = await getDetails(id);
        setDescription(societeData.data.description_societe);
      } catch (error) {
        snackbarShowMessage("Erreur de chargement de la description de la société.", "error", 4000);
      } finally {
        setLoadingDescription(false);
      }
    })();
  }, [reloadDescription]);

  return (
    <>
      <DescriptionModal {...descriptionModalProps} />
      <DescriptionSociete loading={loadingDescription} description={description} />
    </>
  );
};
export default withSnackbar(Descripsociete);
