import { Tabs, Tab, Box, Card, Typography, useMediaQuery, Grid, Table, TableBody, TableRow, TableCell, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import NotesContact from "./Tabs/NotesContact";
import MissionEnCours from "./Tabs/MissionEnCours";
import { getDetails } from "../../Services/ContactService";
import 'moment/locale/fr';
import { getUserData } from "../../../utils/FunctionHelpers";
import { LayoutContainer } from "../../../layout/LayoutContainer/LayoutContainer";
import { AssignmentOutlined, LinkedIn, NotesOutlined } from "@material-ui/icons";
import { theme } from "../../../utils/Theme";
import { GiOnTarget } from "react-icons/gi";
import Loader from "../../../components/Loader/Loader";
import TabPanelTaches from "../../../components/TabPanelTaches/TabPanelTaches";
import { deleteTacheContact, getAll, getAllTachesFaites } from "../../Services/TacheContactService";
import { TABLE } from "../../../enums/tablesEnum";
import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'
import "./VisualiserContact.css"
import LabelHtmlToTextCustom from "../../../components/LabelHtmlToTextCustom/LabelHtmlToTextCustom";
import { v4 } from "uuid";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index &&
        <Box pt={2} px={1} pb={1}>
          {children}
        </Box>
      }
    </div>
  );
}


const VisualiserContactDrawer = ({ history, idContact, idSociete }) => {
  const useStyles = makeStyles({
    table: {
      marginTop: 20,
      boxShadow: "rgb(0 0 0 / 50%) 0px 0px 15px",
    },
    cards: {
      boxShadow: "none",
    },
    contactTable: {
      "& strong": {
        color: "#117aa7",
      },
    },
  });

  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [contact, setContact] = useState({});
  const [loading, setLoading] = useState(true);
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));
  const isMdMode = useMediaQuery(theme.breakpoints.between(481, 992));
  const props = {
    nomSociete: contact.nom_societ,
    deleteTache: deleteTacheContact,
    getAll, getAllTachesFaites,
    tacheFrom: TABLE.CONTACT, ...contact
  };


  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const { data } = await getDetails(idContact);
        if (isMounted) {
          setContact(data);
          setLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [idContact]);

  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    { name: "NOTES", icon: <NotesOutlined /> },
    { name: "TÂCHES", icon: <AssignmentOutlined /> },
    { name: "MISSIONS", icon: <GiOnTarget /> },
  ]

  const tabPanelProps = [
    {
      index: 0,
      component: <NotesContact {...props} />,
    },
    {
      index: 1,
      component: <TabPanelTaches  {...props} />,
    },
    {
      index: 2,
      component: <MissionEnCours {...props} />,
    },
  ]

  return (
    <LayoutContainer sx={{ width: '100%', height: "100%" }}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card className="p-3">
            <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Link to={{ pathname: `/societe/${contact.societeId}/view` }} className="d-print-none" style={{ textDecoration: "none" }}>
                    <Typography variant="h5" style={{ fontWeight: 600, fontSize: "1.5em", color: " rgb(103, 107, 132)", textTransform: "capitalize" }}>
                      {contact.nom_societe}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Typography variant="h5" style={{ fontSize: "1.4rem", color: "#117aa7", textTransform: "capitalize", fontWeight: 600, }}>
                    {!(isSmMode || isMdMode) && contact.titre} {`${contact.prenom} ${contact.nom}`}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Box sx={{ height: (window.innerHeight - 110), marginTop: 10 }}>
            <ReflexContainer orientation="horizontal">
              <ReflexElement className="left-pane" >
                <Box className={classes.cards}>
                  <Grid container >
                    {[
                      {
                        rows: [
                          {
                            label: 'MOBILE',
                            value: contact.mobile ? contact.mobile.replace(/(\d{2})\D?(\d{1})\D?(\d{2})\D?(\d{2})\D?(\d{2})\D?(\d{2})/, "$1 $2 $3 $4 $5 $6") : "-",
                          },
                          {
                            label: 'TÉL.FIXE DIRECT',
                            value: contact.telFix ? contact.telFix.replace(/(\d{2})\D?(\d{1})\D?(\d{2})\D?(\d{2})\D?(\d{2})\D?(\d{2})/, "$1 $2 $3 $4 $5 $6") : "-",
                          },
                          {
                            label: 'EMAIL PRO',
                            value: contact.email,
                          },
                          {
                            label: 'LINKEDIN',
                            value: (
                              <a href={contact.linkedIn} target="_blank" rel="noreferrer">
                                <LinkedIn sx={{ height: 20 }} />
                              </a>
                            ),
                          },
                          {
                            label: 'DIRECTION',
                            value: contact.directionName ? contact.directionName.toUpperCase() : "-",
                          },
                          { label: 'SOCIÉTÉ', value: contact.nom_societe },
                          { label: 'FONCTION', value: contact.fonction },
                          { label: 'STATUT', value: contact.statut },
                          { label: 'ACCOUNT MANAGER', value: contact.Manager },
                          { label: 'SACHEZ QUE', value: <LabelHtmlToTextCustom data={contact.sachezQue} /> },
                        ],
                      },
                    ].map((section, index) => (
                      <Grid
                        key={v4()}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        item
                        style={{
                          backgroundColor: "rgb(17 72 167 / 4%)",
                          padding: "0px 8px",
                        }}
                      >
                        <Table className="table table-striped">
                          <TableBody component="tbody">
                            {section.rows.map((row) => (
                              <TableRow key={v4()}>
                                <TableCell scope="row">
                                  <strong>{row.label}</strong>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {row.value !== "null" ? row.value : "-"}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </ReflexElement>
              <ReflexSplitter style={{ cursor: "move" }}>
                <Box
                  sx={{
                    backgroundColor: "#117aa7",
                    color: "white",
                  }}>
                  <Tabs
                    value={value}
                    onChange={handleTabs}
                    orientation="horizontal"
                  >
                    {
                      tabs.map(tab => (<Tab key={tab.name} style={{ minWidth: isSmMode ? 50 : isMdMode ? 60 : 64 }} label={
                        <Box sx={{ display: "flex", gap: 5, alignItems: "center" }} >
                          {tab.icon}
                          {!(isSmMode || isMdMode) && <span>{tab.name}</span>}
                        </Box>} />))
                    }
                  </Tabs>
                </Box>
              </ReflexSplitter>
              <ReflexElement className="right-pane" maxSize={600} minSize={window.innerHeight - 510} style={{ overflow: "hidden" }}>
                <Box sx={{ backgroundColor: "#f4f7fc", marginTop: "50px" }}>
                  {tabPanelProps.map((tabData) => (
                    <TabPanel key={tabData.index + value} value={value} index={tabData.index}>
                      {tabData.component}
                    </TabPanel>
                  ))}
                </Box>
              </ReflexElement>
            </ReflexContainer>
          </Box>
        </>)
      }
    </LayoutContainer >
  );
};

export default withRouter(VisualiserContactDrawer);
