import api from "../../utils/API";

async function loginUser(credentials) {
  return api.post("/authentification/user/login_check", credentials);
}

function logout(history) {
  localStorage.clear();
  history.push("/login");
}

function getCurrentUser() {
  return JSON.parse(localStorage.getItem("user"));
}

export {loginUser, logout, getCurrentUser};
