import { createReducer } from "@reduxjs/toolkit";
import {
    displaySwipeableDrawerSummaryCandidat,
    displaySwipeableDrawerSummaryContact,
    displaySwipeableDrawerSummarySociete,
    undisplaySwipeableDrawerSummary
} from "../actions/swipeableDrawerActions"
import { TABLE } from "../../../enums/tablesEnum";


const initialState = {
    idCandidatSelected: null,
    idContactSelected: null,
    idSocieteSelected: null,
    show: false,
    table: ""
};

const updateSwipeableDrawer = ({ state, payload, table }) => {
    return {
        ...state,
        [`id${table.charAt(0).toUpperCase() + table.slice(1)}Selected`]: payload.id,
        show: payload.show,
        table
    };
};

const swipeableReducer = createReducer(initialState, {
    [displaySwipeableDrawerSummaryCandidat]: (state, { payload }) => {
        return updateSwipeableDrawer({ state, payload, table: TABLE.CANDIDAT });
    },
    [displaySwipeableDrawerSummaryContact]: (state, { payload }) => {
        return {
            ...state,
            idContactSelected: payload.idContact,
            idSocieteSelected: payload.idSociete,
            show: payload.show,
            table: TABLE.CONTACT
        };
    },
    [displaySwipeableDrawerSummarySociete]: (state, { payload }) => {
        return updateSwipeableDrawer({ state, payload, table: TABLE.SOCIETE });
    },
    [undisplaySwipeableDrawerSummary]: (state, action) => {
        return { ...initialState };
    },
});

export default swipeableReducer;