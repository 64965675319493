import { Send } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import { IoMdSave } from "react-icons/io";

export default function LoadingButtonCustom({
  title = "Sauvegarder",
  editMode = false,
  labelButton = null,
  handleUpdate = () => {},
  handleSubmit,
  loading,
  reload = () => {},
}) {
  const handle = () => {
    (editMode ? handleUpdate : handleSubmit)();
    reload(true);
  };

  return (
    <LoadingButton
      loading={loading}
      onClick={handle}
      loadingPosition="center"
      variant="contained"
      autoFocus
      disabled={loading}
      type="submit"
      sx={{
        boxShadow:
          labelButton != null ? "none" : "0px 3px 1px -2px rgba(0,0,0,0.2)",
        color: labelButton != null ? "#EF6262" : "#FFF",
        backgroundColor: labelButton != null ? "#FFF" : "#42BA96",
        fontWeight: 500,
        "& .MuiCircularProgress-root": {
          color: "#FFF",
        },
        "&:hover": {
          backgroundColor:
            labelButton != null ? "rgba(0, 0, 0, 0.04)" : "#358d6e",
        },
      }}
      startIcon={
        labelButton != null ? (
          <></>
        ) : title === "Envoyer" ? (
          <Send />
        ) : (
          <IoMdSave />
        )
      }
    >
      {labelButton != null ? labelButton : editMode ? "Modifier" : title}
    </LoadingButton>
  );
}
