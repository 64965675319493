import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import { withRouter } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CircularProgress } from "@material-ui/core";
import CustomAutoComplete from "../../../common/CustomAutoComplete";
import { updateObjectStateValue } from "../../../utils/FunctionHelpers";
import { Button } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";

const MultiCritere = ({
  classes,
  addParam,
  parameters,
  searchKeys,
  setSearchKeys,
  loading,
  selectionsData,
  handleSearch,
  nomFamille,
  handleNameChecked,
}) => {
  const handleDeleteChip = (index) => {
    const newChips = [...searchKeys.motsCle];
    newChips.splice(index, 1);

    updateObjectStateValue(setSearchKeys, { motsCle: newChips });
  };

  const handleChangeSociete = (societe) => {
    updateObjectStateValue(setSearchKeys, { societe });
  };

  const handleChangeSelection = (selection) => {
    updateObjectStateValue(setSearchKeys, { selection });
  };

  return (
    <Box style={{ overflowY: "hidden", width: "100%", maxHeight: "100vh" }}>
      <Box style={{ backgroundColor: "#117aa7", padding: "16px" }}>
        <Typography className={classes.classHeading}>
          FILTRER PAR MOT CLÉ
        </Typography>
      </Box>
      <Box style={{ paddingTop: "22px", overflowY: "auto", padding: "16px", maxHeight: "94vh" }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={12}>
            <FormControl variant="outlined" fullWidth margin="normal">
              <Select
                value={parameters.motsCleCategorie}
                name="motsCleCategorie"
                onChange={(e) => addParam(e)}
                input={<OutlinedInput name="categorie"></OutlinedInput>}
              >
                {[
                  { value: "fonction", label: "FONCTION" },
                  //{ value: "tous les champs", label: "TOUS LES CHAMPS" },
                  //{ value: "notes", label: "NOTES" },
                  //{ value: "cv", label: "CV" },
                  { value: "tous", label: "TOUS" },
                ].map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
              <FormControl variant="outlined" fullWidth margin="normal">
                <CustomAutoComplete
                  listData={selectionsData.selections}
                  value={searchKeys.selection}
                  handleDataChange={handleChangeSelection}
                  name="selection"
                  onChange={(e) => addParam(e)}
                />
              </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box>
              <ChipInput
                value={searchKeys.motsCle}
                onAdd={(chip) =>
                  updateObjectStateValue(setSearchKeys, {
                    motsCle: [...searchKeys.motsCle, chip],
                  })
                }
                onDelete={(chip, index) => handleDeleteChip(index)}
                margin="normal"
                variant="outlined"
                autoComplete="off"
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <label className="input-label">CONSULTANT</label>
              <FormControl variant="outlined" fullWidth margin="normal">
                <Select
                  value={
                    parameters && parameters.sourceur ? parameters.sourceur : ""
                  }
                  name="sourceur"
                  onChange={(e) => addParam(e)}
                >
                  {loading
                    ? [
                      <Box display="flex" justifyContent="center">
                        <CircularProgress />
                      </Box>,
                    ]
                    : [
                      <MenuItem value="" key="-">
                        -
                      </MenuItem>,
                      selectionsData.consultants.map((user) => (
                        <MenuItem value={user.uuid} key={user.uuid}>
                          {user.user}
                        </MenuItem>
                      )),
                    ]}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <label className="input-label">ACCOUNT MANAGER</label>
              <FormControl variant="outlined" fullWidth margin="normal">
                <Select
                  defaultValue=""
                  value={
                    parameters && parameters.consultant
                      ? parameters.consultant
                      : ""
                  }
                  name="consultant"
                  onChange={(e) => addParam(e)}
                >
                  {loading
                    ? [
                      <Box display="flex" justifyContent="center">
                        <CircularProgress />
                      </Box>,
                    ]
                    : [
                      <MenuItem value="" key="-">
                        -
                      </MenuItem>,
                      selectionsData.managers.map((user) => (
                        <MenuItem value={user.uuid} key={user.uuid}>
                          {user.user}
                        </MenuItem>
                      )),
                    ]}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box>
              <Typography noWrap>COMPÉTENCES MÉTIERS</Typography>
              <FormControl variant="outlined" fullWidth margin="normal">
                <Select
                  value={
                    parameters && parameters.competenceMetier
                      ? parameters.competenceMetier
                      : ""
                  }
                  name="competenceMetier"
                  onChange={(e) => addParam(e)}
                  input={<OutlinedInput name="statutCandidat"></OutlinedInput>}
                >
                  {loading
                    ? [
                      <Box display="flex" justifyContent="center">
                        <CircularProgress />
                      </Box>,
                    ]
                    : [
                      <MenuItem value="" key="-">
                        -
                      </MenuItem>,
                      selectionsData.competenceMetier.map((compt) => (
                        <MenuItem value={compt.uuid} key={compt.uuid}>
                          {compt.metier}
                        </MenuItem>
                      )),
                    ]}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item={true} xs={12} md={12}>
            <Box>
              <Typography noWrap>COMPÉTENCES SECTORIELLES</Typography>
              <FormControl variant="outlined" fullWidth margin="normal">
                <Select
                  value={
                    parameters && parameters.competenceSecto
                      ? parameters.competenceSecto
                      : ""
                  }
                  name="competenceSecto"
                  onChange={(e) => addParam(e)}
                  input={<OutlinedInput name="statutCandidat"></OutlinedInput>}
                >
                  {loading
                    ? [
                      <Box display="flex" justifyContent="center">
                        <CircularProgress />
                      </Box>,
                    ]
                    : [
                      <MenuItem value="" key="-">
                        -
                      </MenuItem>,
                      selectionsData.competenceSecto.map((compt) => (
                        <MenuItem value={compt.uuid} key={compt.uuid}>
                          {compt.secteur}
                        </MenuItem>
                      )),
                    ]}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item={true} xs={12} md={12}>
            <Box className={classes.multi2}>
              <Typography noWrap>COMPÉTENCES TECHNIQUES</Typography>
              <TextField
                name="competenceTechnique"
                value={
                  parameters && parameters.competenceTechnique
                    ? parameters.competenceTechnique
                    : ""
                }
                onChange={(e) => addParam(e)}
                margin="normal"
                variant="outlined"
                autoComplete="off"
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box className={classes.multi2}>
              <Typography noWrap>SOCIÉTÉ</Typography>
              <FormControl variant="outlined" fullWidth margin="normal">
                <CustomAutoComplete
                  listData={selectionsData.societes}
                  value={searchKeys.societe}
                  handleDataChange={handleChangeSociete}
                  name="societe"
                  onChange={(e) => addParam(e)}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box>
              <Typography noWrap>EXPÉRIENCE ENTRE</Typography>
              <FormControl variant="outlined" style={{ width: "40%" }}>
                <TextField
                  name="start"
                  value={parameters && parameters.start ? parameters.start : ""}
                  onChange={(e) => addParam(e)}
                  margin="normal"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  type={"number"}
                />
              </FormControl>
              <FormControl
                style={{ width: "20%", textAlign: "center", marginTop: "1em" }}
              >
                ET
              </FormControl>
              <FormControl variant="outlined" style={{ width: "40%" }}>
                <TextField
                  name="end"
                  value={parameters && parameters.end ? parameters.end : ""}
                  onChange={(e) => addParam(e)}
                  margin="normal"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  type={"number"}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item={true} xs={12} md={12}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={nomFamille}
                    onChange={(e) => handleNameChecked(e)}
                    name="nomFamille"
                    color="primary"
                  />
                }
                label={
                  <Typography
                    style={{ fontSize: "12px", fontWeight: 400 }}
                    className={classes.fontStyle}
                  >
                    NOM DE FAMILLE UNIQUEMENT
                  </Typography>
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                type="submit"
                style={{
                  backgroundColor: "#117aa7",
                  color: "#FFF",
                  fontWeight: "500",
                  width: "80%",
                }}
                startIcon={<BiSearch />}
                onClick={() => handleSearch(parameters)}
              >
                APPLIQUER
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default withRouter(MultiCritere);
