import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { deleteCandidatFromSelection, getAllCandidatsInSelection } from "../Services/SelectionCandidatService";
import { useLocation, useParams } from "react-router-dom";
import { AccessAlarm, Delete, FiberNew, LinkedIn, RemoveRedEye, Send, Update } from "@material-ui/icons";
import ShowCvCandidat from "../Candidat/VisualiserCandidat/ShowCVCandidat";
import { withSnackbar } from "../../utils/SnackbarHOC";
import { theme } from "../../utils/Theme";
import { withRouter } from "react-router-dom";
import DialogDeleteCustom from "../../components/DialogDeleteCustom/DialogDeleteCustom";
import { IoMdReturnLeft } from "react-icons/io";
import { displaySwipeableDrawerSummaryCandidat } from "../../redux/SwipeableDrawer/actions/swipeableDrawerActions";
import { useDispatch } from "react-redux";
import { useQuery } from "../../utils/FunctionHelpers";
import moment from "moment";
import CustomToolbarSelect from "../Candidat/ListCandidats/CandidatTable/CustomToolbarSelect";
import { setToggleMailing } from "../../redux/toggle/actions/toggleActions";
import { SetSelectedCandidatBeAddToSelection } from "../../redux/candidats/actions/candidatsActions";
import ShowAddCvCandidat from "../Candidat/VisualiserCandidat/ShowAddCvCandidat";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  taskPassed: {
    color: "firebrick"
  },
  taskToday: {
    color: "blue"
  },
  taskFuture: {
    color: "green"
  }
}));

const CandidatsListInSelection = ({ history, snackbarShowMessage }) => {
  const [loading, setLoading] = useState(false);
  const [candidats, setCandidats] = useState([]);
  const [reloadSelections, setReloadSelections] = useState(false);
  const { idSelection } = useParams();
  let query = useQuery();
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [candidatInfos, setCandidatInfos] = useState({ uuid: "", nom: "", prenom: "" });
  const [loadingToDelete, setLoadingToDelete] = useState(false);
  const [candidatsCount, setCandidatsCount] = useState(0);
  const classes = useStyles();
  const location = useLocation();
  const mounted = useRef(true);
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));
  const dispatch = useDispatch();

  const handleOpenDialog = (params) => {
    setCandidatInfos({ uuid: params.rowData[0], nom: params.rowData[3], prenom: params.rowData[2] });
    setOpenDialogDelete(true);
  };

  const viewCandidat = (params) => {
    let candidatsIds = [];

    candidats.forEach((candidat) => {
      candidatsIds.push(candidat.id);
    });
    history.push({
      pathname: `/selections/${idSelection}/candidats/${params.rowData[0]}/view`,
      state: {
        candidatsInList: candidatsIds,
        candidatIndex: candidatsIds.indexOf(params.rowData[0]),
        selectionName: location.state?.selectionName,
        numbersTotalOfSelections: candidats.length
      },
    });
  };

  const handleDeleteCandidat = () => {
    (async () => {
      setLoadingToDelete(true);

      const res = await deleteCandidatFromSelection(candidatInfos.uuid, idSelection);
      if (!mounted.current) {
        return;
      }
      setLoadingToDelete(false);
      snackbarShowMessage(res.data);
      handleCloseDialog();
      setReloadSelections(!reloadSelections);
    })();
  };

  const handleCloseDialog = () => {
    if (loadingToDelete) {
      return;
    }
    setOpenDialogDelete(false);
  };

  const handleTasksClasse = (value) => {
    switch (value) {
      case 3:
        return classes.taskFuture;
      case 2:
        return classes.taskToday;
      case 1:
        return classes.taskPassed;
      default:
        break;
    }
  };

  useEffect(() => {
    if (idSelection === "undefined") {
      history.push({
        pathname: "/selections",
      });
    }
    (async () => {
      setLoading(true);
      try {
        setCandidats([]);
        const { data } = await getAllCandidatsInSelection(idSelection);
        setCandidats(data.candidats);
        setCandidatsCount(data.count);
        setLoading(false);
      } catch (error) {
        snackbarShowMessage("Erreur de chargement de données.", "error", 4000);
        setLoading(false);
      }
    })();
  }, [reloadSelections, idSelection]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleShowChangeType = (statut) => {
    switch (statut) {
      case 'Update':
        return <Update color="primary" />;
      case 'New':
        return <FiberNew color="error" />;
      default:
        return "-";
    }
  };

  const columns = [
    {
      name: "id",
      label: "N°CANDIDAT",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "100px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "100px" } }),
      },
    },
    {
      name: "numCandidat",
      label: "N°",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "100px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "100px" } }),
        customBodyRender: (value, tableMeta) => {
          const files = tableMeta.rowData[14] || []; // Ensure files is an array
          return (
              <ShowAddCvCandidat  {...{ snackbarShowMessage, files }}>
                {value}
              </ShowAddCvCandidat>
          );
        },
      },
    },
    {
      name: "prenom",
      label: "PRENOM",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "200px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "200px" } }),
        customBodyRender: (value, tableMeta) => {
          return (
              <>
                {!isSmMode && (
                    <IconButton
                        onClick={() => dispatch(displaySwipeableDrawerSummaryCandidat({ show: true, id: tableMeta.rowData[0] }))}
                        data-toggle="tooltip"
                        title="Voir resumé"
                    >
                      <RemoveRedEye size={"15px"} />
                    </IconButton>
                )}
                <Button onClick={() => viewCandidat(tableMeta)} style={{ color: "rgb(55 156 255)" }}>
                  {value}
                </Button>
              </>
          );
        },
      },
    },
    {
      name: "nom",
      label: "NOM",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
              <Button onClick={() => viewCandidat(tableMeta)} style={{ color: "rgb(55 156 255)" }}>
                {value}
              </Button>
          );
        },
      },
    },
    {
      name: "updatedAt",
      label: "DATE MAJ",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100%" } }),
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textAlign: "center", width: "100%" } }),
        customBodyRender: (value) => {
          return value != null ? moment(value).format("DD/MM/yyyy") : "-";
        },
      },
    },
    {
      name: "seniorite",
      label: "XP",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { textAlign: "center", width: "200px" } }),
        customBodyRender: (value) => {
          return <Box>{value != null ? value : 0}</Box>;
        },
      },
    },
    {
      name: "fonction",
      label: "FONCTION",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { overflow: "hidden", width: "50px", textOverflow: "ellipsis", whiteSpace: "nowrap" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", width: "50px" } }),
        customBodyRender: (value) => {
          return (
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '150px' }} title={value}>
                {value}
              </div>
          );
        },
      },
    },
    {
      name: "telephone",
      label: "MOBILE",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { justifyContent: "center" } }),
      },
    },
    {
      name: "email",
      label: "EMAIL",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "200px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "200px" } }),
      },
    },
    {
      name: "nom_societe",
      label: "SOCIETE",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "linkedin",
      label: "LINKEDIN",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
              <Box style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>
                {value && (
                    <a href={value} target="_blank" rel="noreferrer">
                      <LinkedIn />
                    </a>
                )}
              </Box>
          );
        },
      },
    },
    {
      name: "consultant",
      label: "CONSULTANT",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "changeType",
      label: "STATUT",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "100px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "100px" } }),
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {handleShowChangeType(value)}
            </>
          );
        },
      },
    },
    {
      name: "task", label: "TACHES", options: {
        filter: true, sort: true
        ,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {
                value !== 0
                  ? (
                    <Box style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "60px", textAlign: "center" }}>
                      <AccessAlarm f className={handleTasksClasse(value)} />
                    </Box>
                  )
                  : ""
              }
            </>
          );
        },
      }
    },
    { name: "uploadedFiles", label: "uploadedFiles", options: { filter: true, display: false, viewColumns: false } },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        filter: false,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container>
              <Grid item xs={6}>
                <Button data-toggle="tooltip"
                  title="Supprimer" onClick={() => handleOpenDialog(tableMeta)} style={{ color: "#EF6262" }}>
                  <Delete />
                </Button>
              </Grid>
            </Grid>
          );
        },
      },
    }
  ];

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [rowSelecteds, setRowSelecteds] = useState([])
  const [showButtonMailing, setShowButtonMailing] = useState(false);
  const isSmall = useMediaQuery(theme.breakpoints.between(0, 767));
  const isMediumMode = useMediaQuery(theme.breakpoints.between(767, 1023));
  const isLargeMode = useMediaQuery(theme.breakpoints.between(1023, 2800));

  const tableBodyMaxHeight = `${isSmall ? viewportHeight - 299 : isMediumMode ? viewportHeight - 308 : isLargeMode ? viewportHeight - 284 : 200}px`;


  const options = {
    jumpToPage: true,
    tableBodyMaxHeight: tableBodyMaxHeight,
    rowsPerPage: 50,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment."
        ),
      },
    },
    rowsSelected: rowSelecteds,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "multiple",
    customToolbarSelect: (selectedRows) => (
      <CustomToolbarSelect selectedminRows={selectedRows} />
    ),
    onRowSelectionChange: (rowsSelected, allRowsSelected) => {
      setShowButtonMailing(allRowsSelected.length > 0)
      const selecionsIds = []
      const indexs = []
      allRowsSelected.forEach((element) => {
        indexs.push(element.index)
        selecionsIds.push(candidats[element.index].id)
      });
      setRowSelecteds(indexs)
      dispatch(SetSelectedCandidatBeAddToSelection(selecionsIds))
    },
  };

  const dialogDeleteProps = {
    handleClose: handleCloseDialog,
    handleOpen: openDialogDelete,
    handleDelete: handleDeleteCandidat,
    loading: loadingToDelete,
    label: `Voulez vous vraiment supprimer le candidat ${candidatInfos.nom} ${candidatInfos.prenom}" de la selection`,
    title: "Supprimer le candidat?",
  };


  const candidatsDataCustom = candidats.map((row) => {
    const updatedRow = {
      ...row,
      updatedAt: (row.updatedAt?.date != null) ? moment(row.updatedAt.date).toDate().getTime() : null,
    };
    return updatedRow;
  });

  return (
    <Box sx={{ padding: "0 20px " }}>
      <Box className="candidatedit_header">
        <Grid container spacing={1} direction={isSmMode ? "column-reverse" : "row"} >
          <Grid item xs={12} sm={6} md={6} xl={6}>
            <h4 style={{ color: "rgb(17, 122, 167)" }}>SÉLECTION : {query.get('selectionName') + ' - ' + candidatsCount}</h4>
            <h3 style={{ color: "rgb(17, 122, 167)" }}>CONSULTANT : {query.get('consultant')}</h3>
          </Grid>
          <Grid item xs={12} sm={6} md={6} xl={6}>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "end" }}>
              {!isSmMode ? showButtonMailing ? <Button color="primary" onClick={() => dispatch(setToggleMailing(true))} variant="contained" endIcon={<Send />}>Mailing</Button> : <Button
                onClick={() => history.goBack()}
                variant="outlined"
                disabled={loading}
                sx={{
                  color: "#1b2a4e",
                  backgroundColor: "#d9e2ef",
                  borderColor: "#d9e2ef",
                }}
              >
                RETOURNER
              </Button> :
                showButtonMailing ? <IconButton
                  color="primary"
                  onClick={() => dispatch(setToggleMailing(true))}
                  variant="contained"
                  size="medium"
                  data-toggle="tooltip"
                  title="Mailing">
                  <Send /></IconButton> :
                  <IconButton aria-label="Retourner"
                    data-toggle="tooltip"
                    title="Retourner"
                    style={{
                      color: "#1b2a4e",
                      backgroundColor: "#d9e2ef",
                      borderColor: "#d9e2ef",
                    }}
                    size="medium"
                    onClick={() => history.goBack()}>
                    <IoMdReturnLeft fontSize="18px" />
                  </IconButton>}
            </Box>

          </Grid>
        </Grid>
      </Box>
      <Box className="candidatedit_container">
        <MUIDataTable title={"Candidats"} data={candidatsDataCustom} columns={columns} options={options} />
        <DialogDeleteCustom {...dialogDeleteProps} />
      </Box>
    </Box>
  );
}

export default withSnackbar(withRouter(CandidatsListInSelection))
