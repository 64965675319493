import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  IconButton,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import { withSnackbar } from "../../utils/SnackbarHOC";
import { Add, Close } from "@material-ui/icons";
import { theme } from "../../utils/Theme";
import { v4 } from "uuid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DialogDeleteCustom from "../../components/DialogDeleteCustom/DialogDeleteCustom";
import { useEffect } from "react";
import { LayoutContainer } from "../../layout/LayoutContainer/LayoutContainer";
import Loader from "../../components/Loader/Loader";
import { displaySwipeableDrawerSummaryCandidat } from "../../redux/SwipeableDrawer/actions/swipeableDrawerActions";
import { useDispatch } from "react-redux";

function ProcessView({ snackbarShowMessage }) {
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));
  const [showInputList, setShowInputList] = useState(false);
  const [columns, setColumns] = useState([
    { titre: "TITRE 1" },
    { titre: "TITRE 2" },
  ]);
  const [nameList, setNameList] = useState(null);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [loadingToDelete, setLoadingToDelete] = useState(false);
  const [processToDelete, setProcessToDelete] = useState(null);
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setState([getItems(10), getItems(5, 10)]);
    setLoading(false);
  }, []);

  useEffect(() => {
    return () => {
      handleProcessSaving();
    };
  }, []);

  const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map((k) => ({
      id: `item-${k + offset}-${new Date().getTime()}`,
      fullName: `
      Jean
      KADOR`,
      mission: `Profils finance, risques, capital markets`,
      entreprise: `PEOPLEFINE`,
      uuid: "728e1044-9387-4f5f-844e-ab514a95813b",
      techs: [
        "Java",
        "Javascript",
        "Reactjs",
        "Php",
        "SpringBoot",
        "Redux Toolkit",
      ],
    }));

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: `0 0 8px 0`,
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    padding: 8,
    width: 280,
  });

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setState(newState.filter((group) => group.length));
    }
  };

  const handleProcessSaving = () => {
    (async () => {
      setLoading(true);
      const newListProcess = [...state];
      try {
        snackbarShowMessage("Patientez svp!", "warning", null);
        //   const res = await UpdateList²Process(newListProcess);
        snackbarShowMessage(
          "Fonctionnalité sauvegarde non developée",
          "error",
          4000
        );
        setState(newListProcess);
      } catch (error) {
        snackbarShowMessage("Erreur de sauvegarde des process.", "error", 4000);
      } finally {
        setLoading(false);
      }
    })();
  };

  const handleDeleteProcess = (columnIndex, itemIndex) => {
    (async () => {
      setLoadingToDelete(true);
      const newListProcess = [...state];
      newListProcess[processToDelete.columnIndex].splice(
        processToDelete.itemIndex,
        1
      );
      try {
        snackbarShowMessage("Patientez svp!", "warning", null);
        //   const res = await UpdateList²Process(newListProcess);
        snackbarShowMessage("Fonctionnalité non developée", "error", 4000);
        setState(newListProcess);
      } catch (error) {
        snackbarShowMessage(
          "Erreur de suppression de la donnée.",
          "error",
          4000
        );
      } finally {
        setLoadingToDelete(false);
        handleCloseDialog();
      }
    })();
  };

  const handleOpenDialog = ({ columnIndex, itemIndex, processName }) => {
    setProcessToDelete({
      columnIndex,
      itemIndex,
      processName,
    });
    setOpenDialogDelete(true);
  };

  const handleCloseDialog = () => {
    if (loadingToDelete) {
      return;
    }
    setOpenDialogDelete(false);
  };

  const newList = () => {
    setShowInputList(false);
    setState([...state, []]);
    setColumns((prev) => [...prev, { titre: nameList ?? "TITRE DE LA LISTE" }]);
    setNameList(null);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      newList();
    }
  };

  const dialogDeleteProps = {
    handleClose: handleCloseDialog,
    handleOpen: openDialogDelete,
    handleDelete: handleDeleteProcess,
    loading: loadingToDelete,
    label: `Voulez vous vraiment supprimer le process ${processToDelete?.processName}?`,
    title: "Supprimer le process",
  };

  return (
    <LayoutContainer>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ height: "100%" }}>
          <Grid container style={{ paddingBottom: "5px" }}>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Box
                sx={{ display: "flex", alignItems: "center", height: "100%" }}
              >
                <h2
                  className="text-capitalize"
                  style={{ color: "rgb(17, 122, 167)" }}
                >
                  PROCESS VIEW
                </h2>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Box
              sx={{
                borderRadius: "5px",
                // padding: "10px 8px",
                display: "flex",
                gap: "15px",
                height: "calc(100vh - 113px)",
                overflowX: "auto",
              }}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                {state.map((el, ind) => {
                  return (
                    <Box key={v4()}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "300px",
                          backgroundColor: "white",
                          padding: "10px",
                          borderRadius: "10px",
                          overflowY: "hidden",
                          overflowX: "auto",
                          maxHeight: "calc(100vh - 113px)",
                          scrollBehavior: "smooth",
                        }}
                      >
                        <Box
                          sx={{
                            marginRight: "auto",
                            fontWeight: "700",
                            fontSize: "18px",
                            textTransform: "uppercase",
                            color: "grey",
                            cursor: "pointer",
                            width: "100%",
                          }}
                        >
                          {columns[ind].titre}
                        </Box>
                        <Droppable key={v4()} droppableId={`${ind}`}>
                          {(provided, snapshot) => (
                            <Box
                              sx={{
                                width: "100%",
                                backgroundColor: "rgba(223, 225, 225, 0.5)",
                                overflowX: "hidden",
                                maxHeight: "calc(100vh - 220px)",
                                padding: "1px 3px 10px 1px",
                                overflowY: "auto",
                                scrollBehavior: "smooth",
                              }}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                              {...provided.droppableProps}
                            >
                              {el.map((candidat, index) => (
                                <Draggable
                                  key={v4()}
                                  draggableId={candidat.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <Card>
                                        <Box
                                          sx={{
                                            padding: "10px",
                                            // height: "100px",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              gap: "10px",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                marginRight: "auto",
                                                fontWeight: "500",
                                                fontSize: "15px",
                                                textTransform: "uppercase",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                color: "rgb(17, 122, 167)",
                                              }}
                                              data-toggle="tooltip"
                                              title={candidat.fullName}
                                              onClick={() =>
                                                dispatch(
                                                  displaySwipeableDrawerSummaryCandidat(
                                                    {
                                                      show: true,
                                                      id: candidat.uuid,
                                                    }
                                                  )
                                                )
                                              }
                                            >
                                              {candidat.fullName}
                                            </Box>
                                            <Box>
                                              <IconButton
                                                size="small"
                                                aria-label="settings"
                                                onClick={() =>
                                                  handleOpenDialog({
                                                    columnIndex: ind,
                                                    itemIndex: index,
                                                    processName:
                                                      candidat.mission,
                                                  })
                                                }
                                              >
                                                <Close />
                                              </IconButton>
                                            </Box>
                                          </Box>
                                          <Box
                                            sx={{
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                              color: "rgb(17, 122, 167)",
                                            }}
                                            data-toggle="tooltip"
                                            title={candidat.entreprise}
                                          >
                                            {candidat.entreprise}
                                          </Box>
                                          <Box
                                            sx={{
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                              marginBottom: "5px",
                                            }}
                                            data-toggle="tooltip"
                                            title={candidat.mission}
                                          >
                                            {candidat.mission}
                                          </Box>
                                          <Box
                                            sx={{
                                              overflow: "hidden",
                                              display: "flex",
                                              flexWrap: "wrap",
                                              gap: "5px",
                                            }}
                                          >
                                            {candidat.techs.map((tech) => (
                                              <Chip
                                                data-toggle="tooltip"
                                                title={tech}
                                                size="small"
                                                label={tech}
                                              />
                                            ))}
                                          </Box>
                                        </Box>
                                      </Card>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </Box>
                          )}
                        </Droppable>
                        <Box>
                          <Button
                            size="small"
                            onClick={() => {
                              const newItem = {
                                id: `item-${v4()}-${new Date().getTime()}`,
                                fullName: `
                                Jean
                                KADOR`,
                                mission: `Profils finance, risques, capital markets`,
                                entreprise: `PEOPLEFINE`,
                                uuid: "728e1044-9387-4f5f-844e-ab514a95813b",
                                techs: [
                                  "Java",
                                  "Javascript",
                                  "Reactjs",
                                  "Php",
                                  "SpringBoot",
                                  "Redux Toolkit",
                                ],
                              };
                              const newState = [...state];
                              newState[ind].push(newItem);
                              setState(newState);
                            }}
                          >
                            <span>Ajouter un process</span>
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
                <Box>
                  <Box>
                    {showInputList && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "270px",
                          backgroundColor: "white",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <TextField
                          onChange={(e) => setNameList(e.target.value)}
                          value={nameList ?? ""}
                          onKeyDown={handleKeyPress}
                          placeholder="Saisissez le titre de la liste..."
                          margin="normal"
                          variant="outlined"
                          autoComplete="off"
                          size="small"
                        />
                        <Box>
                          <Button size="small" onClick={() => newList()}>
                            <span>Ajouter une liste</span>
                          </Button>
                          <Button
                            size="small"
                            onClick={() => {
                              setShowInputList(false);
                              setNameList("");
                            }}
                          >
                            <Close fontSize="small" />
                          </Button>
                        </Box>
                      </Box>
                    )}
                    {!showInputList && (
                      <Button
                        style={{
                          height: "50px",
                          borderRadius: "20px",
                          padding: "10px",
                          width: "270px",
                        }}
                        onClick={() => setShowInputList(true)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            width: "100%",
                          }}
                        >
                          <Add />
                          <span>Ajouter une autre liste</span>
                        </Box>
                      </Button>
                    )}
                  </Box>
                </Box>
              </DragDropContext>
            </Box>
          </Box>
          <DialogDeleteCustom {...dialogDeleteProps} />
        </Box>
      )}
    </LayoutContainer>
  );
}

export default withSnackbar(ProcessView);
