// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from "react";
import {Redirect, Route} from "react-router-dom";

const PrivateRoute = ({component: Component, ...rest}) => {
  if (localStorage.getItem("token") && localStorage.getItem("token") !== "undefined") {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  if (localStorage.getItem("token") === "undefined") {
    localStorage.clear();
  }

  //redirect if there is no user
  return <Redirect to="/login" />;
};

export default PrivateRoute;
