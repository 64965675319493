import { IconButton } from '@material-ui/core';
import { useState } from 'react';
import DialogDeleteCustom from '../components/DialogDeleteCustom/DialogDeleteCustom';

export default function DialogModal(props) {
  const [open, setOpen] = useState(false);
  const { label, description, title, loading, onConfirm, data } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm(data, handleClose);
  }

  const dialogDeleteProps = {
    handleClose: handleClose,
    handleOpen: open,
    handleDelete: handleConfirm,
    loading: loading,
    label: description,
    title: title,
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen} variant="contained" data-toggle="tooltip">
        {label}
      </IconButton>
      <DialogDeleteCustom {...dialogDeleteProps} />
    </div>
  );
}