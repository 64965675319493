import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { getCandidatContact } from "../../../Services/ContactService";
import { useParams } from "react-router-dom";


const CandidatsAssocie = () => {
  const [candidatContact, setCandidatContact] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { idContact } = useParams();

  const handlePageChange = (page) => {
    setPage(page + 1);
  };
  React.useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      setCandidatContact([]);
      let newCandidatContact = await getCandidatContact(idContact, page, 50);
      if (!active) {
        return;
      }
      setCandidatContact(newCandidatContact.data.CandidatContact);
      setCount(newCandidatContact.data.count);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, idContact]);

  const getColumnProps = (widthPercent) => ({
    setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", width: widthPercent, maxWidth: "100px" } }),
    setCellHeaderProps: () => ({ style: { textAlign: "center", width: widthPercent, maxWidth: "100px" } }),
  });

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        ...getColumnProps("20%"),
      },
    },
    {
      name: "dateSignature",
      label: "INTEGRATION",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment(value.date).format("DD/MM/yyyy")}</>;
        },
        ...getColumnProps("15%"),
      },
    },
    {
      name: "nomCandidat",
      label: "NOM",
      options: getColumnProps("25%"),
    },
    {
      name: "nom_mission",
      label: "MISSION",
      options: getColumnProps("45%"),
    },
    {
      name: "linkedin",
      label: "LINKEDIN",
      options: getColumnProps("20%"),
    },
  ];

  const options = {
    tableBodyMaxHeight: "44vh",
    serverSide: true,
    count: count,
    rowsPerPage: 50,
    textLabels: {
      body: {
        noMatch: loading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handlePageChange(tableState.page);
          break;
        default:
      }
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filterType: "dropdown",
    filter: false,
    print: false,
    columns: false,
    responsive: "standard",
    search: false,
    download: false,
    viewColumns: false,
  };

  return (
    <Grid container >
      <Grid item xs={12}>
        <MUIDataTable data={candidatContact} columns={columns} options={options} />
      </Grid>
    </Grid>
  );
};

export default CandidatsAssocie;