import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const CustomAutoComplete = ({ listData, ...props }) => {
    return (
        <>
            <label className="input-label">
                {listData.label} {listData.required ? <span className="required-input">*</span> : ""}
            </label>
            <Autocomplete
                {...(props.value ? { value: props.value } : { value: null })}
                onChange={(event, newValue) => {
                    props.handleDataChange(newValue);
                    if (props.onChange) {
                        props.onChange({
                            target: { name: props.name, value: newValue},
                        });
                    }
                }}
                getOptionLabel={option => `${option?.nom_societe !== undefined ? option?.nom_societe + " - " : ""} ${option.value !== undefined ? option.value : option.selectionName}`}
                getOptionSelected={(option, value) => option.id === value.id}
                options={listData.data}
                noOptionsText={"Aucune donnée trouvée."}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        variant="outlined"
                        {...(props.errors && props.errors[props?.name] && {
                            error: true,
                            label: props.errors[props.name],
                        })}
                    />
                }
                {...(props.disableClearable ? { disableClearable: props.disableClearable } : { disableClearable: false })}
                loading={listData.isLoading}
                loadingText="Chargement..."
            />
        </>
    )
}

export default CustomAutoComplete
