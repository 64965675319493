import {
  Box,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import { Delete } from "@material-ui/icons";
import {
  getAll,
  deleteNoteCandidat,
  editNoteCandidat,
  downloadNoteFile,
  addNoteCandidat,
} from "../../../Services/NoteCandidat";
import moment from "moment";
import { useParams } from "react-router-dom";
import "../visuialisation.css";
import MUIDataTable from "mui-datatables";
import EditorCustom from "../../../../components/EditorCustorm/EditorCustom";
import { removeLineBreak } from "../../../../utils/EditorUtil";
import LabelReadMoreReadLess from "../../../../components/LabelReadMoreReadLess/LabelReadMoreReadLess";
import DialogDeleteCustom from "../../../../components/DialogDeleteCustom/DialogDeleteCustom";
import FormNoteModal from "../../../../components/FormNoteModal/FormNoteModal";
import { getUserData } from "../../../../utils/FunctionHelpers";
import { AttachFile, Download, Edit } from "@mui/icons-material";



export default function Notes({ loadData, snackbarShowMessage, ...rest }) {
  const [notesCandidat, setNotesCandidat] = useState([]);
  const [noteCandidatInfo, setNoteCandidatInfo] = useState({
    auteur: "",
    id: "",
  });
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [loadingToDelete, setLoadingToDelete] = useState(false);
  const [reloadingData, setReloadingData] = useState(false);
  const parametters = useParams();
  const idCandidat = rest.idCandidat ?? parametters.idCandidat
  const [textEdit, setTextEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [idNoteSeleted, setIdNoteSeleted] = useState(0);
  const [modalState, setModalState] = useState({ open: false, ecole: "" });
  const [pj, setPj] = useState(null);
  const [dataUpload, setDataUpload] = useState({});
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState({});
  const [downloadingRowIndex, setDownloadingRowIndex] = useState(null);
  const uploadRef = useRef(null);

  React.useEffect(() => {
    let active = true;
    (async () => {
      setLoading(true);
      setNotesCandidat([]);
      await getAll(1, idCandidat).then((data) => {
        setNotesCandidat(data.data.notesCandidat);
      });
      if (!active) {
        return;
      }
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [reloadingData, idCandidat, loadData]);

  const validate = (comment) => {
    let temp = {};
    temp.commentaire = !(comment?.replace(/<\/?[^>]+(>|$)/g, "") === "") ? "" : "Le champ 'COMMENTAIRE' est obligatoire.";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleOpen = () => {
    setModalState({ open: true });
  };

  const handleClose = () => {
    if (loading) {
      return;
    }
    setModalState({ open: false });
    setComment("")
    setErrors("")
  };

  const handleSubmit = (comment) => {
    if (validate(comment)) {
      (async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("dateTime", new Date());
        formData.append("commentaire", removeLineBreak(comment));
        formData.append("auteur", getUserData().uuid);
        formData.append("candidat", idCandidat);
        formData.append("pj", pj);

        try {
          snackbarShowMessage("Patientez svp!", "warning", null)
          const response = await addNoteCandidat(formData);
          snackbarShowMessage(response.data)
          setReloadingData(!reloadingData);
        } catch (e) {
          snackbarShowMessage("Une erreur est survenue: " + e.message, "error", 4000)
        } finally {
          setLoading(false);
          handleClose();
        }
      })();
    }
  };


  const handleDownload = (noteId, pj) => {
    const FileDownload = require("js-file-download");
    (async () => {
      setDownloadingRowIndex(noteId)
      setLoading(true);
      try {
        let response = await downloadNoteFile(noteId, pj);
        FileDownload(response.data, pj);
        setLoading(false);
        setDownloadingRowIndex(null)
      } catch (error) {
        snackbarShowMessage("Une erreur est survenue: " + error.message, "error", 4000)
        setLoading(false);
        setDownloadingRowIndex(null)
      }
    })();
  };

  const handleOpenDialog = (auteur, id) => {
    setNoteCandidatInfo({ auteur: auteur, id: id });
    setOpenDialogDelete(true);
  };

  const handleCloseDialog = () => {
    if (loadingToDelete) {
      return;
    }
    setOpenDialogDelete(false);
  };

  const handleDeleteNote = () => {
    (async () => {
      setLoadingToDelete(true);
      try {
        snackbarShowMessage("Patientez svp!", "warning", null)
        const res = await deleteNoteCandidat(noteCandidatInfo.id);
        snackbarShowMessage(res.data)
        setLoadingToDelete(false);
        handleCloseDialog();
        setReloadingData(!reloadingData);
      } catch (error) {
        snackbarShowMessage("Erreur de suppression de la données.", "error", 4000)
        setLoadingToDelete(false);
      }
    })();
  };

  const handleUpdate = (data) => {
    (async () => {
      const formData = new FormData();
      let id = idNoteSeleted;
      if (typeof data === 'string') {
        formData.append("commentaire", removeLineBreak(data));
        formData.append("pj", pj);
      } else {
        const { comment, file, idNote } = data;
        formData.append("commentaire", removeLineBreak(comment));
        formData.append("pj", file);
        id = idNote;
      }
      try {
        snackbarShowMessage("Patientez svp!", "warning", null)
        const response = await editNoteCandidat(formData, id);
        snackbarShowMessage(response.data, "success", 4000)
        setReloadingData(!reloadingData);
        setTextEdit(true);
      } catch (e) {
        snackbarShowMessage("Une erreur est survenue: " + e.message, "error", 4000)
      }
    })();
  };

  const editorCustomProps = {
    value: comment,
    setValue: setComment,
    handleSubmit: handleUpdate,
    enableOnBlur: true
  }

  const handleFileChange = (event) => {
    handleUpdate({ comment: dataUpload.msgOrComment, file: event.target.files[0], idNote: dataUpload.id });
  };

  const getNotes = () => {
    return notesCandidat.map(item => {
      const isMail = item.commentaire?.replace(/<\/?[^>]+(>|$)/g, "").trim().startsWith("MAIL");
      const msgOrComment = item.commentaire.trim().startsWith('MAIL')
        ? `${item.commentaire.split('\n')[0]} ${item.commentaire.split('\n')[1]}; ${item.commentaire.split('\n').slice(2).join('\n')}`
        : item.commentaire;
      return {
        id: item.id,
        auteur: item.auteur,
        commentaire: {
          isMail,
          msgOrComment,
          id: item.id,
        },
        pj: {
          pj: item.pj,
          id: item.id,
          isMail,
          msgOrComment: item.commentaire,
        },
        date: moment(item.dateTime.date).format("DD/MM/yy HH:mm:ss"),
        action: {
          auteur: item.auteur,
          id: item.id,
        },
      };
    });
  };

  const handleRowClick = (comment, id) => {
    setComment(comment);
    setIdNoteSeleted(id);
    setTextEdit(false);
  }


  const options = {
    tableBodyMaxHeight: "44vh",
    textLabels: {
      body: {
        noMatch: loading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
      },
    },
    selectableRows: "none",
    responsive: 'standard',
    fixedHeader: true,
    // fixedHeaderOptions: {
    //   xAxis: false,
    //   yAxis: true,
    // },
    serverSide: true,
    filterType: "dropdown",
    filter: false,
    print: false,
    columns: false,
    search: false,
    download: false,
    viewColumns: false,
  };

  const columns = [
    {
      name: "date",
      label: "DATE/HEURE",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { width: '100px', padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
      },
    },
    {
      name: "commentaire",
      label: "COMMENTAIRES",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { textAlign: "left", width: '60%', padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value) => {
          return (
            <Box sx={{ cursor: !value.isMail ? "pointer" : "hand" }}>
              {textEdit ? (
                <span onDoubleClick={() => { !value.isMail && handleRowClick(value.msgOrComment, value.id) }}>
                  <LabelReadMoreReadLess data={value.msgOrComment} />
                </span>
              ) : value.id === idNoteSeleted ? (
                <EditorCustom {...editorCustomProps} />
              ) : (
                <LabelReadMoreReadLess data={value.msgOrComment} />
              )
              }
            </Box >
          );
        },
      },
    },
    {
      name: "auteur",
      label: "AUTEUR",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { maxWidth: '100px', padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
      },
    },
    {
      name: "pj",
      label: "P.J",
      options: {
        filter: false, sort: false,
        setCellHeaderProps: () => ({ style: { maxWidth: '100px', textAlign: "center", padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value) => {
          const isDownloading = downloadingRowIndex === value.id;
          return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {value.pj ? (
                <>
                  <IconButton disabled={loading} key={`pj ${value.id}`} onClick={() => handleDownload(value.id, value.pj)} data-toggle="tooltip"
                    title="Télécharger">
                    {isDownloading && !value.isMail ? <CircularProgress size={25} /> : <Download size={25} color="primary" style={{ fontSize: "20px" }} />}
                  </IconButton>
                  <IconButton disabled={value.isMail || loading} onClick={() => { uploadRef.current.click(); setDataUpload(value) }} data-toggle="tooltip"
                    title="Modifier PJ">
                    <Edit style={{ fontSize: "20px" }} color="disabled" /> {downloadingRowIndex === value.id}
                  </IconButton>
                </>

              ) : (
                <>
                  <input
                    type="file"
                    onChange={(e) => { handleFileChange(e) }}
                    style={{ display: 'none' }}
                    ref={uploadRef}
                  />
                  <IconButton disabled={value.isMail || loading} key={`pj ${value.id}`} onClick={() => { uploadRef.current.click(); setDataUpload(value) }} data-toggle="tooltip"
                    title="Ajouter PJ">
                    <AttachFile style={{ fontSize: "20px" }} color={value.isMail ? "disabled" : "primary"} />
                  </IconButton>
                </>

              )}
            </Box>
          );
        },
      }
    },
    {
      name: "action",
      label: "ACTION",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { maxWidth: '50px', padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value) => {
          return (
            <IconButton data-toggle="tooltip" title="Supprimer" aria-label="Supprimer" onClick={() => handleOpenDialog(value.auteur, value.id)} style={{ color: "#EF6262" }}>
              <Delete />
            </IconButton>
          );
        },
      },
    },
  ];

  const dialogDeleteProps = {
    handleClose: handleCloseDialog,
    handleOpen: openDialogDelete,
    handleDelete: handleDeleteNote,
    loading: loadingToDelete,
    label: ` Voulez vous vraiment supprimer la note de ${noteCandidatInfo.auteur}?`,
    title: "Supprimer la note"
  }

  const formNoteProps = { errors, setPj, loading, handleSubmit, modalState: modalState.open, handleOpen, handleClose }


  return (
    <>
      <FormNoteModal {...formNoteProps} />
      <MUIDataTable data={getNotes()} columns={columns} options={options} />
      <DialogDeleteCustom {...dialogDeleteProps} />
    </>
  );
}
