import { createReducer } from '@reduxjs/toolkit';
import { AddFilterKPIConsultants, ClearListFilterKPIConsultants, LoadAllFilterKPIConsultants, AddPlaqueDateFilterKPIConsultants, AddSelectedConsultantsFilterKPIConsultants } from '../actions/filterKPIConsultantsActions';

const initialState = {
    filters: {
        selectedConsultants: [],
        startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
        endDate: new Date(new Date().setDate(new Date().getDate() - 1)),
        key: "selection",
    },
};

const filtersKPIConsultantsReducer = createReducer(initialState, {
    [AddFilterKPIConsultants]: (state, action) => {
        state.filters = action.payload;
    },
    [AddSelectedConsultantsFilterKPIConsultants]: (state, action) => {
        state.filters.selectedConsultants = action.payload;
    },
    [AddPlaqueDateFilterKPIConsultants]: (state, action) => {
        state.filters.plagueDate = action.payload;
    },
    [LoadAllFilterKPIConsultants]: (state, action) => {
        return state.filters
    },
    [ClearListFilterKPIConsultants]: (state, action) => {
        return state = initialState;
    },
});

export default filtersKPIConsultantsReducer;
