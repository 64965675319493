import api from "../../utils/API";

const getAll = (page, uuid) => {
  return api.get(`/societemanagement/contact/${uuid}/tachescontact/list?page=${page}&limit=${100}`);
};
const getAllTachesFaites = (page, uuid) => {
  return api.get(`/societemanagement/contacts/${uuid}/tachescontactfaites/list?page=${page}&limit=${100}`);
};
const deleteTacheContact = (tacheContact) => {
  return api.delete(`/societemanagement/tachescontact/${tacheContact}/delete`);
};

const addTacheContact = (contactId, data) => {
  return api.post(`/socitemanagement/contacts/${contactId}/tachescontact/add`, data);
};

const editTacheContact = (data, tacheContactId) => {
  return api.put(`/societemanagement/tachescontact/${tacheContactId}/update`, data);
};

const getAllMesTaches = (page, searchParams) => {
  return api.get(`/societemanagement/tachescontact/list?page=${page}&limit=${100}&${searchParams}`);
};

export {getAll, editTacheContact, deleteTacheContact, addTacheContact, getAllTachesFaites, getAllMesTaches};

