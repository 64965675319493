import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";
import { Backdrop, Box, Button, CircularProgress, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { addremovecandidattoselection, getAllSelectionsWithoutCandidat } from "../../../../../Services/SelectionCandidatService";
import moment from "moment";
import { updateObjectStateValue } from "../../../../../../utils/FunctionHelpers";


function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "20px",
    width: "1000px",
  },
  saveButton: {
    backgroundColor: "rgb(66, 186, 150)",
    color: "#FFF",
    fontWeight: 500,
    "&:hover, &:focus": {
      backgroundColor: "#389e7f",
      borderColor: "#359478",
    },
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginLeft: "0.5em",
    fontWeight: "500",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  modalBody: {
    height: "500px",
    padding: "0rem 1rem 1rem 1rem",
    overflowY: "scroll"
  }
}));

export default function CandidatSelectionsList({ loadSelectionsData, snackbarShowMessage, selectionsCandidat }) {
  const classes = useStyles();
  const [modalState, setModalState] = useState({ open: false });
  const [modalStyle] = useState(getModalStyle);
  const { idCandidat } = useParams();
  const [selectionsData, setSelectionsData] = useState({
    isLoading: false,
    data: []
  })
  const [selectedCandidatsData, setSelectedCandidatsData] = useState({
    isLoading: false,
    data: [],
    indexes: []
  })

  const handleOpen = () => {
    setModalState({ open: true });
  };

  const handleClose = () => {
    if (selectedCandidatsData.isLoading) {
      return;
    }
    setModalState({ open: false });
  };

  const loadSelections = () => {
    updateObjectStateValue(
      setSelectionsData,
      { isLoading: true, data: [] }
    )
    getAllSelectionsWithoutCandidat(idCandidat).then(({ data }) => {
      updateObjectStateValue(
        setSelectionsData,
        { isLoading: false, data: data.selections }
      );
      const foundIndexes = [];
      selectionsCandidat.forEach((selection) => {
        let foundIndex = data.selections.findIndex((row) => row.id === selection.id)
        if (foundIndex) {
          foundIndexes.push(foundIndex)
        }
      });
      
      updateObjectStateValue(
        setSelectedCandidatsData,
        { isLoading: false, data: data.selections, indexes: foundIndexes }
      );
    }).catch(() => {
      snackbarShowMessage("Erreur de chargement des sélections.", "error", 4000);
    }).finally(() => {
      updateObjectStateValue(
        setSelectionsData,
        { isLoading: false }
      )
    })
  }

  React.useEffect(() => {
    if (modalState.open) {
      loadSelections()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState.open]);

  const columns = [
    {
      name: "id",
      label: "N°SELECTION",

      options: {
        display: false,
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{value}</Box>
            </>
          );
        },
      },
    },
    {
      name: "dateAjout",
      label: "DATE D'AJOUT",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { textAlign: "center" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={moment(value.date).format("DD/MM/yyyy")}>{moment(value.date).format("DD/MM/yyyy")}</Box>
            </>

          );
        },
      },
    },
    {
      name: "nom",
      label: "NOM SÉLÉCTION",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { textAlign: "center" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{value}</Box>
            </>
          );
        },
      },
    },
    {
      name: "auteur",
      label: "AUTEUR",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { textAlign: "center" } })
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <>
            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{value}</Box>
          </>
        );
      },
    },
  ];

  const options = {
    rowsPerPage: 50,
    textLabels: {
      body: {
        noMatch: selectionsData.isLoading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
      },
    },
    draggableColumns: {
      enabled: true,
    },
    filter: true,
    filterType: "dropdown",
    responsive: "scrollFullHeight",
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      let temp = [];
      rowsSelected.forEach((element) => {
        temp.push(selectionsData.data[element].id);
      });
      updateObjectStateValue(setSelectedCandidatsData, { data: temp, indexes: rowsSelected })
    },
    rowsSelected: selectedCandidatsData.indexes
  };

  const handleSubmit = () => {
    snackbarShowMessage("Ajout du candidats dans les sélections...", "warning")
    updateObjectStateValue(
      setSelectedCandidatsData,
      { isLoading: true }
    )
    const payload = {
      selectedSelections: selectedCandidatsData.data
    }
    addremovecandidattoselection(payload, idCandidat).then(() => {
      snackbarShowMessage("Candidat a été ajouté dans les sélections.")
      updateObjectStateValue(setSelectedCandidatsData, { data: [], indexes: [], isLoading: false })
      loadSelectionsData()
      loadSelections()
    }).catch(({ response }) => {
      snackbarShowMessage(response.data.error.detail, "error", 4000)
    }).finally(() => {
      updateObjectStateValue(
        setSelectedCandidatsData,
        { isLoading: false }
      );
      handleClose();
    })
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="modal-header">
        <h4 className={classes.modalTitle}>LISTE DES SÉLECTIONS</h4>
      </div>
      <div className={classes.modalBody}>
        <MUIDataTable title={"Liste des selections"} data={selectionsData.data} columns={columns} options={options} />
      </div>
      <div className="modal-footer">
        <Button className={classes.saveButton} onClick={handleSubmit} disabled={selectedCandidatsData.isLoading}>
          Sauvegarder
          {selectedCandidatsData.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
        <Button className={classes.closeButton} onClick={handleClose} disabled={selectedCandidatsData.isLoading}>
          Annuler
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Button
        style={{ backgroundColor: "rgb(17 122 167)", color: "#fff", marginBottom: "1em", height: "2.5em" }}
        type="submit"
        variant={"contained"}
        onClick={handleOpen}
        size="small"
      >
        AJOUTER
      </Button>
      <Modal
        className={classes.modal}
        open={modalState.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalState.open}>{body}</Fade>
      </Modal>
    </>
  );
}
