import api from "../../utils/API";

const addAppointment = (proces, payload) => {
  return api.post(`/process/processes/${proces}/appointments/add`, payload);
};

const getLastAppointment = (proces) => {
  return api.get(`/process/processes/${proces}/appointments/last`);
};

const updateAppointment = (payload) => {
  return api.put(`/process/appointments/last/update`, payload);
};

const getAppointments = (proces) => {
  return api.get(`/process/processes/${proces}/appointments/list`);
};

const deleteAppointment = (appointment) => {
  return api.delete(`/process/appointments/${appointment}/delete`);
}

export {addAppointment, getLastAppointment, updateAppointment, getAppointments, deleteAppointment};
