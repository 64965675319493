import api from "../../utils/API";

const addContact = (data) => {
  return api.post(`/societemanagement/contact/add`, data);
};

const getAll = (page) => {
  return api.get(`/societemanagement/contact/list?page=${page}&limit=${100}`);
};

const getDetails = (uuid) => {
  return api.get(`/societemanagement/contact/${uuid}/details`);
};

const edit = (params, uuid) => {
  return api.put(`/societemanagement/contact/${uuid}/edit`, params);
};

const deleteContact = (uuid) => {
  return api.delete(`/societemanagement/contact/${uuid}/delete`);
};

const getContsoci = (uuid, page) => {
  return api.get(`/societe/${uuid}/contacts/list?page=${page}&limit=${50}`);
};

const searchContacts = (url, page) => {
  return api.get(`/societemanagement/contact/search?page=${page}&limit=${100}&${url}`);
};

const getDescriptionSociete = (id) => {
  return api.get(`/societemanagement/contacts/${id}/descriptionsociete/get`);
};

const getMissionContact = (contact, page, limit, status) => {
  return api.get(`/societemanagement/contacts/${contact}/missions/get?page=${page}&limit=${limit}&status=${status}`);
};

const getCandidatContact = (contact, page, limit) => {
  return api.get(`/societemanagement/contacts/${contact}/candidatcontact/get?page=${page}&limit=${limit}`);
};
const getPhoning = () => {
  return api.get(`/Phoning/list`);
}


export { getAll, getDetails, edit, deleteContact, getContsoci, addContact, searchContacts, getDescriptionSociete, getMissionContact, getCandidatContact, getPhoning };
