import { CircularProgress, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import CandidatSelectionsList from "./Modals/CandidatSelection/CandidatSelectionsList";
import MUIDataTable from "mui-datatables";
import { deleteCandidatFromSelection, getAllCandidatSelections } from "../../../Services/SelectionCandidatService";
import moment from "moment";
import { useParams } from "react-router-dom";
import "../visuialisation.css"
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import { updateObjectStateValue } from "../../../../utils/FunctionHelpers";
import { Delete } from "@material-ui/icons";
import DialogDeleteCustom from "../../../../components/DialogDeleteCustom/DialogDeleteCustom";
import { Box } from "@material-ui/core";

const Selections = ({ snackbarShowMessage }) => {
  const { idCandidat } = useParams();
  const [selection, setSelection] = useState({})
  const [selectionsData, setSelectionsData] = useState({
    isLoading: false,
    data: []
  })
  const [reloadingData, setReloadingData] = useState(false);
  const [loadingToDelete, setLoadingToDelete] = useState(false)
  const mounted = React.useRef(true)
  const [openDialogDelete, setOpenDialogDelete] = useState(false)

  const loadSelections = () => {
    updateObjectStateValue(
      setSelectionsData,
      { isLoading: true, data: [] }
    )
    getAllCandidatSelections(idCandidat).then(({ data }) => {
      updateObjectStateValue(
        setSelectionsData,
        { isLoading: false, data: data.selectionsCandidat }
      )
    }).catch(() => {
      snackbarShowMessage("Erreur de chargement des sélections.", "error", 4000)
    }).finally(() => {
      updateObjectStateValue(
        setSelectionsData,
        { isLoading: false }
      )
    })
  }

  const handleOpenDialog = (selectionTab) => {
    setSelection(selectionTab)
    setOpenDialogDelete(true);
  };


  React.useEffect(() => {
    loadSelections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadingData]);

  const columns = [
    {
      name: "id",
      label: "N°SELECTION",

      options: {
        display: false,
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({ style: { padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
      },
    },
    {
      name: "dateAjout",
      label: "DATE D'AJOUT",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center", maxWidth: "100px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", maxWidth: "100px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment(value.date).format("DD/MM/yyyy ")}</>;
        },
      },
    },
    {
      name: "nom",
      label: "NOM SÉLÉCTION",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { textAlign: "center", padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
      },
    },
    { name: "auteur", label: "AUTEUR", options: { filter: true, sort: true, setCellProps: () => ({ style: { maxWidth: "150px" } }), setCellHeaderProps: () => ({ style: { textAlign: "center", padding: "2px 12px", maxWidth: "50px" } }) } },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellHeaderProps: () => ({ style: { textAlign: "center", padding: "2px 12px", maxWidth: "12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", maxWidth: "80px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton data-toggle="tooltip"
                title="Supprimer" onClick={() => handleOpenDialog(tableMeta.rowData)} style={{ color: "#EF6262" }}>
                <Delete />
              </IconButton>
            </Box>

          );
        },
      },
    },
  ];


  const handleCloseDialog = () => {
    if (loadingToDelete) {
      return
    }
    setOpenDialogDelete(false)
  }

  const deleteCandidatFromList = () => {
    (async () => {
      setLoadingToDelete(true)
      try {
        const res = await deleteCandidatFromSelection(idCandidat, selection[0])
        snackbarShowMessage(res.data)
        if (!mounted.current) {
          return
        }
        setLoadingToDelete(false)
        setOpenDialogDelete(false)
        setReloadingData(!reloadingData)
      } catch (err) {
        snackbarShowMessage("Erreur de suppression de la données,veuillez réssayer plutard.", "error", 4000)
        if (!mounted.current) {
          return
        }
      }
    })()
  }



  const options = {
    textLabels: {
      body: {
        noMatch: selectionsData.isLoading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
      },
    },
    tableBodyMaxHeight: "44vh",
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filterType: "dropdown",
    print: false,
    columns: false,
    responsive: "standard",
    download: false,
    viewColumns: false,
    filter: false,
    search: false,
  };

  const dialogDeleteProps = {
    handleClose: handleCloseDialog,
    handleOpen: openDialogDelete,
    handleDelete: deleteCandidatFromList,
    loading: loadingToDelete,
    label: `Voulez vous vraiment supprimer le candidat de la selection ${selection[2]}?`,
    title: "Supprimer le candidat de la sélection"
  }

  return (
    <>
      <Box>
        <CandidatSelectionsList {...{ loadSelectionsData: loadSelections, snackbarShowMessage, selectionsCandidat: selectionsData.data }} />
        <MUIDataTable data={selectionsData.data} columns={columns} options={options} />
      </Box>
      <DialogDeleteCustom {...dialogDeleteProps} />
    </>
  );
}
export default withSnackbar(Selections);
