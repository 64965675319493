import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";
import { Backdrop, Box, Button, Fade, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditorCustom from "../EditorCustorm/EditorCustom";
import LoadingButtonCustom from "../LoadingButtonCustom/LoadingButtonCustom";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    borderRadius: "20px",
    width: "500px",
  },
  saveButton: {
    backgroundColor: "rgb(66, 186, 150)",
    color: "#FFF",
    fontWeight: 500,
    "&:hover, &:focus": {
      backgroundColor: "#389e7f",
      borderColor: "#359478",
    },
  },
  plus: {
    fontWeight: 500,
    marginBottom: "0.2em",
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginLeft: "0.5em",
    fontWeight: "500",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
  },
}));

export default function FormNoteModal({
  errors,
  setPj,
  loading,
  handleSubmit,
  modalState,
  handleOpen,
  handleClose,
}) {
  const classes = useStyles();

  const [comments, setComments] = useState("");

  useEffect(() => {
    setComments("");
  }, [modalState]);

  const editorCustomProps = {
    value: comments,
    setValue: setComments,
    handleSubmit,
    enableOnBlur: false,
  };

  const loadingButtonProps = {
    handleSubmit: () => handleSubmit(comments),
    loading,
  };

  const body = (
    <Box className={classes.paper}>
      <h4 className={classes.modalTitle}>Ajouter une note</h4>
      <Box sx={{ paddingBottom: "16px" }}>
        <Box className="input-label">
          Commentaire <span className="required-input">*</span>
        </Box>
        <EditorCustom {...editorCustomProps} />
        {errors.commentaire && (
          <Typography variant="body2" color="error">
            {errors.commentaire}
          </Typography>
        )}
        <Box className="input-label">PIECE JOINTE</Box>
        <Box>
          <input type="file" onChange={(e) => setPj(e.target.files[0])} />
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", gap: "10px", width: "100%" }}
        className="modal-footer"
      >
        <Button
          className={classes.closeButton}
          onClick={handleClose}
          disabled={loading}
        >
          Annuler
        </Button>
        <LoadingButtonCustom {...loadingButtonProps} />
      </Box>
    </Box>
  );

  return (
    <>
      <Button
        onClick={handleOpen}
        style={{
          backgroundColor: "rgb(17 122 167)",
          color: "#fff",
          marginBottom: "1em",
        }}
        variant="contained"
        size="small"
      >
        AJOUTER
      </Button>
      <Modal
        className={classes.modal}
        open={modalState}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalState}>{body}</Fade>
      </Modal>
    </>
  );
}
