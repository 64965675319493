import api from "../../utils/API";

const getAll = (page, uuid) => {
  return api.get(`/candidatsmanagement/candidat/${uuid}/tachescandidat/list?page=${page}&limit=${100}`);
};
const getAllTachesFaites = (page, uuid) => {
  return api.get(`/candidatsmanagement/candidat/${uuid}/tachescandidatfaites/list?page=${page}&limit=${100}`);
};
const deleteTacheCandidat = (tacheCandidat) => {
  return api.delete(`/candidatsmanagement/tachescandidat/${tacheCandidat}/delete`);
};

const addTacheCandidat = (candidatId, data) => {
  return api.post(`/candidatsmanagement/candidat/${candidatId}/tachescandidat/add`, data);
};

const editTacheCandidat = (data, tacheCandidatId) => {
  return api.put(`/candidatsmanagement/tachescandidat/${tacheCandidatId}/update`, data);
};

const getAllMesTaches = (searchParams) => {
  return api.get(`/candidatsmanagement/tachecandidat/list?${searchParams}`);
};

export {getAll, editTacheCandidat, deleteTacheCandidat, addTacheCandidat, getAllTachesFaites, getAllMesTaches};
