import { Box, Button, Card, Grid, IconButton, makeStyles, useMediaQuery } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { withSnackbar } from "../../../utils/SnackbarHOC";
import { theme } from "../../../utils/Theme";
import { getAllCandidatsForReporting } from "../../Services/CandidatService";
import { getMissionsActive } from "../../Services/MissionService";
import { getSocietesWithActiveMission } from "../../Services/SocieteService";
import ReportingInternList from "./components/reporting-intern-list";
import TableReportingIntern from "./components/table-reporting-intern";
import "./reporting.css";

const ReportingIntern = ({ snackbarShowMessage, history }) => {

    const useStyles = makeStyles({
        archiveButton: {
            color: "white",
            fontWeight: 500,
            backgroundColor: "rgb(17, 122, 167)",
            marginBottom: "10px",
            "&:hover": {
                backgroundColor: "rgb(17, 122, 167)"
            }
        },
        fileDownloadIcon: {
            color: "green"
        }
    })

    const classes = useStyles();
    const [reloadData, setReloadData] = useState(false);
    const location = useLocation();
    const [reportingData, setReportingData] = useState({
        isLoading: false,
        data: []
    });
    const showAllValue = { id: 1, value: "TOUTES" };
    const [clientData, setClientData] = useState({
        isLoading: false,
        data: [showAllValue],
        value: showAllValue,
        label: "RECHERCHER PAR CLIENT"
    });
    const [missionData, setMissionData] = useState({
        isLoading: false,
        data: [showAllValue],
        value: showAllValue,
        label: "RECHERCHER PAR MISSION DU CLIENT SÉLECTIONNÉ"
    });
    const [query, setQuery] = useState({
        inactive: false
    });

    const loadMissionData = () => {
        setMissionData((prevState) => ({
            ...prevState,
            isLoading: true,
            data: [],
            value: showAllValue
        }))
    }

    const loadReportingData = () => {
        setReportingData((prevState) => ({
            ...prevState,
            isLoading: true,
            data: []
        }))
    }


    const handleMissionActiveFromClientSelected = (newValue) => {
        const searchParams = new URLSearchParams();
        if (newValue.value !== 'TOUTES') {
            searchParams.append('societe', newValue.id);
        }
        const searchQuery = newValue.value !== 'TOUTES' ? `?${searchParams.toString()}` : '';
        loadMissionData();
        getMissionsActive(searchQuery)
            .then((response) => {
                setMissionData((prevState) => ({
                    ...prevState,
                    data: [showAllValue, ...response.data],
                    isLoading: false
                }));
            })
            .catch(() => {
                snackbarShowMessage("Erreur de chargement des missions.", "error", 4000);
                setMissionData((prevState) => ({
                    ...prevState,
                    data: [showAllValue],
                    isLoading: false
                }));
            });
    }

    const resetParamsUrl = () => {
        const params = new URLSearchParams();
        params.append('inactive', query.inactive);
        params.append('status', 'ACTIVE');
        return params
    }

    const getInitialParameters = () => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.toString()) return searchParams.toString();
        return resetParamsUrl().toString();
    };

    const handleMissionChange = (newValue) => {
        const params = new URLSearchParams();
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get("societe")) {
            params.append('societe', searchParams.get("societe"));
            params.append('client', searchParams.get("client"));
        }
        params.append('inactive', query.inactive);
        params.append('status', 'ACTIVE');
        if (newValue.value == 'TOUTES') {
            history.push(`/reporting/intern?${params.toString()}`);
            return
        }
        params.append('mission', newValue.id);
        params.append('missiondata', JSON.stringify(newValue));
        history.push(`/reporting/intern?${params.toString()}`);
    }

    const handleClientChange = (newValue) => {
        if (newValue.value == 'TOUTES') {
            history.push(`/reporting/intern`);
            handleMissionActiveFromClientSelected({ id: 1, value: 'TOUTES' });
            return
        }
        const params = new URLSearchParams();
        params.append('societe', newValue.id);
        params.append('client', JSON.stringify(newValue));
        params.append('inactive', query.inactive);
        params.append('status', 'ACTIVE');
        history.push(`/reporting/intern?${params.toString()}`);
        handleMissionActiveFromClientSelected(newValue)
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const searchParams = new URLSearchParams(location.search);
                const clientSelected = JSON.parse(searchParams.get("client"));
                clientSelected != null && searchParams.append('societe', clientSelected.id);
                const searchQuery = clientSelected != null ? `?${searchParams.toString()}` : ''
                const [clientResponse, missionResponse] = await Promise.all([
                    getSocietesWithActiveMission(),
                    getMissionsActive(searchQuery)
                ]);

                setClientData((prevState) => ({
                    ...prevState,
                    data: [showAllValue, ...clientResponse.data],
                    isLoading: false
                }));

                setMissionData((prevState) => ({
                    ...prevState,
                    data: [showAllValue, ...missionResponse.data],
                    isLoading: false
                }));
            } catch (error) {
                snackbarShowMessage("Erreur de chargement des données.", "error", 4000);
                setClientData((prevState) => ({
                    ...prevState,
                    data: [showAllValue],
                    isLoading: false
                }));
                setMissionData((prevState) => ({
                    ...prevState,
                    data: [showAllValue],
                    isLoading: false
                }));
            }
        };

        fetchData();
    }, []);


    const fetchReportingData = async () => {
        const searchParams = new URLSearchParams(location.search);
        const clientSelected = JSON.parse(searchParams.get("client"));
        const missionSelected = JSON.parse(searchParams.get("missiondata"));
        setClientData((prevState) => ({
            ...prevState,
            value: clientSelected ?? showAllValue
        }))
        setMissionData((prevState) => (
            {
                ...prevState,
                value: missionSelected ?? showAllValue
            }))
        try {
            const search = `?${getInitialParameters()}`;
            loadReportingData();
            const response = await getAllCandidatsForReporting(search);
            setReportingData({
                isLoading: false,
                data: response.data.candidats
            });
        } catch (error) {
            snackbarShowMessage("Erreur de chargement des missions.", "error", 4000);
            setReportingData({
                isLoading: false,
                data: []
            });
        }
    };

    useEffect(() => {
        fetchReportingData();
    }, [query, reloadData, location.search]);


    const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));

    const handleAddCandidateInactiveOrActive = (isActive) => {
        if (location.search) {
            const urlParams = new URLSearchParams(location.search);
            urlParams.set('inactive', isActive);
            history.push(`/reporting/intern?${urlParams.toString()}`);
        }
    }

    return (
        <Box sx={{ padding: "0 20px" }}>
            <Grid container spacing={1} style={{ paddingBottom: "5px" }}>
                <Grid item xs={10} sm={8} md={8} xl={6}>
                    <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
                        <h2 className="text-capitalize" style={{ color: "rgb(17, 122, 167)" }}>
                            REPORTING INTERNE DES PROCESS EN COURS
                        </h2>
                    </Box>
                </Grid>
                <Grid item xs={2} sm={4} md={4} xl={6}>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                        {!isSmMode ? <Button
                            startIcon={!query.inactive ? <Add /> : <Remove />}
                            className={classes.archiveButton}
                            onClick={(e) => {
                                setQuery({ inactive: !query.inactive });
                                handleAddCandidateInactiveOrActive(!query.inactive)
                            }}>
                            LES CANDIDATS INACTIFS
                        </Button>
                            : <IconButton data-toggle="tooltip"
                                title={!query.inactive ? "Ajouter" : "Retirer"} onClick={(e) => {
                                    setQuery({ inactive: !query.inactive });
                                    handleAddCandidateInactiveOrActive(!query.inactive);
                                }}>
                                {!query.inactive ? <Add /> : <Remove />}
                            </IconButton>
                        }
                    </Box>
                </Grid>
            </Grid>
            <Box className="reporting_content">
                <Card style={{ padding: "1em", marginBottom: "1em" }}>
                    <Grid container spacing={2}>
                        {[{ listData: clientData, handleChange: handleClientChange, label: 'Client' }, { listData: missionData, handleChange: handleMissionChange, label: 'Mission' }].map((item, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                <ReportingInternList listData={item.listData} handleDataChange={item.handleChange} label={item.label} />
                            </Grid>
                        ))}
                    </Grid>
                </Card>
                <Box className="reporting_container" >
                    <TableReportingIntern {...{ reloadData, setReloadData, reportingData }} />
                </Box>
            </Box>

        </Box>
    )
}

export default withRouter(withSnackbar(ReportingIntern));