import { createReducer } from '@reduxjs/toolkit';
import { AddFilterKPIMissions, ClearListFilterKPIMissions, LoadAllFilterKPIMissions, AddPlaqueDateFilterKPIMissions, AddSelectedMissionsFilterKPIMissions } from '../actions/filterKPIMissionsActions';

const initialState = {
    filters: {
        selectedMissions: [],
        startDate: null,
        endDate: null,
    },
};

const filtersKPIMissionsReducer = createReducer(initialState, {
    [AddFilterKPIMissions]: (state, action) => {
        state.filters = action.payload
    },
    [AddSelectedMissionsFilterKPIMissions]: (state, action) => {
        state.filters.selectedMissions = action.payload;
    },
    [AddPlaqueDateFilterKPIMissions]: (state, action) => {
        state.filters.plagueDate = action.payload;
    },
    [LoadAllFilterKPIMissions]: (state, action) => {
        return state.filters
    },
    [ClearListFilterKPIMissions]: (state, action) => {
        return state = initialState;
    },
});

export default filtersKPIMissionsReducer;
