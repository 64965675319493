import React from "react";
import {PreviewContainer, FileMetaData} from "../../CandidatForm/Controls/InputFile/InputFile.styles";
import {makeStyles} from "@material-ui/core";
import {FaRegTrashAlt} from "react-icons/fa";

const useStyles = makeStyles(() => ({
  ellipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  download: {
    cursor: "pointer",
  },
  buttonProgress: {
    color: "white",
  },
  filePreviewContainer: {
    cursor: "default",
    justifyContent: "space-between",
  },
}));

export default function FilePreviewOld({oldFiles, updateOldFilesCb}) {
  const classes = useStyles();

  return (
    <>
      {oldFiles.map((oldFile) => {
        let fileName = oldFile.imageName;
        let key = oldFile.id;

        return (
          <PreviewContainer key={key}>
            <FileMetaData className={classes.filePreviewContainer}>
              <span className={classes.ellipsis}>{fileName}</span>
              <span>
                <FaRegTrashAlt onClick={() => updateOldFilesCb(key)} className={classes.download} />
              </span>
            </FileMetaData>
          </PreviewContainer>
        );
      })}
    </>
  );
}
