import React from "react";
import "./style.css";
import UserBox, { UserBoxMdMode } from "./Components/UserBox";
import { Box, IconButton } from "@material-ui/core";
import MenuIcon from '@mui/icons-material/Menu';
import { Paper, SwipeableDrawer } from "@material-ui/core";
import NavBar from "../../layout/appNav/VerticalNavWrapper";

const Layout = ({ children, ...rest }) => (
  <Box
    xs={12}
    {...rest}
    style={{
      background:
        "linear-gradient(194deg,rgb(17, 122, 167), #117aa7,transparent,transparent)",
    }}
  >
    {children}
  </Box>
)

export const HeaderDesktop = () => {
  return (
    <Layout>
      <UserBox />
    </Layout>
  );
}

export const HeaderMobile = () => {
  const [openDrawer, setOpenDrawer] = React.useState(false);

  return (
    <Layout sx={{ boxShadow: "0px 2px 2px -2px gray" }}>
      <Box sx={{ display: "flex", width: "100%" }}>
        <SwipeableDrawer
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          onOpen={() => setOpenDrawer(true)}
        >
          <Box />
          <Paper>
            <NavBar
              sideBarCollapsed={false}
              handleClose={() => setOpenDrawer(false)}
            />
          </Paper>
        </SwipeableDrawer>
        <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
          <IconButton
            onClick={() => setOpenDrawer(!openDrawer)}
            disableRipple
            data-toggle="tooltip"
            title="Menu"
          >
            <MenuIcon sx={{ height: '30px', width: '30px' }} />
          </IconButton>
          <img
            src={process.env.REACT_APP_LOGO_URL}
            alt="People fine Logo"
            className="peoplef-inelogo" width={100} height={40}
          />
        </Box>
        <UserBoxMdMode />
      </Box>
    </Layout>
  );
}


