import api from "../../utils/API";

const getAll = (page, uuid) => {
  return api.get(`/societemanagement/contact/${uuid}/notecontact/list?page=${page}&limit=${100}`);
};
const deleteNoteContact = (uuid) => {
  return api.delete(`/societemanagement/notecontact/${uuid}/delete`);
};

const addNoteContact = (data) => {
  return api.post(`/societesmanagement/notecontact/add`, data);
};

const editNoteContact = (data, uuid) => {
  return api.post(`/societemanagement/notecontact/${uuid}/edit`, data);
};

const downloadNoteFile = (noteId, fileName) => {
  return api.get(`/societemanagement/notecontact/${noteId}/file/${fileName}/download`,{
    responseType: "blob",
  });
};

export {getAll, editNoteContact, deleteNoteContact, addNoteContact, downloadNoteFile};
