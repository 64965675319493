import ReactQuill, { Quill } from "react-quill";
import Emoji from "quill-emoji";
import { useState } from "react";
import { withSnackbar } from "../../utils/SnackbarHOC";
import "quill-paste-smart";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import "./style.css";
import "quill-emoji/dist/quill-emoji.css";

Quill.register("modules/emoji", Emoji);

function EditorCustom({
  value,
  handleSubmit = () => {},
  setValue,
  snackbarShowMessage,
  enableOnBlur,
  ...rest
}) {
  const [readOnly, setReadOnly] = useState(false);
  const [isPasting, setIsPasting] = useState(false);
  const [text, setText] = useState(value);

  const handleBlur = () => {
    if (!isPasting) {
      setReadOnly(true);
      handleSubmit(text);
    }
  };

  const handleKey = (e, state) => {
    if (e.key === "Control" || e.key === "Meta") {
      setIsPasting(state);
    }
  };
  const modules = {
    toolbar: [["bold", "italic", "underline", "link", "emoji"]],
    "emoji-toolbar": true,
    "emoji-shortname": true,
  };

  return (
    <ReactQuill
      modules={modules}
      onBlur={enableOnBlur ? handleBlur : () => {}}
      readOnly={readOnly}
      theme="snow"
      value={enableOnBlur ? text : value}
      onKeyDown={(e) => handleKey(e, true)}
      onKeyUp={(e) => handleKey(e, false)}
      onChange={(e) => (enableOnBlur ? setText(e) : setValue(e))}
      {...rest}
      placeholder="Saisie le text ici..."
    />
  );
}
export default withSnackbar(EditorCustom);
