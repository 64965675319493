import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getDetails, edit } from "../../Services/ContactService";
import { getConsultantsAndManagers } from "../../Services/UserService";
import { getAllSource } from "../../Services/SourceService";
import { getAllStep } from "../../Services/StepService";
import 'react-phone-number-input/style.css'
import { KeyboardDatePicker } from "@material-ui/pickers";
import "./icons.css"
import { withSnackbar } from "../../../utils/SnackbarHOC";
import { v4 } from "uuid";



const VisualiserDate = ({ editMode, snackbarShowMessage }) => {
  const [newTitre, setNewTitre] = useState("");
  const [newNom, setNewNom] = useState("");
  const [newPrenom, setNewPrenom] = useState("");
  const [newFonction, setNewFonction] = useState("");
  const [newStatut, setNewStatut] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newMobile, setNewMobile] = useState("");
  const [newTelFix, setNewTelFix] = useState("");
  const [newLinkedIn, setNewLinkedIn] = useState("");
  const [newSachezQue, setNewSachezQue] = useState("");
  const [newdatePremierContact, setNewdatePremierContact] = useState("");
  const [newdatederniercontact, setNewdatederniercontact] = useState("");
  const [newsource, setnewsource] = useState("");
  const [sources, setSources] = useState([]);
  const [newstep, setnewstep] = useState("");
  const [steps, setSteps] = useState([]);
  const [directionSociete, setDirectionSociete] = useState("");
  const [newAccountManager, setNewAccountManager] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const title = editMode ? "MODIFIER UN CONTACT" : "AJOUTER UN CONTACT";
  const [loadingManagers, setLoadingManagers] = useState(true);
  const [managers, setManagers] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const { id, idContact } = useParams();
  let mounted = useRef(false);
  const [datePhoning, setDatePhonning] = useState("");
  const [dmrLKD, setDmrLKD] = useState("");
  const [dmr, setDmr] = useState("");
  const [phone, setPhone] = useState("")



  React.useEffect(() => {
    let active = true;
    setLoadData(true);

    const fetchData = async () => {
      try {
        if (!active) return;

        const res = await getDetails(idContact);

        if (active) {
          const {
            titre, prenom, nom, fonction, statut, accountManager, email,
            mobile, telFix, linkedIn, sachezQue, premiercontact, derniercontact,
            source, step, direction, phoning, dmr, dmrLKD, datePhoning
          } = res.data;

          setNewTitre(titre);
          setNewPrenom(prenom);
          setNewNom(nom);
          setNewFonction(fonction);
          setNewStatut(statut);
          setNewAccountManager(accountManager);
          setNewEmail(email);
          setNewMobile(mobile);
          setNewTelFix(telFix);
          setNewLinkedIn(linkedIn);
          setNewSachezQue(sachezQue);
          setNewdatePremierContact(premiercontact);
          setNewdatederniercontact(derniercontact);
          setnewsource(source);
          setnewstep(step);
          setDirectionSociete(direction);
          setPhone(phoning);
          setDmr(dmr);
          setDmrLKD(dmrLKD);
          setDatePhonning(datePhoning);
        }
      } catch (error) {
        snackbarShowMessage(
          "Erreur de chargement des données du contact.",
          "error",
          4000
        );
      } finally {
        if (active) {
          setLoadData(false);
        }
      }
    };

    fetchData();

    return () => {
      active = false;
    };
  }, [idContact, editMode]);


  React.useEffect(() => {
    let active = true;
    mounted.current = true;

    const fetchData = async () => {
      try {
        setLoadingManagers(true);
        const [newUsers, sourceReponse, stepReponse] = await Promise.all([
          getConsultantsAndManagers(),
          getAllSource(),
          getAllStep(),
        ]);
        if (!active) {
          return;
        }
        setManagers(newUsers.data.accountManagers);
        setSources(sourceReponse.data);
        setSteps(stepReponse.data);
      } catch (error) {
        if (!active) {
          return;
        }
      } finally {
        setLoadingManagers(false);
      }
    };
    fetchData();
    return () => {
      active = false;
      mounted.current = false;
    };
  }, []);


  const handleUpdate = async () => {
    setLoading(true);
    try {
      const contact = {
        titre: newTitre,
        nom: newNom,
        prenom: newPrenom,
        fonction: newFonction,
        statut: newStatut,
        accountManager: newAccountManager,
        email: newEmail,
        mobile: newMobile,
        telFixDirect: newTelFix,
        linkedIn: newLinkedIn,
        sachezQue: newSachezQue,
        societe: id,
        direction: directionSociete,
        premiercontact: newdatePremierContact,
        derniercontact: newdatederniercontact,
        source: newsource,
        step: newstep,
        phoning: phone,
        dmrLKD: dmrLKD,
        dmr: dmr,
        datePhoning: datePhoning
      };

      snackbarShowMessage("Patientez svp!", "warning", null);
      await edit(contact, idContact);
      snackbarShowMessage("Modifié avec succès.", "success");
      if (mounted.current) {
        setLoading(false);
      }
    } catch (error) {
      if (mounted.current) {
        snackbarShowMessage(
          error + "",
          "error",
          4000
        );
        setLoading(false);
      }
    }
  };



  const handleTimeChange = (value) => {
    setNewdatePremierContact(value);
    handleUpdate();
  };
  const handleTimeChangedernier = (value) => {
    setNewdatederniercontact(value);
    handleUpdate();
  };

  const DateInput = ({ label, value, onChange, ...rest }) => {
    return (
      <FormControl variant="outlined" fullWidth margin="normal">
        <label className="input-label">
          <Typography variant="h5" style={{ fontSize: "1.0rem", textTransform: "capitalize", fontWeight: 600 }}>
            {label}
          </Typography>
        </label>
        <KeyboardDatePicker
          style={{ marginTop: "0px" }}
          invalidDateMessage=""
          autoOk={true}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          inputVariant="outlined"
          margin="normal"
          value={value}
          disabled={loading}
          onChange={onChange}
          {...rest}
        />
      </FormControl>
    );
  };

  return (
    <>
      {loadData & editMode ? (
        <Box display="flex" height={500} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={1}>
          {[
            {
              label: "PREMIER CONTACT",
              value: newdatePremierContact,
              onChange: (value) => handleTimeChange(value),
            },
            {
              label: "DERNIER CONTACT",
              value: newdatederniercontact,
              onChange: (value) => handleTimeChangedernier(value),
            },
            {
              label: "SOURCE",
              value: newsource,
              onChange: (e) => setnewsource(e.target.value),
              error: errors.source,
              items: sources.map((source) => ({
                key: source.uuid,
                value: source.uuid,
                text: source.type,
              })),
            },
            {
              label: "STEP",
              value: newstep,
              onChange: (e) => setnewstep(e.target.value),
              error: errors.step,
              items: steps.map((step) => ({
                key: step.uuid,
                value: step.uuid,
                text: step.type,
              })),
            },
          ].map((field, index) => (
            <Grid item xs={12} sm={3} md={3} lg={3} key={v4()}>
              {field.label !== "SOURCE" && field.label !== "STEP" ? (
                <DateInput
                  minDate={field.label === "PREMIER CONTACT" ? newdatePremierContact : undefined}
                  label={field.label}
                  value={field.value}
                  onChange={field.onChange}
                />
              ) : (
                <FormControl variant="outlined" fullWidth margin="normal">
                  <label className="input-label" htmlFor={field.label.toLowerCase()}>
                    <Typography variant="h5" style={{ fontSize: "1.0rem", textTransform: "capitalize", fontWeight: 600 }}>
                      {field.label}
                    </Typography>
                  </label>
                  <InputLabel
                    error={field.error}
                    shrink
                    htmlFor={field.label.toLowerCase()}
                    disabled={loading}
                  >
                    {field.error}
                  </InputLabel>
                  {loadingManagers ? (
                    <Box display="flex" justifyContent="center">
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Select
                      value={field.value}
                      name={field.label.toLowerCase()}
                      onChange={field.onChange}
                      onBlur={handleUpdate}
                      disabled={loading}
                      input={
                        <OutlinedInput
                          name={field.label.toLowerCase()}
                          notched
                          labelWidth={field.error ? 340 : undefined}
                        />
                      }
                    >
                      <MenuItem value="">
                        <em>-</em>
                      </MenuItem>
                      {field.items.map((item) => (
                        <MenuItem key={item.key} value={item.value}>
                          <em>{item.text}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              )}
            </Grid>
          ))}
        </Grid>

      )
      }
    </>
  );
};
export default withSnackbar(VisualiserDate);
