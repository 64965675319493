import React from "react";
import { Card, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { v4 } from "uuid";

function informationMission({ missionData }) {
    return (
        <Grid item xs={12}>
            <Card>
                <TableContainer>
                    <Table className="table table-striped">
                        <TableBody component="tbody">
                            {[
                                {
                                    title: "Type de Mission",
                                    content: missionData.type,
                                },
                                {
                                    title: "Statut",
                                    content: missionData.statut,
                                },
                                {
                                    title: "Priorite",
                                    content: missionData.priorite,
                                },
                                {
                                    title: "Destinataires Principal",
                                    content: missionData.contact,
                                },
                                {
                                    title: "Destinataires Secondaire",
                                    content: (missionData.secondaires || []).map((secondaire) => `${secondaire}, `),
                                },
                            ].map((item, index) => (
                                <TableRow key={v4()}>
                                    <TableCell scope="row">
                                        <strong>{item.title}</strong>
                                    </TableCell>
                                    <TableCell style={{ width: "80%" }} align="left">{item.content ?? "-"}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </Grid>

    );
}



export default informationMission;
