import api from "../../utils/API";

const downloadFile = (id) => {
  return api.get(`/candidatsmanagement/downloadfile/${id}`, {
    responseType: "blob",
  });
};

const getCvBlob = (cv) => {
  return api.get(`/candidatsmanagement/candidat/${cv}`, {
    responseType: "blob",
  });
};

export { downloadFile, getCvBlob };
