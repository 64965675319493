import { Button, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import CustomAutoComplete from '../../../../common/CustomAutoComplete'
import { getUserData, updateObjectStateValue } from '../../../../utils/FunctionHelpers'
import { withSnackbar } from '../../../../utils/SnackbarHOC'
import SelectionModal from '../../../Dashboard/Modals/SelectionModal'
import { getAllCadidatSelections } from '../../../Services/SelectionCandidatService'

const useStyles = makeStyles(() => ({
    plus: {
        fontWeight: 500,
        marginBottom: "0.2em",
    },
}))

const Step1 = ({ importData, errors, setImportData, snackbarShowMessage }) => {
    const classes = useStyles();
    const [selectionData, setSelectionData] = useState({
        isLoading: false,
        data: [],
        label: "SELECTION",
        required: true
    })
    const [reloadSelections, setReloadSelections] = useState(false)

    const handleChange = (event) => {
        setImportData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const handleSelectionChange = (selection) => {
        handleChange({
            target: {
                name: 'selection',
                value: selection
            }
        })
    }

    const loadSelections = () => {
        updateObjectStateValue(
            setSelectionData,
            { isLoading: true, data: [] }
        )
        getAllCadidatSelections('?archived=false').then(({ data }) => {
            updateObjectStateValue(
                setSelectionData,
                { isLoading: false, data: data.candidatsSelections }
            )
        }).catch(() => {
            snackbarShowMessage("Erreur de chargement des sélections.", "error", 4000)
        }).finally(() => {
            updateObjectStateValue(
                setSelectionData,
                { isLoading: false }
            )
        })
    }

    useEffect(() => {
        loadSelections()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadSelections])

    return (
        <>
            {
                getUserData().role.includes('ROLE_ADMIN')
                && (
                    <SelectionModal {...{ selectedCandidats: [], snackbarShowMessage, isImport: true, setReloadSelections, reloadSelections, setImportData }}>
                        {(handleOpen) => (
                            <Button className="float-right" color='primary' onClick={() => handleOpen()}>
                                <AiOutlinePlus className={classes.plus} />
                                Ajouter
                            </Button>
                        )}
                    </SelectionModal>
                )
            }
            <CustomAutoComplete listData={selectionData} errors={errors} value={importData.selection} handleDataChange={handleSelectionChange} name="selection" />
        </>
    )
}

export default withSnackbar(Step1)