import { Box, CircularProgress, useMediaQuery } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { sources } from "../../../../utils/SourceUtil";
import { withSnackbar } from "../../../../utils/SnackbarHOC";


const Sourcing = ({ loading, sourcesIds }) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
    const isMediumMode = useMediaQuery((theme) => theme.breakpoints.between(767, 1023));
    const isLargeMode = useMediaQuery((theme) => theme.breakpoints.between(1023, 2800));
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const tableBodyMaxHeight = `${isSmall ? viewportHeight - 292 : isMediumMode ? viewportHeight - 340 : isLargeMode ? viewportHeight - 310 : 200}px`;

    const columns = [
        {
            name: "name",
            label: "SOURCES",
        },
        {
            name: "count",
            label: "DOSSIERS GÉNÉRÉS",
        },
    ];

    const countById = sourcesIds?.reduce((acc, id) => {
        acc[id] = (acc[id] || 0) + 1;
        return acc;
    }, {});

    const newTable = sources.map(({ name, id }) => ({
        name,
        count: countById[id] || 0
    }));


    useEffect(() => {
        const handleResize = () => {
            setViewportHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const options = {
        tableBodyMaxHeight: tableBodyMaxHeight,
        rowsPerPage: 50,
        textLabels: {
            body: {
                noMatch: loading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
            },
        },
        selectableRows: "none",
        draggableColumns: {
            enabled: true,
        },
        filterType: "dropdown",
        print: false,
        columns: false,
        responsive: "standard",
        download: false,
        viewColumns: false,
        filter: false,
        search: false,
    };


    return (
        <Box>
            <MUIDataTable
                data={newTable}
                columns={columns}
                options={options}
            />
        </Box>
    )
}

export default withSnackbar(Sourcing);