import React, { useState } from "react";
import { getMissionContact } from "../../../Services/ContactService";
import { useParams } from "react-router-dom";
import TabPanelMission from "../../../../components/TabPanelMission/TabPanelMission";
import { TABLE } from "../../../../enums/tablesEnum";


const MissionEnCours = ({ ...rest }) => {
    const [missionsTermines, setMissionsTermines] = useState(false);
    const [missionsContact, setMissionsContact] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const parametters = useParams();
    const idCandidat = rest?.idCandidat ?? parametters.idCandidat;
    const idContact = (rest?.uuid || rest?.societeId) ?? parametters.idContact;
    const id = rest.tacheFrom === TABLE.CONTACT ? rest.id : idCandidat;

    const handlePageChange = (page) => {
        setPage(page + 1);
    };

    React.useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            setLoading(true);
            setMissionsContact([]);
            try {
                const newMissionsContact = await getMissionContact(
                    idContact,
                    page,
                    50,
                    missionsTermines ? 'passive' : 'active'
                );

                if (isMounted) {
                    setMissionsContact(newMissionsContact.data.missionsContact);
                    setCount(newMissionsContact.data.count);
                    setLoading(false);
                }
            } catch (error) {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [page, idContact, missionsTermines]);

    const tabPanelMissionProps = {
        count,
        missionsTermines,
        missionsContact,
        handlePageChange,
        setMissionsTermines,
        loading,
        id,
    }

    return (
        <TabPanelMission {...tabPanelMissionProps} />
    );
};
export default MissionEnCours;
