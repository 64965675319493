import React, {useState} from "react";
import "./candidat.css";
import CandidatTable from "./CandidatTable/index";
import {BsPlus} from "react-icons/bs";
import {Link} from "react-router-dom";
const Candidats = () => {
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(1);

  const props = {setOffset, setLimit, count, setCount};

  return (
    <div className="main_content_iner ">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="QA_section">
              <h2>
              Sociétés   <span className="text-capitalize">Candidats</span>
                <small className="datatable_info_stack">
                  <div className="datatable_info" aria-live="polite">
                    Affichage de {offset} à {limit} de {count} candidats
                  </div>
                </small>
              </h2>
              <div className="container-fluid animated fadeIn">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row mb-0">
                      <div className="col-sm-6">
                        <div className="d-print-none with-border">
                          <Link
                            className="btn btn-primary"
                            to="/candidats/add"
                            style={{backgroundColor: "rgb(17 122 167)", borderColor: "rgb(17 122 167)"}}
                          >
                            <span className="ladda-label">
                              <BsPlus />
                              Ajouter candidat
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <CandidatTable {...props} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Candidats;
