import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { Chip } from "@material-ui/core";
import useDebounce from "./useDebounce";
import { getContactsUsersEmails } from "../pages/Services/EmailTypeService";
import { updateObjectStateValue } from "./FunctionHelpers";
import { withSnackbar } from "./SnackbarHOC";

const TagsInput = ({ ...props }) => {
    const { selectedTags, placeholder, validation, snackbarShowMessage } = props;
    const [selectedItem, setSelectedItem] = React.useState([]);
    const [error, setError] = React.useState(false);
    const [searchData, setSearchData] = useState({
        emails: [],
        search: "",
        loading: false
    })
    const debounsedSearch = useDebounce(searchData.search, 100)

    const searchEmails = (searchQuery) => {
        updateObjectStateValue(setSearchData, { emails: [], loading: true })
        getContactsUsersEmails(searchQuery).then(({data}) => {
            updateObjectStateValue(setSearchData, { emails: data })
        }).catch(() => {
            snackbarShowMessage("Erreur des chargements des emails", "error", 4000)
        }).finally(() => {
            updateObjectStateValue(setSearchData, { loading: false })
        })
    }

    useEffect(() => {
        if (debounsedSearch) {
            searchEmails(searchData.search)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounsedSearch])

    useEffect(() => {
        selectedTags(selectedItem);
    }, [selectedItem, selectedTags]);

    const handleSearchChange = (event) => {
        updateObjectStateValue(setSearchData, { search: event.target.value })
    }

    const handleKeyDown = (event) => {
        if ((event.key === "Enter" || event.type === "click") && (event.target.value || event.target.innerText)) {
            const value = event.type === "keydown" ? event.target.value : event.target.innerText
            const newSelectedItem = [...selectedItem];
            const duplicatedValues = newSelectedItem.indexOf(
                value.trim()
            );
            if (duplicatedValues !== -1) {
                setError(true);
                return;
            }
            if (!value.replace(/\s/g, "").length) return;
            if (validation && !value.trim().match(validation)) {
                setError(true);
                return;
            }
            newSelectedItem.push(value.trim());
            setSelectedItem(newSelectedItem);
            setError(false);
        }
        if (
            selectedItem.length &&
            event.key === "Backspace"
          ) {
            setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
          }
    }

    const handleDelete = item => () => {
        const newSelectedItem = [...selectedItem];
        newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
        setSelectedItem(newSelectedItem);
      };

    return (
        <Autocomplete
            disableClearable
            freeSolo
            multiple
            options={searchData.emails}
            value={selectedItem}
            loading={searchData.loading}
            loadingText="Chargement des emails..."
            onChange={handleKeyDown}
            onFocus={() => updateObjectStateValue(setSearchData, { emails: [] })}
            renderTags={(tags) => (
                tags.map(tag => (
                    <Chip
                        key={tag}
                        tabIndex={-1}
                        label={tag}
                        onDelete={handleDelete(tag)}
                    />
                ))
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder={placeholder}
                    error={error}
                    onChange={e => {
                        setError(false)
                        handleSearchChange(e)
                    }}
                />
            )}
        />
    );
}
export default withSnackbar(TagsInput)