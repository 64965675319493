import { Button } from "@material-ui/core";
import { Box, Grid } from "@material-ui/core";
import { CircularProgress, FormControlLabel, Switch } from "@material-ui/core";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { undisplaySwipeableDrawerSummary } from "../../redux/SwipeableDrawer/actions/swipeableDrawerActions";
import { Link } from "react-router-dom";

function TabPanelMission({
  count,
  missionsTermines,
  missionsContact,
  handlePageChange,
  setMissionsTermines,
  loading,
  id,
  history,
}) {
  const dispatch = useDispatch();
  const columns = [
    {
      name: "uuid",
      label: "N°MISSION",

      options: {
        display: false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "100px",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
            maxWidth: "100px",
            whiteSpace: "nowrap",
          },
        }),
      },
    },
    {
      name: "numMission",
      label: "N°",
    },
    {
      name: "nom_mission",
      label: "NOM MISSION",
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
            whiteSpace: "nowrap",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Link
                to={{
                  pathname: `/societe/${tableMeta.rowData[4]}/missions/${tableMeta.rowData[0]}/view`,
                }}
                className="d-print-none font-sm"
                style={{ textDecoration: "none" }}
              >
                {value}
              </Link>
            </>
          );
        },
      },
    },
    {
      name: "statut",
      label: "STATUT",
    },
    {
      name: "consultant",
      label: "CONSULTANT",
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
            whiteSpace: "nowrap",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ?? "-"}</>;
        },
      },
    },
    {
      name: "accountManager",
      label: "ACCOUNT MANAGER",
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        }),
      },
    },
    {
      name: "createdAt",
      label: "CRÉE LE",
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? moment(value.date).format("DD/MM/yyyy") : "-"}</>;
        },
      },
    },
    {
      name: "CV",
      label: "CV EN COURS",
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "100px",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
            maxWidth: "100px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100px",
                }}
              >
                <Link
                  to={{
                    pathname: `/candidats/missions/${tableMeta.rowData[0]}/list`,
                    state: {
                      missionName: tableMeta.rowData[3],
                      societeName: tableMeta.rowData[2],
                      missionNum: tableMeta.rowData[1],
                      dateMission: tableMeta.rowData[5],
                    },
                  }}
                >
                  {value}
                </Link>
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "societeId",
      label: "",

      options: {
        display: false,
      },
    },
  ];

  const options = {
    rowsPerPageOptions: [],
    serverSide: true,
    count: count,
    tableBodyMaxHeight: "44vh",
    rowsPerPage: 50,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment."
        ),
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handlePageChange(tableState.page);
          break;
        default:
      }
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filterType: "dropdown",
    filter: false,
    print: false,
    columns: false,
    responsive: "standard",
    search: false,
    download: false,
    viewColumns: false,
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Button
          onClick={() => {
            dispatch(undisplaySwipeableDrawerSummary());
            history.push(`/societe/${id}/missions/add`);
          }}
          style={{
            backgroundColor: "rgb(17 122 167)",
            color: "#fff",
            marginBottom: "1em",
          }}
          size="small"
          variant={"contained"}
        >
          Ajouter
        </Button>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <div style={{ marginTop: "-3rem" }}>
            <label className="input-label" htmlFor="tachesTermines">
              LES MISSIONS TERMINÉES
            </label>
            <FormControlLabel
              control={
                <Switch
                  checked={missionsTermines}
                  onChange={(e) => setMissionsTermines(e.target.checked)}
                  name="missionsTermines"
                  color="primary"
                />
              }
              style={{ marginLeft: "0.2em", marginRight: 0 }}
            />
          </div>
        </div>
        <MUIDataTable
          data={missionsContact}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
}

export default withRouter(TabPanelMission);
