import api from "../../utils/API";

const getAllSource = () => {
  return api.get(`/AllSource/list`);
};


const getAllImportationSource = (queryParams) => {
  return api.get(`/AllSource/importation/list${queryParams}`);
}
export { getAllSource, getAllImportationSource };