import React, { useState, useRef } from "react";
import { FaFileUpload, FaRegTrashAlt } from "react-icons/fa";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  PreviewContainer,
  PreviewList,
  FileMetaData,
} from "./InputFile.styles";
import FilePreviewOld from "../../Components/FilePreviewOld";
import { withSnackbar } from "../../../../../utils/SnackbarHOC";
import "./InputFile.css"
import { v4 } from "uuid";

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 50000000;
const KILO_BYTES_PER_BYTE = 10000;

const convertNestedObjectToArray = (nestedObj) => Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
                      updateFilesCb,
                      maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
                      acceptOnlyPdf = false,
                      ...otherProps
                    }) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState(otherProps.files ? { ...otherProps.files } : {});

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    let errorFiles = [];
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (acceptOnlyPdf && file.type !== "application/pdf") {
          errorFiles.push(file.name);
        } else {
          if (!otherProps.multiple) {
            return { file };
          }
          files[file.name] = file;
        }
      } else {
        errorFiles.push(file.name);
      }
    }
    checkFileError(errorFiles);
    return { ...files };
  };

  const checkFileError = (errorFiles) => {
    if (errorFiles.length > 0) {
      let filesErrorMsg = acceptOnlyPdf
          ? "Seuls les fichiers PDF sont autorisés: "
          : "La taille des fichiers est trop grande (50MB max): ";
      for (let fileName of errorFiles) {
        filesErrorMsg += fileName + " ";
      }
      otherProps.snackbarShowMessage(filesErrorMsg, "error", 4000);
    }
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  return (
      <>
        <FileUploadContainer>
          <DragDropText>Glisser puis déposer vos fichiers {acceptOnlyPdf ? "PDF" : ""} ou</DragDropText>
          <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
            <FaFileUpload />
            <span className="title">{otherProps.multiple ? "Fichiers" : "un fichier"}</span>
          </UploadFileBtn>
          <FormField
              type="file"
              ref={fileInputField}
              onChange={handleNewFileUpload}
              accept={acceptOnlyPdf ? ".pdf" : "*"}
              title=""
              value=""
              {...otherProps}
          />
        </FileUploadContainer>
        <FilePreviewContainer>
          <span>À TÉLÉCHARGER</span>
          <PreviewList>
            {otherProps.editMode && <FilePreviewOld oldFiles={otherProps.oldFiles} updateOldFilesCb={otherProps.updateOldFilesCb} />}
            {Object.keys(files).map((fileName, index) => {
              let file = files[fileName];
              return (
                  <PreviewContainer key={v4()}>
                    <div>
                      <FileMetaData>
                        <span>{file.name}</span>
                        <aside>
                          <span>{convertBytesToKB(file.size)} kb</span>
                          <FaRegTrashAlt style={{ cursor: "pointer" }} onClick={() => removeFile(fileName)} />
                        </aside>
                      </FileMetaData>
                    </div>
                  </PreviewContainer>
              );
            })}
          </PreviewList>
        </FilePreviewContainer>
      </>
  );
};

export default withSnackbar(FileUpload);
