import { configureStore } from '@reduxjs/toolkit';
import toggleReducer from '../toggle/reducers/toggleReducer';
import candidatsReducer from '../candidats/reducers/candidatsReducer';
import swipeableReducer from '../SwipeableDrawer/reducers/swipeableDrawerReducer';
import filtersKPIConsultantsReducer from '../pilotage/reducers/filterKPIConsultantsReducer';
import filtersKPIMissionsReducer from '../pilotage/reducers/filterKPIMissionsReducer';


const store = configureStore({
    reducer: {
        toggle: toggleReducer,
        candidats: candidatsReducer,
        swipeableDrawer: swipeableReducer,
        filtersKPIConsultants: filtersKPIConsultantsReducer,
        filtersKPIMissions: filtersKPIMissionsReducer
    },
});


export default store;
