import { createReducer } from '@reduxjs/toolkit';
import { AddCandidats, LoadAllCandidats, ClearListCandidats, SetSelectedCandidatBeAddToSelection, ClearListCandidatsSelectedForSelection } from '../actions/candidatsActions';

const initialState = {
    candidats: [],
    candidatsSelectedForSelection: []
};

const candidatsReducer = createReducer(initialState, {
    [AddCandidats]: (state, action) => {
        state.candidats = action.payload;
    },
    [LoadAllCandidats]: (state, action) => {
        return state.candidats
    },
    [SetSelectedCandidatBeAddToSelection]: (state, action) => {
        state.candidatsSelectedForSelection = action.payload;
    },
    [ClearListCandidats]: (state, action) => {
        return state = initialState;
    },
    [ClearListCandidatsSelectedForSelection]: (state, action) => {
        return state.candidatsSelectedForSelection = initialState;
    },
});

export default candidatsReducer;
