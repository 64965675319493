import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { listEmailTypes } from "../../Services/EmailTypeService";
import "../../Mission/Modal.css";
import { getAllUsersForTasks } from "../../Services/UserService";
import { getUserData } from "../../../utils/FunctionHelpers";
import { sendEmailCandidat } from "../../Services/ProcessService";
import { Autocomplete } from "@material-ui/lab";
import { FiSend } from "react-icons/fi";
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  Modal,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Select, OutlinedInput, MenuItem } from "@material-ui/core";
import TagsInput from "../../../utils/TagsInput";
import { BiMailSend } from "react-icons/bi";
import { withSnackbar } from "../../../utils/SnackbarHOC";
import EditorCustom from "../../../components/EditorCustorm/EditorCustom";
import { removeLineBreak } from "../../../utils/EditorUtil";
import { Fade, Typography } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "20px",
    width: "800px",
  },
  saveButton: {
    backgroundColor: "rgb(66, 186, 150)",
    color: "#FFF",
    fontWeight: 500,
    "&:hover, &:focus": {
      backgroundColor: "#389e7f",
      borderColor: "#359478",
    },
  },
  buttonLabel: {
    paddingLeft: "0.4em",
    marginLeft: 0,
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginLeft: "0.5em",
    fontWeight: "500",
  },
  actionBar: {
    marginTop: "1.5em",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
    marginBottom: "1em",
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  takeAppointment: {
    color: "rgb(17 122 167)",
  },
  calendar: {
    marginRight: "0.5em",
  },
}));

const EmailCondidat = ({ emailCandidat, ...otherProps }) => {
  const classes = useStyles();
  const { idCandidat } = useParams();
  const [errors, setErrors] = useState({});
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const loading = open && options.length === 0;
  const [de, setDe] = useState(getUserData().email);
  const [cc, setCc] = useState([]);
  const [cci, setCci] = useState([]);
  const [objet, setObjet] = useState("");
  const [Messages, setMessages] = useState("");
  const [user, setUser] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [message, setMessage] = useState("<br></br><p>Bien cordialement</p>");
  const isActive = useRef();
  const [dataUpload, setDataUpload] = useState(null);
  const uploadRef = useRef(null);

  const handleOpenModal = () => {
    emailCandidat
      ? setOpenModal(true)
      : otherProps.snackbarShowMessage(
        "Vous ne pouvez pas utiliser cette fonctionnalité car aucun e-mail n'a été renseigné pour le candidat.",
        "warning",
        5000
      );
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setMessage("<br></br><p>Bien cordialement</p>")
    setErrors("")
    // setDataUpload(null)
  };

  const handleSelectedCC = (ccs) => {
    setCc(ccs);
  };

  const handleSelectedCCI = (ccis) => {
    setCci(ccis);
  };
  const clearFieds = () => {
    setMessage("");
    setObjet(null);
    setCc([]);
    setCci([]);
    setDataUpload(null)

  };

  //Input validation
  const validate = () => {
    let temp = {};
    temp.de = de ? "" : "Le champ 'emetteur' est obligatoire.";
    temp.objet = objet ? "" : "Le champ 'objet' est obligatoire.";
    temp.message = !(message?.replace(/<\/?[^>]+(>|$)/g, "") === "") ? "" : "Le champ 'Message' est obligatoire.";;
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const messageTypeChanged = (event, values) => {
    const replacements = [
      ["PRENOM CDT", otherProps.prenom],
      ["Mr/Mme NOM DU CLT", otherProps.prenom],
      ["Prénom CDT", otherProps.prenom],
      ["Prénom", otherProps.prenom],
      ["Madame", otherProps.prenom],
      ["[Prenom_CDT]", otherProps.prenom],
    ];

    const msgTxt = values?.message || "-";
    const updatedMsgTxt = replacements.reduce((txt, [search, replacement]) => {
      const formattedReplacement = replacement.charAt(0).toUpperCase() + replacement.slice(1);
      return txt.replace(search, formattedReplacement);
    }, msgTxt);

    setMessages(values?.msgName || "-");
    setObjet(values?.objet || "-");
    setMessage(updatedMsgTxt);
  };

  //list d'users
  React.useEffect(() => {
    let active = true;
    (async () => {
      try {
        setLoadingUsers(true);
        const { data } = await getAllUsersForTasks();
        if (!active) return;
        setUser(data);
        setLoadingUsers(false);
      } catch (err) {
        otherProps.snackbarShowMessage(
          "Erreur de chargement des utilisateurs.",
          "error",
          4000
        );
      } finally {
        setLoadingUsers(false);
      }
    })();
    return () => {
      active = false;
    };
  }, [openModal]);

  //list d'email
  React.useEffect(() => {
    let active = true;
    const queryParam = '?type=email&subject=candidat';
    (async () => {
      try {
        const { data } = await listEmailTypes(queryParam);
        if (!active) return;
        setOptions(data);
      } catch (err) {
        otherProps.snackbarShowMessage(
          "Erreur de chargement des email types.",
          "error",
          4000
        );
      }
    })();

    return () => {
      active = false;
    };
  }, [open]);

  const appendArrayToFormData = (formData, key, array) => {
    if (array.length > 0) {
      array.forEach((name, index) => {
        formData.append(`${key}[${index}]`, name);
      });
    } else {
      formData.append(key, []);
    }
  };

  const handleSend = async () => {
    if (!validate()) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append('de', de);
      appendArrayToFormData(formData, 'cc', cc);
      appendArrayToFormData(formData, 'cci', cci);
      formData.append('msg', removeLineBreak(message));
      formData.append('objet', objet);
      formData.append('candidat', idCandidat);
      formData.append(
        'commentaire',
        `<div style={{display:"flex"}}> MAIL : \n${objet}\n${removeLineBreak(message)}</div>`
      );
      formData.append('auteur', getUserData().uuid);
      formData.append('pj', dataUpload);

      if (isActive) {
        otherProps.snackbarShowMessage(
          "Envoie d'email en cours...",
          "warning",
          null
        );
        const { data } = await sendEmailCandidat(formData);
        otherProps.snackbarShowMessage(data, "success", 4000);
        otherProps.setLoadData(!otherProps.loadData);
        clearFieds();
      }
    } catch (error) {
      otherProps.snackbarShowMessage(
        "Erreur de l'envoie de mail.",
        "error",
        4000
      );
    }

    handleCloseModal();
  };

  const handleSender = async (id) => {
    setDe(id);
  };

  const editorCustomProps = {
    value: message,
    setValue: setMessage,
    enableOnBlur: false
  };

  const body = (
    <div className="modalBackground">
      <div className="modalContainer ">
        <div style={{ height: "100%", maxHeight: "693px", overflowY: "auto" }}>
          <div>
            <FormControl
              {...(errors.de && {
                error: true,
                label: errors.de,
              })}
              fullWidth
            >
              <label className="input-label" htmlFor="messages">
                De <span className="required-input">*</span>
              </label>
              <Select
                value={user.length !== 0 && !loadingUsers ? de : ""}
                name="users"
                style={{ width: "100%" }}
                onChange={(e) => {
                  handleSender(e.target.value);
                }}
                input={
                  <OutlinedInput
                    name="de"
                    {...(errors.de && {
                      labelWidth: 280,
                    })}
                    notched
                  ></OutlinedInput>
                }
              >
                {loadingUsers ? (
                  <CircularProgress />
                ) : (
                  user.map((user) => (
                    <MenuItem
                      selected={"EmailUser"}
                      onChange={(e) => setDe(e.target.value)}
                      value={user.email}
                      key={user.uuid}
                    >
                      {`${user.email}`}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </div>

          <div>
            <label className="input-label" htmlFor="cci">
              CC
            </label>
            <TagsInput
              selectedTags={handleSelectedCC}
              fullWidth
              id="cc"
              name="cc"
              validation={/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/}
              placeholder="Ajouter des CC"
            />
          </div>
          <div>
            <label className="input-label" htmlFor="cci">
              CCI
            </label>
            <TagsInput
              selectedTags={handleSelectedCCI}
              fullWidth
              variant="outlined"
              id="cci"
              name="cci"
              placeholder="Ajouter des CCI"
            />
          </div>
          <div>
            <label className="input-label" htmlFor="messages">
              Message Type
            </label>
            <Autocomplete
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              getOptionSelected={(option, value) =>
                option.msgName === value.msgName
              }
              getOptionLabel={(option) => option.msgName}
              onChange={(event, values) => messageTypeChanged(event, values)}
              options={options}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={Messages ? Messages.msgName : ""}
                  placeholder={Messages ? Messages.msgName : ""}
                  {...(errors.Messages && {
                    error: true,
                    label: errors.Messages,
                  })}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div>
            <label className="input-label" htmlFor="objet">
              Objet<span className="required-input"> *</span>
            </label>
            <TextField
              id="objet"
              value={objet}
              margin="normal"
              variant="outlined"
              autoComplete="off"
              onChange={(e) => setObjet(e.target.value)}
              fullWidth
              {...(errors.objet && {
                error: true,
                label: errors.objet,
              })}
            />
          </div>
          <div>
            <label className="input-label" htmlFor="message">
              Message <span className="required-input"> *</span>
            </label>
            {errors.message && (
              <Typography variant="body2" color="error">
                {errors.message}
              </Typography>
            )}
            <EditorCustom {...editorCustomProps} />
          </div>
          {dataUpload && (
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                height: "100%",
              }}
              className="input-label"
            >
              <span>PJ:</span>
              <Chip
                label={dataUpload.name}
                variant="default"
                onDelete={() => setDataUpload(null)}
                size="small"
              />
            </Box>
          )}
        </div>
        <Box sx={{
          display: "flex",
          gap: "10px",
          justifyContent: "end",
          width: "100%",
        }}
          className="modal-footer">
          {!dataUpload && (
            <Box sx={{ display: "flex" }}>
              <input
                type="file"
                onChange={(e) => setDataUpload(e.target.files[0])}
                style={{ display: "none" }}
                ref={uploadRef}
              />
              <IconButton
                // disabled={value.isMail || loading}
                onClick={() => uploadRef.current.click()}
                data-toggle="tooltip"
                title="Ajouter PJ"
              >
                <AttachFile style={{ fontSize: "20px" }} color="primary" />
              </IconButton>
            </Box>
          )}
          <Button
            style={{
              color: "#1b2a4e",
              backgroundColor: "#d9e2ef",
              borderColor: "#d9e2ef",
              marginRight: "0.5em",
              fontWeight: "500",
            }}
            onClick={handleCloseModal}
            disabled={loading}
          >
            Annuler
          </Button>
          <Button
            startIcon={<FiSend />}
            style={{
              backgroundColor: "rgb(66, 186, 150)",
              color: "#FFF",
              fontWeight: 500,
              "&:hover, &:focus": {
                backgroundColor: "#389e7f",
                borderColor: "#359478",
              },
            }}
            disabled={loading}
            onClick={handleSend}
          >
            Envoyer
          </Button>
        </Box>
      </div>
    </div>
  );

  return (
    <>
      <Button
        startIcon={<BiMailSend />}
        className={`${classes.contactIcons} ${otherProps?.className}`}
        data-toggle="tooltip"
        title="EMAIL"
        onClick={handleOpenModal}
      ></Button>
      <Modal
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        open={openModal}
        onClose={handleCloseModal}
      >
        <Fade in={openModal}>{body}</Fade>
      </Modal>
    </>
  );
};
export default withSnackbar(EmailCondidat);
