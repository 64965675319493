import { FormControl, Grid, Select, MenuItem, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import CandidatTasksTable from "./Tables/CandidatTasksTable";
import ContactTasksTable from "./Tables/ContactTasksTable"
import { Card } from "react-bootstrap";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { getAllUsersForTasks } from "../Services/UserService";
import { getUserData } from "../../utils/FunctionHelpers";
import { Box } from "@material-ui/core";
import "./candidatTaskStyle.css"
import { withSnackbar } from "../../utils/SnackbarHOC";
import { v4 } from "uuid";

function CandidatTasks({ snackbarShowMessage }) {
  const [loadUsers, setLoadUser] = useState(false);
  const [affectePar, setAffectePar] = useState();
  const [affecteA, setAffecteA] = useState(getUserData().uuid);
  const [dateFilter, setDateFilter] = useState("aujourduietpasse");
  const [dueDate, setDueDate] = useState();
  const [users, setUsers] = useState([]);
  const [reloadingData, setReloadingData] = useState(false);
  const searchParams = { affecteA, affectePar, dueDate, dateFilter };
  const tachesCandidatProps = { searchParams, reloadingData, setReloadingData };
  const tachesContactProps = { searchParams, reloadingData, setReloadingData };


  const dateFilterData = {
    toutes: "TOUTES",
    aujourduietpasse: "AUJOURD'HUI ET PASSÉES",
    aujourduietavenir: "AUJOURD'HUI ET À VENIR",
    pardate: "PAR DATE",
    faites: "FAITES"
  };

  const handleParDate = (e) => {
    if (e.target.value === "pardate") {
      setDateFilter(e.target.value);
      setDueDate(new Date());
      return;
    }
    setDueDate();
    setDateFilter(e.target.value);
    setReloadingData(!reloadingData);
  };

  React.useEffect(() => {
    let active = true;
    (async () => {
      setLoadUser(true);
      try {
        const users = await getAllUsersForTasks();
        if (!active) {
          return;
        }
        setUsers(users.data);
        setLoadUser(false);
      } catch (error) {
        if (!active) {
          return;
        }
        snackbarShowMessage("Erreur de chargement des utilisateurs.", "error", 4000);
      } finally {
        setLoadUser(false);
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  const taches_list =
    [{ value: 0, name: "candidats" },
    { value: 1, name: "contacts" }]

  const [toggleList, setToggletList] = useState(0)


  return (
    <Box sx={{ padding: "0 20px" }}>

      <h2 className="text-capitalize" style={{ color: "rgb(17, 122, 167)", }}>
        MA LISTE DE TÂCHES
      </h2>
      <Box className="task_content">
        <Box >
          <Card style={{ padding: "1em", marginBottom: "1em" }}>
            <Grid container spacing={1}>
              <Grid item {...(dateFilter === "pardate" ? { xs: 12, sm: 2, md: 2, lg: 2 } : { xs: 12, sm: 3, md: 3, lg: 3 })}>
                <strong>TÂCHES DES</strong>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <Select
                    name="affectePar"
                    onChange={(e) => setToggletList(e.target.value)}
                    defaultValue={0}
                  >
                    {
                      taches_list.map((list) => (
                        <MenuItem value={list.value} key={list.value}>
                          {list.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item {...(dateFilter === "pardate" ? { xs: 12, sm: 3, md: 3, lg: 3 } : { xs: 12, sm: 3, md: 3, lg: 3 })}>
                <strong>AFFECTÉES PAR</strong>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <Select
                    name="affectePar"
                    onChange={(e) => {
                      setAffectePar(e.target.value);
                      setReloadingData(!reloadingData);
                    }}
                    defaultValue=""
                  >
                    {loadUsers ? (
                      <Box display="flex" justifyContent="center">
                        <CircularProgress />
                      </Box>
                    ) : (
                      users.map((user) => (
                        <MenuItem value={user.uuid} key={user.uuid}>
                          {user.prenom} {user.nom}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item {...(dateFilter === "pardate" ? { xs: 12, sm: 3, md: 3, lg: 3 } : { xs: 12, sm: 3, md: 3, lg: 3 })}>
                <strong>AFFECTÉES À</strong>
                <FormControl variant="outlined" fullWidth margin="normal">
                  {loadUsers ? (
                    <Box display="flex" justifyContent="center">
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Select
                      value={users.length > 0 ? affecteA : ""}
                      name="users"
                      onChange={(e) => {
                        setReloadingData(!reloadingData);
                        setAffecteA(e.target.value);
                      }}
                    >
                      {loadUsers ? (
                        <CircularProgress />
                      ) : (
                        users.map((user) => (
                          <MenuItem value={user.uuid} key={user.uuid}>
                            {user.prenom} {user.nom}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  )}
                </FormControl>
              </Grid>
              <Grid item {...(dateFilter === "pardate" ? { xs: 12, sm: 2, md: 2, lg: 2 } : { xs: 12, sm: 3, md: 3, lg: 3 })}>
                <strong>AFFICHER LES TÂCHES</strong>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <Select name="dateFilter" onChange={(e) => { setReloadingData(!reloadingData); handleParDate(e) }} value={dateFilter}>
                    {Object.keys(dateFilterData).map((keyname, index) => (
                      <MenuItem value={keyname} key={v4()}>
                        {dateFilterData[keyname]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {dateFilter === "pardate" && (
                <Grid item {...(dateFilter ? { xs: 12, sm: 2, md: 2, lg: 2 } : { xs: 12, sm: 3, md: 3, lg: 3 })}>
                  <strong>Par date </strong>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    views={["date", "month", "year"]}
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    margin="normal"
                    fullWidth
                    onChange={(date) => {
                      setDueDate(date);
                      setReloadingData(!reloadingData);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Card>
        </Box>

        <Box className="candidatTask_container" >
          <Grid container >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {toggleList === 0 ? <CandidatTasksTable {...tachesCandidatProps} /> : <ContactTasksTable {...tachesContactProps} />}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default withSnackbar(CandidatTasks);
