import api from "../../utils/API";

const getAllCandidatSelections = (candidatId, params = "") => {
  return api.get(`/candidatsmanagement/candidat/${candidatId}/selection/list${params}`);
};

const getAllSelectionsWithoutCandidat = (candidatId, params = "") => {
  return api.get(`/candidatsmanagement/candidat/${candidatId}/selection/listwithoutcandidat${params}`);
};

const addCandidatSelection = (data) => {
  return api.post("/candidatsmanagement/selection/add", data);
};

const editCandidatSelection = (data, idSelection) => {
  return api.put(`/candidatsmanagement/selection/${idSelection}/edit`, data);
};
const addremovecandidattoselection = (data, candidatId) => {
  return api.put(`/candidatsmanagement/candidat/${candidatId}/selections`, data);
};
const getAllCadidatSelections = (params = "") => {
  return api.get(`/candidatsmanagement/selection/list${params}`);
};

const getAllCandidatsInSelection = (selectionId) => {
  return api.get(`/candidatsmanagement/selection/${selectionId}/candidats/list`);
};

const deleteCandidatFromSelection = (candidatId, selectionId) => {
  return api.delete(`/candidatsmanagement/selection/${selectionId}/candidat/${candidatId}/delete`);
};

const getSelectionDetails = (selectionId) => {
  return api.get(`/candidatsmanagement/selection/${selectionId}/details`);
}

const deleteCandidatSelection = (selectionId) => {
  return api.delete(`/candidatsmanagement/selections/${selectionId}/delete`);
};

const archiveCandidatSelection = (selectionId, data) => {
  return api.put(`/candidatsmanagement/selections/${selectionId}/archive`, data);
};

const importCandidatsToSelection = (payload) => {
  return api.post(`/candidatsmanagement/selection/candidats/import`, payload)
}

const exportCandidatsSelection = (selection) => {
  return api.get(`/candidatsmanagement/selection/${selection}/export`,
    {
      responseType: "blob",
    })
}

const addCandidatsToSelection = (selection, body) => {
  return api.put(`/candidatsmanagement/selections/${selection}/candidats/add`, body);
}

const sendEmailsToCandidatsOfSelection = (body) => {
  return api.post(`/candidatmanagement/selection/mailling`, body);
}

export {
  getAllCandidatSelections,
  getAllSelectionsWithoutCandidat,
  getAllCadidatSelections,
  addCandidatSelection,
  editCandidatSelection,
  addremovecandidattoselection,
  getAllCandidatsInSelection,
  deleteCandidatFromSelection,
  getSelectionDetails,
  deleteCandidatSelection,
  archiveCandidatSelection,
  importCandidatsToSelection,
  exportCandidatsSelection,
  addCandidatsToSelection,
  sendEmailsToCandidatsOfSelection
};
